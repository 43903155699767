import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { HeadingContainer } from "../containers/Container";

interface SimpleEditHeaderProps extends WithTranslation {
    entity?: EntityWrapper<any>;
    entityTypeName: string;
}

const SimpleEditHeaderComponent = (props: SimpleEditHeaderProps) => {
    const {entity, entityTypeName, t} = props;
    return (
        <HeadingContainer>
            <h1 className="mb-0">
                {entity ? (entity.id ?
                    (t("Editing") + " " + t(entityTypeName)) :
                    (t("Creating") + " " + t(entityTypeName))) :
                    (t("Loading") + " " + t(entityTypeName) + "...")}
            </h1>
        </HeadingContainer>
    );
};

export const SimpleEditHeader = withTranslation(["translation"])(SimpleEditHeaderComponent);
