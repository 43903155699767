import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { CourseCategory } from "../../../model/course-category";
import { CourseCategoryColorBubble } from "./CourseCategoryColorBubble";

export interface CourseCategoryDisplayProps extends WithTranslation {
    courseCategory?: EntityWrapper<CourseCategory>;
}

class CourseCategoryDisplayComponent extends React.Component<CourseCategoryDisplayProps> {
    public render() {
        const {courseCategory, t} = this.props;

        if (!courseCategory) {
            return (<div className="course-category-display">{t("no category")}</div>);
        }
        return (
            <div className="course-category-display d-flex align-items-center">
                <CourseCategoryColorBubble
                    className="mr-2"
                    color={courseCategory.entity.color}/>
                {courseCategory.entity.name}
            </div>
        );
    }
}

export const CourseCategoryDisplay = withTranslation("courses")(CourseCategoryDisplayComponent);
