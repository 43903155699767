import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TooltipLink } from "./TooltipLink";

interface IconLinkWithTooltipProps {
    to: string;
    tooltip: string;
    icon: IconDefinition;
}

export const ActionLink = (props: IconLinkWithTooltipProps) => {
    return <TooltipLink to={props.to} tooltip={props.tooltip}>
        <FontAwesomeIcon icon={props.icon}/>
    </TooltipLink>;
};
