import { faSave} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WithTranslation, withTranslation } from "react-i18next";
import React from "react";
import { connect } from "react-redux";
import { downloadLogAction } from "../../actions/download-log";

interface DispatchProps {
    downloadLog: typeof downloadLogAction;
}

type Props = DispatchProps & WithTranslation;

const DownloadLogButtonComponent = (props: Props) => {
    const {downloadLog, t} = props;

    return (
        <button type="button" onClick={() => {
            downloadLog();
        }} className="btn btn-outline-success">
            <FontAwesomeIcon className="mr-2" icon={faSave}/>{t("download-log.download")}
        </button>
    );
};
export const DownloadLogButton = connect<{}, DispatchProps, {}>(
    null,
    {
        downloadLog: downloadLogAction,
    }
)(withTranslation("transactionlog")(DownloadLogButtonComponent));
