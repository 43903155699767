import {apiGet, apiPost, matchesAnyOfTheseApiSuccesses} from "@thekeytechnology/framework-react";
import {Action} from "redux";
import {combineEpics} from "redux-observable";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {API_BOOK_COACHING} from "../../coaching/actions/book-coaching";
import {API_ENROLL} from "../../courses/actions/enroll";
import {API_PURCHASE_LEARNING_ITEMS} from "../../learning-items/actions/purchase-learning-items";
import {API_GET_BUDGET_SETTINGS} from "../actions/get-budget-settings";
import {API_GET_CURRENT_USER_BUDGET, getCurrentUserBudgetAction} from "../actions/get-current-user-budget";
import {API_GET_USER_BUDGETS, GetUserBudgetsPayload} from "../actions/get-user-budgets";
import {API_RESET_BUDGETS} from "../actions/reset-budget";
import {API_SET_BUDGET_SETTINGS, SetBudgetSettingsPayload} from "../actions/set-budget-settings";
import {API_SET_USER_BUDGETS} from "../actions/set-user-budgets";

const getUserBudgets$ = apiPost<GetUserBudgetsPayload, any>(
    {apiType: API_GET_USER_BUDGETS},
    "/budget/get"
);

const getBudgetSettings$ = apiGet(
    {apiType: API_GET_BUDGET_SETTINGS},
    "/budget/settings"
);

const setBudgetSettings$ = apiPost<SetBudgetSettingsPayload, any>(
    {apiType: API_SET_BUDGET_SETTINGS},
    "/budget/settings"
);

const setUserBudgets$ = apiPost<GetUserBudgetsPayload, any>(
    {apiType: API_SET_USER_BUDGETS},
    "/budget/set"
);

const resetBudgets$ = apiGet(
    {apiType: API_RESET_BUDGETS},
    "/budget/reset"
);

const getCurrentBudget$ = apiGet(
    {apiType: API_GET_CURRENT_USER_BUDGET},
    "/budget/current"
);

const reloadBudget = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_ENROLL}, {apiType: API_BOOK_COACHING}, {apiType: API_PURCHASE_LEARNING_ITEMS}),
    map(() => getCurrentUserBudgetAction())
);

export const budgetModuleEpics = combineEpics(
    getUserBudgets$ as any,
    setUserBudgets$ as any,
    getBudgetSettings$ as any,
    setBudgetSettings$ as any,
    resetBudgets$ as any,
    getCurrentBudget$ as any,
    reloadBudget
);
