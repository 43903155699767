import { WithEntityList, WithSelections } from "@thekeytechnology/framework-react";
import { TkFile } from "@thekeytechnology/framework-react";
import { WithEntityListProps } from "@thekeytechnology/framework-react";
import { WithSelectionsProps } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { AboveTableContainer } from "../../../../core/components/containers/Container";
import { Table } from "../../../../core/components/table/Table";
import EntityDeletionButton from "../../../../core/components/buttons/EntityDeletionButton";
import PaginationInfo from "../../../../core/components/pagination/PaginationInfo";
import { AsyncEntityTableBody } from "../../../../entity/components/AsyncEntityTableBody";
import { FilesTableHeader } from "./FilesTableHeader";
import { FilesTableRow } from "./FilesTableRow";

import "./files-table.scss";

type Props = WithEntityListProps<TkFile> & WithSelectionsProps<string> & WithTranslation;

const FilesTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={TkFile.TYPE} className="mr-auto"/>
                <EntityDeletionButton entityType={TkFile.TYPE}
                                      selectedEntities={selectionModel.selections}
                                      label={t("file")}
                                      labelPlural={t("files")}
                />
            </AboveTableContainer>
            <Table itemCount={entities.length} className="files-table">
                <FilesTableHeader files={entities}
                                  selectionModel={selectionModel}/>
                <AsyncEntityTableBody apiCallState={entityApiState}
                                      items={entities}
                                      renderItem={file =>
                                          <FilesTableRow
                                              key={file.id}
                                              file={file}
                                              selectionModel={selectionModel}/>
                                      }/>
            </Table>
        </>
    );
};

export const FilesTableWithExternalEntitiesAndSelections = withTranslation("files")(FilesTableComponent);

export const FilesTable = WithSelections<{}, string>(
    WithEntityList<WithSelectionsProps<string>, TkFile>(
        FilesTableWithExternalEntitiesAndSelections,
        TkFile.TYPE
    ) as any
);
