import { createSelector } from "reselect";
import { CoursesModuleState } from "../reducers";
import { MyCoursesState } from "../reducers/my-courses";

export const selectCoursesModuleState = (state: any) => state.courses;

export const selectMyCoursesState = createSelector(
    selectCoursesModuleState,
    (moduleState: CoursesModuleState) =>
        moduleState.myCourses
);

export const selectMyCourses = createSelector(
    selectMyCoursesState,
    (subscriptionState: MyCoursesState) =>
        subscriptionState.myCourses ? subscriptionState.myCourses : []
);

export const selectMyCourseGroups = createSelector(
    selectMyCoursesState,
    (subscriptionState: MyCoursesState) =>
        subscriptionState.myCourseGroups ? subscriptionState.myCourseGroups : []
);
