import {
    apiPost,
    matchesApiSuccess
} from "@thekeytechnology/framework-react";
import { push } from "connected-react-router";
import { Action } from "redux";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { API_PURCHASE_LEARNING_ITEMS } from "../actions/purchase-learning-items";

export const purchaseLearningItem$ = apiPost(
    {apiType: API_PURCHASE_LEARNING_ITEMS},
    "/learning-items/purchase"
);

const redirectAfterPurchase$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_PURCHASE_LEARNING_ITEMS),
        map(() => push("/transactions"))
    );

export const learningItemsModuleEpics$ = combineEpics(
    purchaseLearningItem$ as any,
    redirectAfterPurchase$
);
