import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_ENROLL = "enroll";
export const CALL_TYPE_ENROLL = "enroll";
export const CALL_TYPE_UNENROLL = "unenroll";

export interface EnrollPayload {
    courseId: string;
    onWaitingList: boolean;
}

export const enrollAction = (courseId: string, onWaitingList: boolean) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_ENROLL,
            callType: CALL_TYPE_ENROLL
        },
        payload: {
            courseId,
            onWaitingList
        }
    } as ApiCallAction<EnrollPayload>;
};

export const unEnrollAction = (courseId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_ENROLL,
            callType: CALL_TYPE_UNENROLL
        },
        payload: courseId
    } as ApiCallAction<string>;
};
