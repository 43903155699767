import { EntityWrapper } from "@thekeytechnology/framework-react";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { CourseWithMeta } from "../../../../model/course/course-with-meta";

import "./waiting-list-switcher.scss";

interface WaitingListSwitcherProps extends WithTranslation {
    course?: EntityWrapper<CourseWithMeta>;
    showWaitingList: boolean;
    settingSwitched: (showWaitingList: boolean) => void;
}

class WaitingListSwitcherComponent extends Component<WaitingListSwitcherProps> {
    public render() {
        const {course, showWaitingList, settingSwitched, t} = this.props;

        const participantCount = course ? course.entity.participants.filter(p => !p.entity.onWaitingList).length : "?";
        const waitingListCount = course ? course.entity.participants.filter(p => p.entity.onWaitingList).length : "?";
        return (
            <div className="waiting-list-switcher mt-3">
                <button type="button" onClick={() => settingSwitched(false)}
                        className={"tab pb-4 " + (!showWaitingList ? "active" : "")}>
                    {t("Participants")} ({participantCount}/{course ? course.entity.capacity : "?"})
                </button>
                <button type="button" onClick={() => settingSwitched(true)}
                        className={"tab pb-4 " + (showWaitingList ? "active" : "")}>
                    {t("Waiting List")} ({waitingListCount})
                </button>
            </div>
        );
    }
}

export const WaitingListSwitcher = withTranslation("courses")(WaitingListSwitcherComponent);
