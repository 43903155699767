import { faPencilAlt, faUserTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WithEntityList, WithSelections } from "@thekeytechnology/framework-react";
import { UserWithRoles } from "@thekeytechnology/framework-react";
import { WithEntityListProps } from "@thekeytechnology/framework-react";
import { WithSelectionsProps } from "@thekeytechnology/framework-react";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AboveTableContainer } from "../../../../core/components/containers/Container";
import { ActionLink } from "../../../../core/components/icon-link-tooltip/ActionLink";
import { ActionTableColumn } from "../../../../core/components/table/ActionTableColumn";
import { EntityTableHeader } from "../../../../core/components/table/EntityTableHeader";
import { EntityTableRow } from "../../../../core/components/table/EntityTableRow";
import { Table, TableColumn } from "../../../../core/components/table/Table";
import { AsyncEntityTableBody } from "../../../../entity/components/AsyncEntityTableBody";
import { NavLink } from "react-router-dom";
import "./users-table.scss";
import { RemoveUsersButton } from "./RemoveUserButton";

type Props = WithSelectionsProps<string> & WithEntityListProps<UserWithRoles> & WithTranslation;

const UsersTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    return (
        <>
            <h3>{t("Users in account")}</h3>
            <AboveTableContainer>
                <NavLink className="mr-3"
                         to={"/users/invite"}>
                    <button type="button" className="btn btn-success">
                        {t("Invite")}
                    </button>
                </NavLink>
                <RemoveUsersButton selectedEntities={selectionModel.selections}/>
            </AboveTableContainer>
            <Table itemCount={entities.length} className="users-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("translation:Name")}</TableColumn>
                    <TableColumn>{t("Roles")}</TableColumn>
                    <TableColumn>{t("translation:Actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={user =>
                        <EntityTableRow
                            key={user.id}
                            entity={user}
                            selectionModel={selectionModel}>
                            <TableColumn className="bold title-column">
                                {user.entity.name}
                            </TableColumn>
                            <TableColumn>
                                <FontAwesomeIcon icon={faUserTag}
                                                 className="mobile-only-icon lg-and-above-hide mr-2"/>
                                {user.entity.roles.map(r => t("user-role." + r)).join(", ")}
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink
                                    to={"/users/" + user.id + "/roles"}
                                    tooltip={t("Edit user")}
                                    icon={faPencilAlt}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const UsersTable = WithSelections<{}, string>(
    WithEntityList<WithSelectionsProps<string>, UserWithRoles>(
        withTranslation("auth")(UsersTableComponent),
        UserWithRoles.TYPE
    ) as any
);
