import {
    addFiltersAction, API_RELOAD_ENTITY_LIST,
    ApiCallState, reloadEntityListAction,
    removeFiltersAction,
    selectApiCallState
} from "@thekeytechnology/framework-react";
import { selectEntities, selectSingleEntity } from "@thekeytechnology/framework-react";
import {
    EntityWrapper, fetchSingleEntityAction,
    FilterTerm,
    PropertyFilter
} from "@thekeytechnology/framework-react";
import React, { Component } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    HeadingContainer,
    PaginationContainer,
    ContentContainer
} from "../../../../core/components/containers/Container";
import { CourseDefinition } from "../../../model/course-definition/course-definition.model";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { ScheduledEmail } from "../../../model/scheduled-email/scheduled-email";
import Pagination from "../../../../core/components/pagination/Pagination";
import { EditHeaderWithBackground } from "../../course/common/edit/EditHeaderWithBackground";
import { ScheduleDisplay } from "../single/schedule/ScheduleDisplay";
import { ScheduledEmailsTable } from "./table/ScheduledEmailsTable";

interface StateProps {
    courseDefinition?: EntityWrapper<CourseDefinition>;
    scheduledEmails: Array<EntityWrapper<ScheduledEmail>>;
    scheduledEmailsApiState: ApiCallState;
}

interface DispatchProps {
    fetchCourseDefinition: ReturnType<typeof fetchSingleEntityAction>;
    addFilters: ReturnType<typeof addFiltersAction>
    removeFilters: ReturnType<typeof removeFiltersAction>
    fetchScheduledEmails: ReturnType<typeof reloadEntityListAction>;
}

type Props = StateProps & DispatchProps & WithTranslation & RouteComponentProps<any>;

class ScheduledEmailsOverview extends Component<Props> {
    public componentDidMount(): void {
        const {match: {params: {courseDefinitionId}}, fetchCourseDefinition, fetchScheduledEmails, addFilters} = this.props;
        if (courseDefinitionId) {
            fetchCourseDefinition(courseDefinitionId);
            addFilters("root-definition", [new PropertyFilter("entity.courseDefinitionRef",
                new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, courseDefinitionId))
            ]);
            fetchScheduledEmails();
        }
    }

    public componentWillUnmount(): void {
        this.props.removeFilters(["root-definition"])
    }

    public render() {
        const {courseDefinition, scheduledEmails, scheduledEmailsApiState, t} = this.props;
        if (!courseDefinition || !scheduledEmails) {
            return <HeadingContainer><h1>{t("Loading")} {t("Scheduled emails")}</h1></HeadingContainer>;
        }
        return (
            <>
                <BreadcrumbsItem to={"/course-definitions/"}>{t("Course definitions")}</BreadcrumbsItem>
                <BreadcrumbsItem
                    to={"/course-definitions/" + courseDefinition.id + "/edit"}>{courseDefinition.entity.name}</BreadcrumbsItem>
                <BreadcrumbsItem
                    to={"/course-definitions/" + courseDefinition.id + "/scheduled-emails"}>{t("Editing scheduled emails")}</BreadcrumbsItem>

                <HeadingContainer>
                    <h1 className="mb-0">{t("Editing scheduled emails")}</h1>
                </HeadingContainer>
                <EditHeaderWithBackground heading={t("For course definition") + ": " + courseDefinition.entity.name}
                                          leadText={<ScheduleDisplay
                                              schedule={courseDefinition.entity.schedule}/>}>
                </EditHeaderWithBackground>
                <ContentContainer>
                    <ScheduledEmailsTable scheduledEmails={scheduledEmails}
                                          scheduledEmailsApiState={scheduledEmailsApiState}
                                          courseDefinitionId={courseDefinition.id as string}/>
                </ContentContainer>
                <PaginationContainer>
                    <Pagination entityType={ScheduledEmail.TYPE}/>
                </PaginationContainer>
            </>
        );
    }
}

export default connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            courseDefinition: selectSingleEntity<CourseDefinition>(CourseDefinition.TYPE)(state),
            scheduledEmails: selectEntities<ScheduledEmail>(ScheduledEmail.TYPE)(state),
            scheduledEmailsApiState: selectApiCallState(API_RELOAD_ENTITY_LIST, ScheduledEmail.TYPE)(state)
        };
    },
    {
        fetchCourseDefinition: fetchSingleEntityAction(CourseDefinition.TYPE),
        fetchScheduledEmails: reloadEntityListAction(ScheduledEmail.TYPE),
        addFilters: addFiltersAction(ScheduledEmail.TYPE),
        removeFilters: removeFiltersAction(ScheduledEmail.TYPE)
    }
)(withTranslation("courses")(withRouter(ScheduledEmailsOverview)));
