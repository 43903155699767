import { ApiCallAction } from "@thekeytechnology/framework-react";
import { apiPost } from "@thekeytechnology/framework-react";
import { combineEpics} from "redux-observable";
import {
    API_CANCEL_COURSES,
    CancelCoursesPayload
} from "../actions/cancel-course";
import {
    API_CHANGE_COURSE_STATUS,
    ChangeCourseStatusPayload
} from "../actions/change-course-status";

const cancelCourses$ = apiPost<CancelCoursesPayload, any>(
    {apiType: API_CANCEL_COURSES},
    "/courses/cancel",
    (action: ApiCallAction<CancelCoursesPayload>) => {
        return {
            courseIds: action.payload.courseIds
        };
    });

const changeStatus$ = apiPost<ChangeCourseStatusPayload, any>(
    {apiType: API_CHANGE_COURSE_STATUS},
    "/courses/change-status",
    (action: ApiCallAction<ChangeCourseStatusPayload>) => {
        return {
            courseIds: [action.payload.courseId],
            status: action.payload.status
        };
    });

export const courseEpics$ = combineEpics(
    cancelCourses$ as any,
    changeStatus$ as any
);
