import { ApiCallAction } from "@thekeytechnology/framework-react";
import { apiPost } from "@thekeytechnology/framework-react";
import { API_SEND_MAIL, EmailSentPayload, SendMailPayload } from "../actions/send-email";

export const emailEpics$ = apiPost<SendMailPayload, EmailSentPayload>(
    {apiType: API_SEND_MAIL},
    "/courses/mail",
    (action: ApiCallAction<SendMailPayload>) => {
        return {
            courseId: action.payload.courseId,
            targetParticipantIds: action.payload.targetParticipantIds,
            subject: action.payload.subject,
            message: action.payload.message
        };
    },
    ((originalAction: ApiCallAction<SendMailPayload>, responseData: any) => (
        {
            ...responseData,
            courseId: originalAction.payload.courseId
        } as EmailSentPayload
    ))
);
