import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component, default as React } from "react";
import { createPortal } from "react-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./message-display.scss";
import { Message, selectMessages, removeMessageAction } from "@thekeytechnology/framework-react";

interface StateProps {
    messages: Message[];
}
interface DispatchProps {
    removeMessage: (id: string) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class MessageDisplayComponent extends Component<Props> {
    public render() {
        const {t, messages, removeMessage} = this.props;

        if (!messages || messages.length === 0) {
            return null;
        }

        const messageDisplay = <div className="message-display w-100 d-flex flex-column">
            {messages.map(message =>
                <div key={message.id} className={"message d-flex " + message.messageType}>
                    <div dangerouslySetInnerHTML={{__html: t(message.message)}}>
                    </div>

                    <a href="/" className="ml-auto" onClick={e => {
                        removeMessage(message.id)
                        e.preventDefault();
                        return false;
                    }}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </a>
                </div>
            )}
        </div>;

        return createPortal(
            messageDisplay,
            document.querySelector("#messages")!
        );
    }
}

export const MessageDisplay = withTranslation()(connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            messages: selectMessages(state)
        };
    },
    {
        removeMessage: removeMessageAction,
    }
)(MessageDisplayComponent));
