import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { withTranslation, WithTranslation } from "react-i18next";
import { SaveAndBackButtons } from "../../../../core/components/buttons/SaveAndBackButtons";
import { ContentContainer } from "../../../../core/components/containers/Container";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { SimpleEditHeader } from "../../../../core/components/header/LoadingEditHeader";
import { CourseCategory } from "../../../model/course-category";
import { ColorSelectField } from "../../common/fields/ColorSelectField";
import { EditHeaderWithBackground } from "../../course/common/edit/EditHeaderWithBackground";
import * as Yup from "yup";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";

type Props = WithSingleEntityFromPathProps<CourseCategory, CourseCategory> & WithTranslation;

const EditCourseCategory = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return (
        <>
            <BreadcrumbsItem to={"/course-categories"}>{t("Course categories")}</BreadcrumbsItem>
            {entity ? <BreadcrumbsItem
                to={"/course-categories/" + entity.id + "/edit"}>
                {t("translation:Editing")} "{entity.entity.name ? entity.entity.name : t("New course category")}"
            </BreadcrumbsItem> : null}
            <SimpleEditHeader
                entityTypeName={t("course category")}
                entity={entity}
            />

            {entity ?
                <Formik
                    initialValues={{
                        name: entity.entity.name,
                        color: entity.entity.color,
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required(t("Name") + " " + t("translation:is a required field")),
                        color: Yup.string().required(t("Color") + " " + t("translation:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const courseForSaving = new EntityWrapper(entity.id,
                            new CourseCategory(
                                values.name,
                                values.color
                            )
                        );
                        upsertEntity(courseForSaving);
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={
                                    entity.id ?
                                        entity.entity.name :
                                        t("New course category")
                                }>
                                <SaveAndBackButtons isSubmitting={isSubmitting}
                                                    entity={entity}
                                                    backPath={"/course-categories"}/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                <div className="form-group row">
                                    <ValidatedField icon={faUsers} label={t("Name")} name="name" errors={errors}
                                                    touched={touched} type="text"
                                                    placeholder={t("Enter course category name")} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField label={t("Color")} name="color" errors={errors}
                                                    touched={touched}
                                                    component={ColorSelectField} required
                                    />
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik> : null}
        </>
    );
};

export default WithSingleEntityFromPath<{}, CourseCategory, CourseCategory>(withTranslation(["courses", "translation"])(EditCourseCategory),
    CourseCategory.TYPE, "courseCategoryId", CourseCategory.new);
