import { SelectionModel } from "@thekeytechnology/framework-react";
import React from "react";

interface SelectInModelCheckboxProps {
    item: string;
    selectionModel: SelectionModel<string>;
}

export const SelectInModelRadio = (props: SelectInModelCheckboxProps) => {
    const {item, selectionModel} = props;
    return <input name={"select-" + item} type="radio"
                  checked={selectionModel.isItemSelected(item)}
                  onChange={() => {
                      selectionModel.handleSelect(item);
                  }}/>;
};
