import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_GET_CURRENT_USER_BUDGET = "get-current-user-budget";

export const getCurrentUserBudgetAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_CURRENT_USER_BUDGET,
            callType: API_GET_CURRENT_USER_BUDGET
        },
        payload: null
    } as ApiCallAction<null>;
};
