import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {EntityCreateAndDeleteButtons} from "../../../../../core/components/buttons/EntityCreateAndDeleteButtons";
import {AboveTableContainer} from "../../../../../core/components/containers/Container";
import {ActionLink} from "../../../../../core/components/icon-link-tooltip/ActionLink";
import {TooltipLink} from "../../../../../core/components/icon-link-tooltip/TooltipLink";
import {ActionTableColumn} from "../../../../../core/components/table/ActionTableColumn";
import {EntityTableHeader} from "../../../../../core/components/table/EntityTableHeader";
import {EntityTableRow} from "../../../../../core/components/table/EntityTableRow";
import {Table, TableBody, TableColumn} from "../../../../../core/components/table/Table";
import {AsyncEntityTableBody} from "../../../../../entity/components/AsyncEntityTableBody";
import {BookedSession} from "../../../../model/booked-session";
import {CoachingOffer} from "../../../../model/coaching-offer";
import "./coaching-offers-table.scss";
import PaginationInfo from "../../../../../core/components/pagination/PaginationInfo";

type Props = WithEntityListProps<CoachingOffer> & WithSelectionsProps<string> & WithTranslation;

const CoachingOffersTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={CoachingOffer.TYPE} className="mr-auto"/>
                <EntityCreateAndDeleteButtons
                    entityType={CoachingOffer.TYPE}
                    selectedEntities={selectionModel.selections}
                    createPath="/coaching-offers/new/edit"
                    label={t("coaching-offers.entity.singular")}
                    labelPlural={t("coaching-offers.entity.plural")}
                />
            </AboveTableContainer>
            <Table itemCount={entities.length} className="coaching-offers-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("coaching-offers.table.columns.title")}</TableColumn>
                    <TableColumn>{t("coaching-offers.table.columns.cost")}</TableColumn>
                    <TableColumn>{t("coaching-offers.table.columns.trainer")}</TableColumn>
                    <TableColumn>{t("coaching-offers.table.columns.sessions")}</TableColumn>
                    <TableColumn>{t("coaching-offers.table.columns.actions")}</TableColumn>
                </EntityTableHeader>
                <TableBody>
                    <AsyncEntityTableBody<CoachingOffer>
                        apiCallState={entityApiState}
                        items={entities}
                        renderItem={coachingOffer =>
                            <EntityTableRow<CoachingOffer>
                                key={coachingOffer.id}
                                entity={coachingOffer}
                                selectionModel={selectionModel}>
                                <TableColumn className="bold">
                                    {coachingOffer.entity.title}
                                </TableColumn>
                                <TableColumn>
                                    {coachingOffer.entity.cost} €
                                </TableColumn>
                                <TableColumn>
                                    {coachingOffer.entity.trainer ? coachingOffer.entity.trainer.entity.name : ""}
                                </TableColumn>
                                <TableColumn>
                                    <TooltipLink to={"/coaching-offers/" + coachingOffer.id + "/booked-sessions/"}
                                                 tooltip={t("coaching-offers.table.columns.sessions-tooltip")}>
                                        {t("coaching-offers.table.columns.requested-sessions")}: {coachingOffer.entity.sessions.filter(f => f.entity.status === BookedSession.STATUS_REQUESTED).length}<br/>
                                        {t("coaching-offers.table.columns.all-sessions")}: {coachingOffer.entity.sessions.length}
                                    </TooltipLink>
                                </TableColumn>
                                <ActionTableColumn>
                                    <ActionLink
                                        to={"/coaching-offers/" + coachingOffer.id + "/edit"}
                                        tooltip={t("coaching-offers.table.edit")}
                                        icon={faPencilAlt}/>
                                </ActionTableColumn>
                            </EntityTableRow>
                        }/>
                </TableBody>
            </Table>
        </>
    );
};

export const CoachingOffersTable = WithSelections(
    WithEntityList<WithSelectionsProps<string>, CoachingOffer>(
        withTranslation("coaching")(CoachingOffersTableComponent),
        CoachingOffer.TYPE
    )
);
