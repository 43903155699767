import { faCalendar, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
    API_FETCH_SETTINGS,
    API_SET_SETTING,
    fetchSettingsAction,
    selectJsonSetting, setSettingAction, ShowWhenApiSucceeded
} from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import * as Yup from "yup";
import { connect } from "react-redux";
import { ApiStateSubmitButton } from "../../core/components/form/ApiStateSubmitButton";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { ApiStatusButton } from "../../integrations/components/ApiStatusButton";

interface StateProps {
    bambooSettings: any;
}

interface DispatchProps {
    setSetting: (key: string, value: string) => void;
    fetchSettings: (keys: string[]) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class BambooIntegrationFormComponent extends Component<Props> {
    public componentDidMount(): void {
        this.props.fetchSettings(["bamboo-settings"]);
    }

    public render() {
        const {t, setSetting, bambooSettings} = this.props;

        return (
            <ShowWhenApiSucceeded apiQualifier={API_FETCH_SETTINGS}
                                  callType="bamboo-settings"
                                  duringLoading={<h3 className="mb-3">{t("bamboo-integration.loading")}</h3>}>
                <Formik
                    initialValues={{
                        apiKey: bambooSettings.apiKey ? bambooSettings.apiKey : "",
                        domain: bambooSettings.domain ? bambooSettings.domain : ""
                    }}
                    validationSchema={Yup.object().shape({
                        apiKey: Yup.string().required(t("bamboo-integration.apiKey") + " " + t("translation:is a required field")),
                        domain: Yup.string().required(t("bamboo-integration.domain") + " " + t("translation:is a required field"))
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        setSetting("bamboo-settings", JSON.stringify({
                            apiKey: values.apiKey,
                            domain: values.domain
                        }));
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => {
                        const hasError = !!(errors.apiKey && touched.apiKey) || !!(errors.domain && touched.domain);

                        return (
                            <Form>
                                <h3 className="mb-3 mt-5">{t("bamboo-integration.heading")}</h3>
                                <div className="form-group row">
                                    <label htmlFor={"name"}
                                           className="col-sm-2 col-form-label">{t("bamboo-integration.api-status")}</label>
                                    <div className="col-sm-10 d-flex align-items-center">
                                        <ApiStatusButton apiIdentifier="hr"/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faCalendar}
                                                    label={t("bamboo-integration.apiKey")}
                                                    name="apiKey" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faEnvelope}
                                                    label={t("bamboo-integration.domain")}
                                                    name="domain" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <ApiStateSubmitButton
                                    apiQualifier={API_SET_SETTING}
                                    callType="bamboo-settings"
                                    submitText={t("bamboo-integration.submit")}
                                    hasError={hasError}
                                    isSubmitting={isSubmitting}/>
                            </Form>
                        );
                    }}
                </Formik>
            </ShowWhenApiSucceeded>);
    }
}

export const BambooIntegrationForm = connect<StateProps, DispatchProps, {}>(
    state => {
        return {
            bambooSettings: selectJsonSetting("bamboo-settings")(state)
        };
    },
    {
        fetchSettings: fetchSettingsAction("bamboo-settings"),
        setSetting: setSettingAction,
    }
)(withTranslation(["settings", "translation"])(BambooIntegrationFormComponent));
