import { EntityWrapper, SelectionModel } from "@thekeytechnology/framework-react";
import { TkFile } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { DateTimeDisplay } from "../../../../core/components/display/DateTimeDisplay";
import { SelectInModelCheckbox } from "../../../../core/components/selection/SelectInModelCheckbox";
import { SelectInModelRadio } from "../../../../core/components/selection/SelectInModelRadio";

interface FilesTableRowProps extends WithTranslation {
    file: EntityWrapper<TkFile>;
    selectionModel: SelectionModel<string>;
}

const FilesTableRowComponent = (props: FilesTableRowProps) => {
    const {file, selectionModel, t} = props;
    return (
        <div key={file.id} className="table-row pt-3 pb-3 ">
            <div className="table-column">
                {selectionModel.isSingleSelect ? <SelectInModelRadio item={file.id as string} selectionModel={selectionModel}/>
                    : <SelectInModelCheckbox item={file.id as string} selectionModel={selectionModel}/>
                }
            </div>
            <div className="table-column bold">
                {file.entity.name}
            </div>
            <div className="table-column">
                <DateTimeDisplay dateTime={file.entity.uploadDateTime}/>
            </div>
            <div className="table-column">
                {file.entity.fileType}
            </div>
            <div className="table-column">
                <a href={file.entity.url} target="_blank" rel="noreferrer noopener">
                    {file.entity.thumbnailUrl ?
                        <img alt="" src={file.entity.thumbnailUrl}/> : <>{t("No preview available")}</>}
                </a>
            </div>
        </div>
    );
};

export const FilesTableRow = withTranslation("files")(FilesTableRowComponent);
