import { createSelector } from "reselect";
import { BudgetModuleState } from "../reducers";
import { BudgetState } from "../reducers/budget";

export const selectBudgetModuleState = (state: any) => state.budget;

export const selectBudgetState = createSelector(
    selectBudgetModuleState,
    (moduleState: BudgetModuleState) =>
        moduleState.budget
);

export const selectUserBudget = (userId: string) => createSelector(
    selectBudgetState,
    (budgetState: BudgetState) =>
        budgetState.budgets[userId] ? budgetState.budgets[userId] : undefined
);

export const selectBudgetSettings = createSelector(
    selectBudgetState,
    (budgetState: BudgetState) =>
        budgetState.budgetSettings ? budgetState.budgetSettings : undefined
);

export const selectCurrentUserBudget = createSelector(
    selectBudgetState,
    (budgetState: BudgetState) => budgetState.currentUserBudget
);
