import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    BlueBgContainer,
    HeadingContainer,
    PaginationContainer,
    ContentContainer
} from "../../../../core/components/containers/Container";
import { SimpleHeader } from "../../../../core/components/header/SimpleHeader";
import Pagination from "../../../../core/components/pagination/Pagination";
import { CourseGroupWithMeta } from "../../../model/course-group/course-group-with-meta";
import { CourseGroupFilters } from "./filter/CourseGroupsFilters";
import { CourseGroupsTable } from "./table/CourseGroupsTable";

const CourseGroupOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/course-groups"}>{t("Course groups")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("Course groups")}/>
            </HeadingContainer>
            <BlueBgContainer>
                <CourseGroupFilters/>
            </BlueBgContainer>
            <ContentContainer>
                <CourseGroupsTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={CourseGroupWithMeta.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const CourseGroupOverviev = withTranslation("courses")(CourseGroupOverviewComponent);
