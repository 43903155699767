import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";
import { NavLink } from "react-router-dom";

import "./breadcrumbs.scss";

export class BreadcrumbsComponent extends React.Component {
    public render() {
        return (
            <div className="breadcrumbs">
                <Breadcrumbs
                    separator={<FontAwesomeIcon className="separator" icon={faChevronRight}/>}
                    item={NavLink}
                    finalItem={"span"}
                />
            </div>
        );
    }
}
