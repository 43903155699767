import { WithEntityList } from "@thekeytechnology/framework-react";
import { WithEntityListProps } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { LoadingRow } from "../../../../core/components/table/LoadingRow";
import { AsyncContent } from "../../../../core/components/table/LoadingTableContent";
import { TrainerData } from "../../../model/trainer/trainer-data";
import { NavLink } from "react-router-dom";
import "./trainer-cards.scss";

type Props = WithEntityListProps<TrainerData> & WithTranslation;

const TrainerCardsComponent = (props: Props) => {
    const {
        entities,
        entityApiState,
        t
    } = props;

    const TrainerCardsContent = <AsyncContent
        loadingComponent={() => <LoadingRow/>}
        successComponent={() =>
            entities.length === 0 ?
                <div className="no-trainers">
                    {t("no-trainer-profiles")}&nbsp;
                    <NavLink to="/users">{t("Add roles here")}.</NavLink>
                </div>
                : entities.map(trainer => {
                    return (
                        <NavLink key={trainer.id}
                                 to={"/trainers/" + trainer.id + "/edit"}>
                            <div className="card">
                                {trainer.entity.avatarFile ?
                                    <img src={trainer.entity.avatarFile.entity.url}
                                         className="card-img-top"
                                         alt={trainer.entity.name}/> : null}
                                <div className="card-body">
                                    <h2 className="card-title">{trainer.entity.name}</h2>
                                    <p className="trainer-type">{t("trainer-type." + trainer.entity.trainerType)}</p>
                                </div>
                            </div>
                        </NavLink>
                    );
                })
        }
        apiCallState={entityApiState}/>;

    return (
        <div className={"trainer-cards pl-3 pr-3 mb-5 row " + (entities.length === 0 ? "empty" : "")}>
            {TrainerCardsContent}
        </div>
    );
};

export const TrainerCards = WithEntityList<{}, TrainerData>(
    withTranslation("courses")(TrainerCardsComponent),
    TrainerData.TYPE
);
