import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { CourseForSaving } from "../../../model/course/course-for-saving";
import { CourseWithMeta } from "../../../model/course/course-with-meta";
import BulkAdditionForm from "../../common/participants/addition/BulkAdditionForm";
import SingleAdditionForm from "../../common/participants/addition/SingleAdditionForm";
import { CourseRelatedModalHeader } from "../common/modal/CourseRelatedModalHeader";
import { WaitingListSwitcher } from "./switcher/WaitingListSwitcher";
import EditParticipantsTable from "./table/EditParticipantsTable";

import "./edit-participants-modal.scss";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";

interface EditParticipantsModalState {
    addForm: string;
    showWaitingList: boolean;
}

type Props = WithSingleEntityFromPathProps<CourseWithMeta, CourseForSaving> & WithTranslation;

class EditParticipantsModal extends Component<Props, EditParticipantsModalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            addForm: "single",
            showWaitingList: false
        };
    }

    public render() {
        const {entity, t} = this.props;
        const {addForm, showWaitingList} = this.state;

        const participants = entity ?
            entity.entity.participants.filter(p => p.entity.onWaitingList === showWaitingList) :
            [];
        const courseId = entity ? entity.id : "";

        return (
            <div className="modal-content edit-participants-modal">
                <div className="modal-header p-4">
                    <CourseRelatedModalHeader title={t("Edit participants")} course={entity}/>
                </div>
                <div className="modal-body">
                    <WaitingListSwitcher course={entity}
                                         showWaitingList={showWaitingList}
                                         settingSwitched={newSetting => {
                                             this.setState({
                                                 ...this.state,
                                                 showWaitingList: newSetting
                                             });
                                         }}/>
                    <div className="p-4">
                        <EditParticipantsTable
                            waitingListMode={showWaitingList}
                            courseId={courseId as string}
                            participants={participants}
                        />
                        <div className="button-row mt-5">
                            {
                                addForm === "single" ?
                                    <SingleAdditionForm
                                        addToWaitingList={showWaitingList}
                                        parentEntityId={courseId as string}
                                        parentEntityType={CourseWithMeta.TYPE}
                                        doSwitchForms={() => this.setState({addForm: "bulk"})}/> :
                                    <BulkAdditionForm
                                        addToWaitingList={showWaitingList}
                                        parentEntityId={courseId as string}
                                        parentEntityType={CourseWithMeta.TYPE}
                                        doSwitchForms={() => this.setState({addForm: "single"})}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithSingleEntityFromPath<{}, CourseWithMeta, CourseForSaving>(
    withTranslation("courses")(EditParticipantsModal), CourseWithMeta.TYPE,
    "courseId");
