import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { addParticipantsAction } from "../../../../actions/add-participant";
import { Participant } from "../../../../model/participant";

import "./addition-form.scss";
import * as Yup from "yup";

interface SingleAdditionFormProps {
    parentEntityType: string;
    parentEntityId: string;
    addToWaitingList: boolean;
    doSwitchForms: () => void;
}

interface DispatchProps {
    addParticipants: (parentEntityType: string,
                      parentEntityId: string,
                      participants: Participant[]) => void;
}

type Props = SingleAdditionFormProps & DispatchProps & WithTranslation;

class SingleAdditionForm extends Component<Props> {

    public render() {
        const {parentEntityType, parentEntityId, addParticipants, doSwitchForms, addToWaitingList, t} = this.props;
        return (
            <div className="addition-form single">
                <a href="/" onClick={e => {
                    doSwitchForms()
                    e.preventDefault();
                    return false;
                }} className="mode-toggle">{t("Single")} <FontAwesomeIcon icon={faChevronDown}/></a>
                <Formik
                    initialValues={{
                        name: "",
                        email: ""
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required(t("Name is required")),
                        email: Yup.string().required(t("Email is required")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        addParticipants(parentEntityType, parentEntityId, [new Participant(
                            values.name,
                            values.email,
                            "not-attended",
                            addToWaitingList
                        )]);
                        setSubmitting(false);
                    }}>
                    {({isSubmitting}) => (
                        <Form>
                            <Field className="default-input mr-2 w-100" placeholder={t("Name")} type="name"
                                   name="name" required/>
                            <Field className="default-input mr-2 w-100" placeholder={t("Email")} type="email"
                                   name="email" required/>
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                {t("Add participant")}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default connect<{}, DispatchProps, SingleAdditionFormProps>(
    null,
    {
        addParticipants: addParticipantsAction
    }
)(withTranslation("courses")(SingleAdditionForm));
