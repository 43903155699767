import { inviteUsersAction } from "@thekeytechnology/framework-react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ModalHeader } from "../../../../courses/components/course/common/modal/ModalHeader";

import "./invite-users-modal.scss";

interface InviteUsersModalProps extends WithTranslation {
    inviteUsers: (emails: string[]) => void;
}

function InviteUsersModal(props: InviteUsersModalProps) {
    const {inviteUsers, t} = props;

    return (
        <div className="modal-content invite-users-modal">
            <div className="modal-header p-4">
                <ModalHeader title={t("Invite new users to account")}
                             returnPath="/users"/>
            </div>
            <div className="modal-body p-4 d-flex flex-column">
                <Formik initialValues={{
                    emails: "",
                }} onSubmit={(values, {setSubmitting}) => {
                    inviteUsers(values.emails.split("\n"));
                    setSubmitting(false);
                }}>
                    {({isSubmitting}) => (
                        <Form className="d-flex flex-column">
                            <div className="form-group">
                                <Field name="emails"
                                       className="default-input"
                                       component="textarea"
                                       placeholder={t("Enter one email per line")}
                                       rows="3"/>
                            </div>

                            <button type="submit" className="btn btn-primary ml-auto"
                                    disabled={isSubmitting}>
                                {t("Invite users to account")}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default connect(
    null,
    {
        inviteUsers: inviteUsersAction,
    }
)(withTranslation("auth")(InviteUsersModal));
