import {FieldProps} from "formik";
import React from "react";
import Select from "react-select";

interface OwnProps {
    trueLabel: string;
    falseLabel: string;
}

type Props = OwnProps & FieldProps;

const BooleanSelectFieldComponent = (props: Props) => {
    const {field, form, trueLabel, falseLabel} = props;
    const options = [
        {
            value: true,
            label: trueLabel
        },
        {
            value: false,
            label: falseLabel
        }
    ]
    return (<Select
        className="react-select"
        classNamePrefix="react-select"
        isClearable={false}
        options={options}
        name={field.name}
        value={field.value !== undefined ? options.find(o => o.value === field.value) : undefined}
        onChange={(item => form.setFieldValue(field.name, item ? (item as any).value : undefined))}
        getOptionValue={opt => opt.value as any}
        getOptionLabel={item => item.label}
        onBlur={field.onBlur}
    />);
};

export const BooleanSelectField = BooleanSelectFieldComponent;
