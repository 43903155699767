import { faCalendar, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
    API_FETCH_SETTINGS,
    API_SET_SETTING, fetchSettingsAction,
    selectJsonSetting,
    setSettingAction, ShowWhenApiSucceeded
} from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import * as Yup from "yup";
import { connect } from "react-redux";
import { ApiStateSubmitButton } from "../../core/components/form/ApiStateSubmitButton";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { ApiStatusButton } from "../../integrations/components/ApiStatusButton";

interface StateProps {
    calendarSettings?: any;
}

interface DispatchProps {
    setSetting: (key: string, value: string) => void;
    fetchSettings: (keys: string[]) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class CalendarIntegrationFormComponent extends Component<Props> {
    public componentDidMount(): void {
        this.props.fetchSettings(["calendar-settings"]);
    }

    public render() {
        const {t, setSetting, calendarSettings} = this.props;

        return (
            <ShowWhenApiSucceeded apiQualifier={API_FETCH_SETTINGS}
                                  callType="calendar-settings"
                                  duringLoading={<h3 className="mb-3">{t("calendar-integration.loading")}</h3>}>
                <Formik
                    initialValues={{
                        calendarId: calendarSettings.calendarId ? calendarSettings.calendarId : "",
                        user: calendarSettings.user ? calendarSettings.user : ""
                    }}
                    validationSchema={Yup.object().shape({
                        calendarId: Yup.string().required(t("calendar-integration.calendar-id") + " " + t("translation:is a required field")),
                        user: Yup.string().required(t("calendar-integration.user") + " " + t("translation:is a required field"))
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        setSetting("calendar-settings", JSON.stringify({
                            calendarId: values.calendarId,
                            user: values.user
                        }));
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => {
                        const hasError = !!(errors.calendarId && touched.calendarId) || !!(errors.user && touched.user);

                        return (
                            <Form>
                                <h3 className="mb-3 mt-5">{t("calendar-integration.heading")}</h3>
                                <div className="form-group row">
                                    <label htmlFor={"name"}
                                           className="col-sm-2 col-form-label">{t("calendar-integration.api-status")}</label>
                                    <div className="col-sm-10 d-flex align-items-center">
                                        <ApiStatusButton apiIdentifier="calendar"/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faCalendar}
                                                    label={t("calendar-integration.calendar-id")}
                                                    name="calendarId" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faEnvelope}
                                                    label={t("calendar-integration.user")}
                                                    name="user" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <ApiStateSubmitButton
                                    apiQualifier={API_SET_SETTING}
                                    callType={"calendar-settings"}
                                    submitText={t("calendar-integration.submit")}
                                    hasError={hasError}
                                    isSubmitting={isSubmitting}/>
                            </Form>
                        );
                    }}
                </Formik>
            </ShowWhenApiSucceeded>);
    }
}

export const CalendarIntegrationForm = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            calendarSettings: selectJsonSetting("calendar-settings")(state)
        };
    },
    {
        fetchSettings: fetchSettingsAction("calendar-settings"),
        setSetting: setSettingAction,
    }
)(withTranslation(["settings", "translation"])(CalendarIntegrationFormComponent));
