import { UserWithRoles } from "@thekeytechnology/framework-react";
import React from "react";
import { ModalPortal } from "../../core/components/modal/ModalPortal";
import { TrainerData } from "../model/trainer/trainer-data";
import { CourseCategoryOverview } from "./course-category/overview/CourseCategoryOverview";
import EditCourseCategory from "./course-category/single/EditCourseCategory";
import { CourseDefinitionOverview } from "./course-definition/overview/CourseDefinitionOverview";
import ScheduledEmailsOverview from "./course-definition/scheduled-emails/ScheduledEmailsOverview";
import EditScheduledEmail from "./course-definition/scheduled-emails/single/EditScheduledEmail";
import EditCourseDefinition from "./course-definition/single/EditCourseDefinition";
import { CourseGroupOverviev } from "./course-group/overview/CourseGroupOverview";
import EditCourseGroupsParticipantsModal from "./course-group/participants/EditCourseGroupsParticipantsModal";
import EditCourseGroup from "./course-group/single/EditCourseGroup";
import EmailParticipantsModal from "./course/emails/EmailParticipantsModal";
import { CourseOverview } from "./course/overview/CourseOverview";
import EditParticipantsModal from "./course/participants/EditParticipantsModal";
import EditCourse from "./course/single/EditCourse";
import { Enroll } from "./enroll/overview/Enroll";
import { MyCourses } from "./enroll/overview/MyCourses";
import { CourseDetail } from "./enroll/single/CourseDetail";
import { TrainerOverview } from "./trainers/overview/TrainerOverview";
import { EditTrainer } from "./trainers/single/EditTrainer";
import {PrivateRoute} from "../../core/components/navigation/PrivateRoute";

export const CourseModuleRoutes = () => {
    return (
        <>
            <PrivateRoute
                exact
                requiredRoles={[UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER, TrainerData.ROLE_TRAINER]}
                path="/my-courses" component={MyCourses}/>
            <PrivateRoute
                exact
                requiredRoles={[UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER, TrainerData.ROLE_TRAINER]}
                path="/enroll" component={Enroll}/>
            <PrivateRoute
                exact
                requiredRoles={[UserWithRoles.ROLE_ADMIN, UserWithRoles.ROLE_USER, TrainerData.ROLE_TRAINER]}
                path="/enroll/:courseId" component={CourseDetail}/>
            <PrivateRoute
                exact
                requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                path="/courses" component={CourseOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/courses/:courseId/edit" component={EditCourse}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/courses/:courseId/participants"
                          component={(props: any) => (
                              <>
                                  <CourseOverview {...props}/>
                                  <ModalPortal show={true}>
                                      <EditParticipantsModal {...props} />
                                  </ModalPortal>,
                              </>
                          )}
            />
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/courses/:courseId/email"
                          component={(props: any) => (
                              <>
                                  <CourseOverview {...props}/>
                                  <ModalPortal show={true}>
                                      <EmailParticipantsModal {...props} />
                                  </ModalPortal>,
                              </>
                          )}
            />
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/course-definitions" component={CourseDefinitionOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/course-definitions/:courseDefinitionId/edit"
                          component={EditCourseDefinition}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/course-definitions/:courseDefinitionId/scheduled-emails"
                          component={ScheduledEmailsOverview}/>
            <PrivateRoute
                exact
                requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                path="/course-definitions/:courseDefinitionId/scheduled-emails/:scheduledEmailId"
                component={EditScheduledEmail}/>

            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/course-categories" component={CourseCategoryOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/course-categories/:courseCategoryId/edit"
                          component={EditCourseCategory}/>

            <PrivateRoute exact
                          path="/course-groups"
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          component={CourseGroupOverviev}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/course-groups/:courseGroupId/edit"
                          component={EditCourseGroup}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/course-groups/:courseGroupId/participants"
                          component={(props: any) => (
                              <>
                                  <CourseGroupOverviev {...props}/>
                                  <ModalPortal show={true}>
                                      <EditCourseGroupsParticipantsModal {...props} />
                                  </ModalPortal>,
                              </>
                          )}
            />

            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/trainers"
                          component={TrainerOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/trainers/:trainerId/edit"
                          component={EditTrainer}/>
        </>
    );
};
