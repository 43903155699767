import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import React, {Component} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {PaddedContainer} from "../../../../../core/components/containers/PaddedContainer";
import {DateTimeDisplay} from "../../../../../core/components/display/DateTimeDisplay";
import {API_MY_COURSE_GROUPS, fetchMyCourseGroupsAction} from "../../../../actions/fetch-my-course-groups";
import {CourseGroupWithComponents} from "../../../../model/course-group/contextual-course-groups";
import {selectMyCourseGroups} from "../../../../selectors";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import "./my-course-groups.scss";

export interface MyCourseGroupsProps extends WithTranslation {
    myCourseGroups: CourseGroupWithComponents[];
    fetchMyCourseGroups: () => void;
}

class MyCourseGroupsComponent extends Component<MyCourseGroupsProps> {
    public componentDidMount(): void {
        this.props.fetchMyCourseGroups();
    }

    public render() {
        const {myCourseGroups, t} = this.props;

        if (myCourseGroups.length === 0) {
            return null;
        }

        return (
            <>
                <PaddedContainer className="mb-5">
                    <MyCourseGroups/>
                    {myCourseGroups.map(courseGroup => <div key={courseGroup.name}
                                                            className="course-group p-3 col-lg-6 col-12">
                        <h3>{t("course-group")}: {courseGroup.name}</h3>

                        <div className="course-group-components">
                            {courseGroup.components.map(component =>
                                <div key={component.definitionId}
                                     className={"course-group-component " + (component.hasTaken ? "has-taken" : "")}>
                                    <div className="course-name">
                                        {component.name}
                                    </div>
                                    <div className="next-date text-right">
                                        {
                                            component.hasTaken ?
                                                <FontAwesomeIcon icon={faCheck}/> :
                                                component.nextThree[0] ?
                                                    <NavLink to={"/enroll/" + component.nextThree[0].id}>
                                                        <DateTimeDisplay dateTime={component.nextThree[0].dateTime}/>
                                                    </NavLink>
                                                    : null
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>)}
                </PaddedContainer>
            </>
        );
    }
}

export const MyCourseGroups = connect(
    () => {
        return {
            myCourseGroups: selectMyCourseGroups,
            myCourseGroupsApiState: selectCombinedApiState(API_MY_COURSE_GROUPS)
        };
    },
    {
        fetchMyCourseGroups: fetchMyCourseGroupsAction
    }
)(withTranslation("courses")(MyCourseGroupsComponent as any));
