import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {Billing} from "./components/Billing";
import {PrivateRoute} from "../core/components/navigation/PrivateRoute";

export const BillingModuleRoutes = () => {
    return (
        <>
            <PrivateRoute
                exact
                requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                path="/billing" component={Billing}/>
        </>
    );
};
