import { TkFile } from "@thekeytechnology/framework-react";
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    BlueBgContainer,
    HeadingContainer,
    PaginationContainer,
    ContentContainer
} from "../../../core/components/containers/Container";
import { SimpleHeader } from "../../../core/components/header/SimpleHeader";
import Pagination from "../../../core/components/pagination/Pagination";
import { FileFilters } from "./filter/FileFilters";
import { FilesTable } from "./table/FilesTable";
import { Uploader } from "./Uploader";

const FilesOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/files"}>{t("Files")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("Files")}/>
            </HeadingContainer>
            <BlueBgContainer>
                <FileFilters/>
            </BlueBgContainer>
            <ContentContainer>
                <Uploader/>
                <FilesTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={TkFile.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const FilesOverview = withTranslation("files")(FilesOverviewComponent);
