import {
    API_CREATE_ENTITIES,
    API_UPDATE_ENTITIES,
    matchesAnyOfTheseApiSuccesses
} from "@thekeytechnology/framework-react";
import {push} from "connected-react-router";
import {Action} from "redux";
import {combineEpics} from "redux-observable";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CoachingOffer} from "../model/coaching-offer";

const redirectAfterCoachingOffereWrite$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesAnyOfTheseApiSuccesses({
            apiType: API_CREATE_ENTITIES, callType: CoachingOffer.TYPE
        }, {
            apiType: API_UPDATE_ENTITIES, callType: CoachingOffer.TYPE
        }), map(() => push("/coaching-offers"))
    );

export const redirectEpics$ = combineEpics(
    redirectAfterCoachingOffereWrite$,
);
