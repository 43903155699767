import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DialogResult, DialogSettings, showDialogAction } from "@thekeytechnology/framework-react";
import { removeUsersAction } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";

interface RemoveUserButtonProps extends WithTranslation {
    selectedEntities: string[];

    removeUsers: (ids: string[]) => void;
    showDialog: (dialogSettings: DialogSettings,
                 callback: (result: DialogResult) => void) => void;
}

const RemoveUserButtonComponent = (props: RemoveUserButtonProps) => {
    const {selectedEntities, showDialog, removeUsers, t} = props;

    return (
        <button type="button" onClick={() => {
            showDialog({
                title: t("Removing users from account"),
                question: t("Do you really want to remove") + " " + selectedEntities.length + " " + t("users from the account") + "?",
                explanation: t("You can reinvite the users at a later time") + ".",
                affirmativeText: t("Remove"),
                negativeText: t("Cancel"),
                affirmativeButtonClass: "btn btn-danger"
            }, (r: DialogResult) => {
                if (r === DialogResult.ACCEPT) {
                    removeUsers(selectedEntities);
                }
            });
        }} className="btn btn-danger" disabled={selectedEntities.length === 0}>
            <FontAwesomeIcon className="mr-2" icon={faTimes}/>{t("translation:Delete")}
        </button>
    );
};
export const RemoveUsersButton = connect(
    null,
    dispatch => {
        // noinspection JSUnusedGlobalSymbols
        return {
            showDialog: (dialogSettings: DialogSettings,
                         callback: (result: DialogResult) => void) =>
                dispatch(showDialogAction(dialogSettings, callback)),
            removeUsers: (ids: string[]) => dispatch(removeUsersAction(ids)),
        };
    }
)(withTranslation(["auth", "translation"])(RemoveUserButtonComponent));
