import { faEuroSign, faPercent } from "@fortawesome/free-solid-svg-icons";
import {
    EntityWrapper,
    ShowWhenApiSucceeded, UserWithRoles
} from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import * as Yup from "yup";
import { connect } from "react-redux";
import { ApiStateSubmitButton } from "../../core/components/form/ApiStateSubmitButton";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { getUserBudgetsAction, API_GET_USER_BUDGETS } from "../actions/get-user-budgets";
import { API_SET_USER_BUDGETS, setUserBudgetsAction } from "../actions/set-user-budgets";
import { UserBudget } from "../model/UserBudget";
import { selectUserBudget } from "../selectors";

interface BudgetFormProps {
    user: EntityWrapper<UserWithRoles>;
}

interface StateProps {
    userBudget?: UserBudget;
}

interface DispatchProps {
    getUserBudgets: (userIds: string[]) => void;
    setUserBudgets: (userIds: string[], budget: number, proRatingRatio: number) => void;
}

type Props = BudgetFormProps & StateProps & DispatchProps & WithTranslation;

class EditUserBudgetFormComponent extends Component<Props> {
    public componentDidMount(): void {
        this.props.getUserBudgets([this.props.user.id as string]);
    }

    public render() {
        const {t, user, userBudget, setUserBudgets} = this.props;

        return (
            <ShowWhenApiSucceeded apiQualifier={API_GET_USER_BUDGETS}
                                  duringLoading={<h3 className="mb-3">{t("user-budget-form.loading")}</h3>}>
                <Formik
                    initialValues={{
                        budget: userBudget ? userBudget.budget : 0,
                        proRateRatio: userBudget ? userBudget.proRateRatio : 1
                    }}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                        budget: Yup.string().required(t("user-budget-form.budget") + " " + t("translation:is a required field")),
                        proRateRatio: Yup.number()
                            .min(0)
                            .max(1)
                            .required(t("user-budget-form.proRateRatio") + " " + t("translation:is a required field"))
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        setUserBudgets([user.id as string], values.budget, values.proRateRatio);
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => {
                        const hasError = !!(errors.budget && touched.budget) || !!(errors.proRateRatio && touched.proRateRatio);

                        return (
                            <Form>
                                <h3 className="mb-3 mt-5">{t("user-budget-form.heading")}</h3>
                                <div className="form-group row">
                                    <ValidatedField icon={faEuroSign}
                                                    label={t("user-budget-form.budget")}
                                                    name="budget" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faPercent}
                                                    type="number"
                                                    min="0"
                                                    max="1"
                                                    step="0.01"
                                                    label={t("user-budget-form.proRateRatio")}
                                                    name="proRateRatio" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <ApiStateSubmitButton
                                    apiQualifier={API_SET_USER_BUDGETS}
                                    submitText={t("user-budget-form.submit")}
                                    hasError={hasError}
                                    isSubmitting={isSubmitting}/>
                            </Form>
                        );
                    }}
                </Formik>
            </ShowWhenApiSucceeded>);
    }
}

export const EditUserBudgetForm = connect<StateProps, DispatchProps, BudgetFormProps>(
    (state: any, ownProps: BudgetFormProps) => {
        return {
            userBudget: selectUserBudget(ownProps.user.id as string)(state as never)
        };
    },
    {
        getUserBudgets: getUserBudgetsAction,
        setUserBudgets: setUserBudgetsAction
    }
)(withTranslation(["budget", "translation"])(EditUserBudgetFormComponent));
