import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { API_UPDATE_ROLES, UserWithRoles } from "@thekeytechnology/framework-react";
import { updateRolesAction } from "@thekeytechnology/framework-react";
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { EditUserBudgetForm } from "../../../budget/components/UserBudgetForm";
import { ShowOnlyWhenBudgetEnabled } from "../../../budget/components/ShowOnlyWhenBudgetEnabled";
import { BlueBgContainer } from "../../../core/components/containers/Container";
import { SingleValueForm } from "../../../core/components/form/SingleValueForm";
import { SimpleEditHeader } from "../../../core/components/header/LoadingEditHeader";
import { RolesSelectField } from "./RolesSelectField";
import { connect } from "react-redux";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../core/components/entity/WithSingleEntityFromPath";

interface DispatchProps {
    updateUserRoles: (userId: string, roles: string[]) => void;
}

type Props = WithSingleEntityFromPathProps<UserWithRoles, UserWithRoles> & DispatchProps & WithTranslation;

const EditUserRolesComponent = (props: Props) => {
    const {entity, updateUserRoles, t} = props;

    return (
        <>
            <BreadcrumbsItem to={"/users"}>Users</BreadcrumbsItem>
            {entity ? <BreadcrumbsItem
                to={"/users/" + entity.id + "/roles"}>
                {t("user-settings.heading")} {entity.entity.name}
            </BreadcrumbsItem> : null}
            <div className="course-list bg-white h-100">
                <SimpleEditHeader entity={entity} entityTypeName={t("User")}/>

                {entity ?
                    <>
                        <BlueBgContainer className="edit-header d-flex flex-column flex-lg-row">
                            <div className="heading">
                                <h2 className="mb-0">{entity.entity.name}</h2>
                            </div>
                        </BlueBgContainer>
                        <div className="pl-5 pt-5 pb-3 pr-5">
                            <div className="form-group row">
                                <SingleValueForm initialValue={entity.entity.roles}
                                                 label={t("Roles")}
                                                 apiQualifier={API_UPDATE_ROLES}
                                                 icon={faUsers}
                                                 className="w-100"
                                                 validationSchema={Yup.array(Yup.string()).required(t("Please provide at least one role"))}
                                                 component={RolesSelectField}
                                                 onSubmit={value => updateUserRoles(entity.id as string, value)}/>

                                <ShowOnlyWhenBudgetEnabled>
                                    <EditUserBudgetForm user={entity}/>
                                </ShowOnlyWhenBudgetEnabled>
                            </div>
                        </div>
                    </>
                    : null}
            </div>
        </>
    );
};

export const EditUser = WithSingleEntityFromPath<{}, UserWithRoles, UserWithRoles>(connect<{}, DispatchProps, {}>(
    null,
    {
        updateUserRoles: updateRolesAction
    }
)(withTranslation("auth")(EditUserRolesComponent)), UserWithRoles.TYPE, "userId");
