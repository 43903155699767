import { EntityWrapper, SelectionModel } from "@thekeytechnology/framework-react";
import { TkFile } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { SelectAllInModelCheckbox } from "../../../../core/components/selection/SelectAllInModelCheckbox";
import { TableColumn, TableHeader } from "../../../../core/components/table/Table";

interface FilesTableHeaderProps extends WithTranslation {
    files: Array<EntityWrapper<TkFile>>
    selectionModel: SelectionModel<string>
}

export const FilesTableHeaderComponent = (props: FilesTableHeaderProps) => {
    const {files, selectionModel, t} = props;
    return (
        <TableHeader>
            <TableColumn>
                {selectionModel.isSingleSelect ?
                    null :
                    <SelectAllInModelCheckbox items={files.map(c => c.id as string)} selectionModel={selectionModel}/>
                }
            </TableColumn>
            <TableColumn>{t("Name")}</TableColumn>
            <TableColumn>{t("Uploaded at")}</TableColumn>
            <TableColumn>{t("Type")}</TableColumn>
            <TableColumn>
                {t("Preview")}
            </TableColumn>
        </TableHeader>
    );
};

export const FilesTableHeader = withTranslation("files")(FilesTableHeaderComponent);
