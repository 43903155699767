import { FieldProps } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { WeeklySchedule } from "../../../../model/schedule/weekly-schedule";
import { EveryXScheduleField } from "./EveryXScheduleField";

interface WeeklyScheduleFieldState {
    startDate?: string;
    endDate?: string;
    every: number;
    daysOfWeek: string[];
}

class WeeklyScheduleFieldComponent extends Component<FieldProps & WithTranslation, WeeklyScheduleFieldState> {
    public constructor(props: FieldProps & WithTranslation) {
        super(props);
        this.updateSchedule = this.updateSchedule.bind(this);
        this.updateDayOfWeek = this.updateDayOfWeek.bind(this);

        const {field} = props;
        const schedule = field.value && field.value.scheduleType === WeeklySchedule.TYPE ?
            field.value as WeeklySchedule : new WeeklySchedule(new Date().toISOString(), undefined, 1, []);
        this.state = {
            startDate: schedule.start,
            endDate: schedule.end,
            every: schedule.every,
            daysOfWeek: schedule.daysOfWeek
        };
    }

    public updateSchedule(newState: WeeklyScheduleFieldState) {
        this.setState(newState);

        const newSchedule = new WeeklySchedule(
            newState.startDate,
            newState.endDate,
            newState.every,
            newState.daysOfWeek
        );
        this.props.form.setFieldValue(this.props.field.name, newSchedule);
    }

    public updateDayOfWeek(day: string) {
        const exists = this.state.daysOfWeek.indexOf(day) > -1;

        let newState;
        if (!exists) {
            newState = {
                ...this.state,
                daysOfWeek: Array.from(new Set([
                    ...this.state.daysOfWeek,
                    day]))
            };
        } else {
            newState = {
                ...this.state,
                daysOfWeek: this.state.daysOfWeek.filter(i => i !== day)
            };
        }

        this.updateSchedule(newState);
    }

    public render() {
        const {t} = this.props;
        return (
            <>
                <EveryXScheduleField
                    onChange={(startDate, endDate, every) => {
                        this.updateSchedule({
                            ...this.state,
                            startDate,
                            endDate,
                            every
                        });
                    }}
                    typeName="weeks"
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    every={this.state.every}/>
                <div className="form-group row mb-4">
                    <label htmlFor="start-date" className="col-sm-2 col-form-label">
                        {t("Days of the week")}</label>
                    <div className="col-sm-10">
                        <label className="d-flex align-items-center mb-3">
                            <input type="checkbox"
                                   checked={this.state.daysOfWeek.indexOf("mon") > -1}
                                   onChange={() => this.updateDayOfWeek("mon")}
                            />
                            <span className="ml-4">{t("Monday")}</span>
                        </label>
                        <label className="d-flex align-items-center mb-3">
                            <input type="checkbox"
                                   checked={this.state.daysOfWeek.indexOf("tue") > -1}
                                   onChange={() => this.updateDayOfWeek("tue")}
                            />
                            <span className="ml-4">{t("Tuesday")}</span>
                        </label>
                        <label className="d-flex align-items-center mb-3">
                            <input type="checkbox"
                                   checked={this.state.daysOfWeek.indexOf("wed") > -1}
                                   onChange={() => this.updateDayOfWeek("wed")}
                            />
                            <span className="ml-4">{t("Wednesday")}</span>
                        </label>
                        <label className="d-flex align-items-center mb-3">
                            <input type="checkbox"
                                   checked={this.state.daysOfWeek.indexOf("thu") > -1}
                                   onChange={() => this.updateDayOfWeek("thu")}
                            />
                            <span className="ml-4">{t("Thursday")}</span>
                        </label>
                        <label className="d-flex align-items-center mb-3">
                            <input type="checkbox"
                                   checked={this.state.daysOfWeek.indexOf("fri") > -1}
                                   onChange={() => this.updateDayOfWeek("fri")}
                            />
                            <span className="ml-4">{t("Friday")}</span>
                        </label>
                        <label className="d-flex align-items-center mb-3">
                            <input type="checkbox"
                                   checked={this.state.daysOfWeek.indexOf("sat") > -1}
                                   onChange={() => this.updateDayOfWeek("sat")}
                            />
                            <span className="ml-4">{t("Saturday")}</span>
                        </label>
                        <label className="d-flex align-items-center mb-3">
                            <input type="checkbox"
                                   checked={this.state.daysOfWeek.indexOf("sun") > -1}
                                   onChange={() => this.updateDayOfWeek("sun")}
                            />
                            <span className="ml-4">{t("Sunday")}</span>
                        </label>
                    </div>
                </div>
            </>
        );
    }
}

export const WeeklyScheduleField = withTranslation("courses")(WeeklyScheduleFieldComponent);
