import React from "react";
import FlagIcon from "../flag-icon/FlagIcon";

import "./language-switcher.scss";

interface LanguageSwitcherProps {
    availableLanguages: string[];
    selectedLanguage: string;
    selectLanguage: (language: string) => void;
}

export const LanguageSwitcher = (props: LanguageSwitcherProps) => {
    return (
        <div className="language-switcher btn-group btn-group-toggle">
            {props.availableLanguages.map(language => {
                    const code = language === "en" ? "us" : language;
                    return <button type="button"
                                   key={language}
                                   className={"btn btn-secondary " + (props.selectedLanguage === language ? "active" : "")}
                                   onClick={() => props.selectLanguage(language)}>
                        <FlagIcon code={code}/>
                    </button>;
                }
            )}
        </div>
    );
};
