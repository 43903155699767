import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Participant } from "../../../../model/participant";

export interface ParticipantStatusDisplayProps extends WithTranslation {
    participant: EntityWrapper<Participant>;
}

class ParticipantStatusDisplayComponent extends React.Component<ParticipantStatusDisplayProps> {
    public render() {
        const {participant, t} = this.props;

        let badgeClass;
        let statusText;
        switch (participant.entity.status) {
            case "not-attended":
                statusText = t("participant-status.not-attended");
                badgeClass = "badge-danger";
                break;
            case "attended":
                statusText = t("participant-status.attended");
                badgeClass = "badge-success";
                break;
            case "excused":
                statusText = t("participant-status.excused");
                badgeClass = "badge-warning";
        }

        return (
            <div className={"badge badge-pill " + badgeClass}>
                {statusText}
            </div>
        );
    }
}

export const ParticipantStatusDisplay = withTranslation("courses")(ParticipantStatusDisplayComponent);
