import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    BlueBgContainer,
    HeadingContainer,
    PaginationContainer,
    ContentContainer
} from "../../../../core/components/containers/Container";
import { SimpleHeader } from "../../../../core/components/header/SimpleHeader";
import Pagination from "../../../../core/components/pagination/Pagination";
import { CoachingOffer } from "../../../model/coaching-offer";
import { CoachingOffersFilter } from "../../coaching-offers/overview/filter/CoachingOffersFilter";
import { AvailableOffersCards} from "./CoachingOffersCards";

function CoachingOffersOverviewComponent(props: WithTranslation) {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/available-offers"}>{t("coaching-offers.breadcrumb")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("coaching-offers.heading")}/>
            </HeadingContainer>
            <BlueBgContainer>
                <CoachingOffersFilter/>
            </BlueBgContainer>
            <ContentContainer>
                <AvailableOffersCards/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={CoachingOffer.TYPE}/>
            </PaginationContainer>
        </>
    );
}

export const AvailableOffersOverview = withTranslation("coaching")(CoachingOffersOverviewComponent);
