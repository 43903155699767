import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_CANCEL_COURSES = "cancel-courses";

export interface CancelCoursesPayload {
    courseIds: string[];
}

export const cancelCoursesAction = (courseIds: string[]) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_CANCEL_COURSES,
            callType: API_CANCEL_COURSES
        },
        payload: {
            courseIds,
        }
    } as ApiCallAction<CancelCoursesPayload>;
};
