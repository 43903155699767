import {combineEpics, ofType} from "redux-observable";
import {ChangeAccount, ChangeAccountAction} from "@thekeytechnology/framework-react";
import {map, withLatestFrom} from "rxjs/operators";
import {saveState} from "../../../localStorage";
import {push} from "connected-react-router";
import {Observable} from "rxjs";
import {Action} from "redux";

const reloadOnAccountChange$ = (action$: Observable<Action>, state$: any) => action$.pipe(
    ofType(ChangeAccount),
    withLatestFrom(state$) as any,
    map(([, state]: [ChangeAccountAction, any]) => {
        saveState({
            auth: {
                authState: state.auth.authState
            },
        });
        setTimeout(() => {
            // eslint-disable-next-line
            location.reload();
        }, 300);
        return push("/");
    })
);

export const reloadActions$ = combineEpics(
    reloadOnAccountChange$
);
