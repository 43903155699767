import { ApiCallSucceededAction, isApiSuccess } from "@thekeytechnology/framework-react";
import { EntityWrapper, PaginationResponse } from "@thekeytechnology/framework-react";
import { API_MY_COURSE_GROUPS } from "../actions/fetch-my-course-groups";
import { API_MY_COURSES } from "../actions/fetch-my-courses";
import {
    ContextualCourseGroupResponse,
    CourseGroupWithComponents
} from "../model/course-group/contextual-course-groups";
import { CourseWithMeta } from "../model/course/course-with-meta";

export interface MyCoursesState {
    myCourses: Array<EntityWrapper<CourseWithMeta>>,
    myCourseGroups: CourseGroupWithComponents[];
}

export const initialState: MyCoursesState = {
    myCourses: [],
    myCourseGroups: []
};

export function myCoursesReducer(state = initialState, action: any): MyCoursesState {
    if (isApiSuccess(API_MY_COURSES)(action)) {
        const myCoursesAction = action as ApiCallSucceededAction<PaginationResponse<EntityWrapper<CourseWithMeta>>>;

        return {
            ...state,
            myCourses: myCoursesAction.payload.entities
        };
    }
    if (isApiSuccess(API_MY_COURSE_GROUPS)(action)) {
        const courseGroupsAction = action as ApiCallSucceededAction<ContextualCourseGroupResponse>;

        return {
            ...state,
            myCourseGroups: courseGroupsAction.payload.groups
        };
    }

    return state;
}
