import { CourseWithMeta } from "./course-with-meta";

export class CourseForSaving {
    public static TYPE = CourseWithMeta.TYPE;

    constructor(public name: string,
                public courseDefinition: string,
                public dateTime: string,
                public durationInMinutes: number,
                public capacity: number,
                public category: string | undefined,
                public trainer: string | undefined,
                public isCancelled: boolean,
                public status: string) {
    }
}
