import { addFiltersAction, removeFiltersAction, selectFilter } from "@thekeytechnology/framework-react";
import {
    Filter,
    FilterTerm,
    OrFilter,
    PropertyFilter
} from "@thekeytechnology/framework-react";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CourseWithMeta } from "../../../../model/course/course-with-meta";
import "./course-overview-header.scss";

interface StateProps {
    currentFilters?: Filter[];
}

interface DispatchProps {
    addFilters: ReturnType<typeof addFiltersAction>;
    removeFilters: ReturnType<typeof removeFiltersAction>;

}

type Props = StateProps & DispatchProps & WithTranslation;

class CourseOverviewHeader extends Component<Props> {

    public constructor(props: Props) {
        super(props);
        this.resetFilters = this.resetFilters.bind(this);
        this.setFuture = this.setFuture.bind(this);
        this.setFutureAndNonFinalized = this.setFutureAndNonFinalized.bind(this);
    }

    public componentDidMount(): void {
        const {currentFilters} = this.props;

        if (currentFilters == null) {
            this.setFutureAndNonFinalized();
        }
    }

    public componentWillUnmount(): void {
        this.resetFilters();
    }

    public render() {
        const {currentFilters, t} = this.props;

        return (
            <div className="course-overview-header d-flex ">
                <h1>{t("Courses")}</h1>
                <div className="separator ml-4 mr-4"/>
                <button type="button"
                        onClick={this.setFutureAndNonFinalized}
                        className={"tab " + (currentFilters && currentFilters.length === 2 ? "active" : "")}
                >{t("Future and non finalized")}
                </button>
                <button type="button" onClick={this.setFuture}
                        className={"tab " + (currentFilters && currentFilters.length === 1 ? "active" : "")}
                >{t("Future")}</button>
                <button type="button" onClick={this.resetFilters}
                        className={"tab " + (currentFilters === undefined || currentFilters.length === 0 ? "active" : "")}>
                    {t("All courses")}
                </button>
            </div>
        );
    }

    public setFutureAndNonFinalized() {
        this.props.addFilters("course-global-filter", [
                new PropertyFilter("entity.isCancelled", new FilterTerm(FilterTerm.TYPE_BOOLEAN, FilterTerm.OPERATION_EQ, false)),
                new OrFilter([
                        new PropertyFilter("entity.dateTime",
                            new FilterTerm(FilterTerm.TYPE_DATE_TIME, FilterTerm.OPERATION_GTE, new Date().toISOString())
                        ),
                        new PropertyFilter("entity.status",
                            new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, "not-finalized")
                        )
                    ]
                )
            ]
        );
    }

    public setFuture() {
        this.props.addFilters("course-global-filter", [
                new PropertyFilter("entity.dateTime",
                    new FilterTerm(FilterTerm.TYPE_DATE_TIME, FilterTerm.OPERATION_GTE, new Date().toISOString())
                )
            ]
        );
    }

    public resetFilters() {
        this.props.removeFilters(["course-global-filter"]);
    }
}

export default withTranslation("courses")(connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            currentFilters: selectFilter(CourseWithMeta.TYPE, "course-global-filter")(state)
        };
    },
    {
        addFilters: addFiltersAction(CourseWithMeta.TYPE),
        removeFilters: removeFiltersAction(CourseWithMeta.TYPE)
    }
)(CourseOverviewHeader));
