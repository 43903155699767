import { FieldProps } from "formik";
import React, { Component } from "react";
import { DailySchedule } from "../../../../model/schedule/daily-schedule";
import { EveryXScheduleField } from "./EveryXScheduleField";

interface DailyScheduleFieldState {
    startDate?: string;
    endDate?: string;
    every: number;
}

export class DailyScheduleField extends Component<FieldProps, DailyScheduleFieldState> {
    public constructor(props: FieldProps) {
        super(props);
        this.updateSchedule = this.updateSchedule.bind(this);
        const {field} = props;
        const schedule = field.value && field.value.scheduleType === DailySchedule.TYPE ?
            field.value as DailySchedule : new DailySchedule(new Date().toISOString(), undefined, 1);
        this.state = {
            startDate: schedule.start,
            endDate: schedule.end,
            every: schedule.every
        };
    }

    public updateSchedule(newState: DailyScheduleFieldState) {
        this.setState(newState);

        const newSchedule = new DailySchedule(
            newState.startDate,
            newState.endDate,
            newState.every,
        );
        this.props.form.setFieldValue(this.props.field.name, newSchedule);
    }

    public render() {
        return (
            <EveryXScheduleField
                onChange={(startDate, endDate, every) => {
                    this.updateSchedule({startDate, endDate, every});
                }}
                typeName="days"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                every={this.state.every}/>
        );
    }
}
