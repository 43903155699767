import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { CourseGroupForSaving } from "../../../model/course-group/course-group-for-saving";
import { CourseGroupWithMeta } from "../../../model/course-group/course-group-with-meta";
import BulkAdditionForm from "../../common/participants/addition/BulkAdditionForm";
import SingleAdditionForm from "../../common/participants/addition/SingleAdditionForm";
import { ModalHeader } from "../../course/common/modal/ModalHeader";
import EditParticipantsTable from "./table/EditParticipantsTable";

import "./edit-course-groups-participants-modal.scss";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";

type Props = WithSingleEntityFromPathProps<CourseGroupWithMeta, CourseGroupForSaving> & WithTranslation;

interface EditCourseGroupsParticipantsModalState {
    addForm: string;
}

class EditCourseGroupsParticipantsModal extends Component<Props, EditCourseGroupsParticipantsModalState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            addForm: "single",
        };
    }

    public render() {
        const {entity, t} = this.props;
        const {addForm} = this.state;

        const courseGroupId = entity ? entity.id : "";

        return (
            <div className="modal-content edit-participants-modal">
                <div className="modal-header p-4">
                    <ModalHeader title={t("Edit participants")}
                                 Lead={entity ? entity.entity.name : <>&nbsp;</>}
                                 returnPath="/course-groups"/>
                </div>
                <div className="modal-body">
                    <div className="p-4">
                        <EditParticipantsTable
                            courseGroupId={courseGroupId as string}
                            participants={entity ? entity.entity.participants : []}
                        />
                        <div className="button-row mt-5">
                            {
                                addForm === "single" ?
                                    <SingleAdditionForm
                                        addToWaitingList={false}
                                        parentEntityId={courseGroupId as string}
                                        parentEntityType={CourseGroupWithMeta.TYPE}
                                        doSwitchForms={() => this.setState({addForm: "bulk"})}/> :
                                    <BulkAdditionForm
                                        addToWaitingList={false}
                                        parentEntityId={courseGroupId as string}
                                        parentEntityType={CourseGroupWithMeta.TYPE}
                                        doSwitchForms={() => this.setState({addForm: "single"})}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WithSingleEntityFromPath<{}, CourseGroupWithMeta, CourseGroupForSaving>(
    withTranslation("courses")(EditCourseGroupsParticipantsModal), CourseGroupWithMeta.TYPE,
    "courseGroupId");
