import { faHeading } from "@fortawesome/free-solid-svg-icons";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { withTranslation, WithTranslation } from "react-i18next";
import { SaveAndBackButtons } from "../../../../core/components/buttons/SaveAndBackButtons";
import { ContentContainer } from "../../../../core/components/containers/Container";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { SimpleEditHeader } from "../../../../core/components/header/LoadingEditHeader";
import { AttachmentField } from "../../../../files/components/attachment/AttachmentField";
import { TrainerData } from "../../../model/trainer/trainer-data";
import { TrainerDataForSaving } from "../../../model/trainer/trainer-data-for-saving";
import { EditHeaderWithBackground } from "../../course/common/edit/EditHeaderWithBackground";
import * as Yup from "yup";
import { TrainerTypeField } from "./TrainerTypeField";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";
import {WysiwygField} from "../../../../core/components/wysiwyg/WysiwygField";

type Props = WithSingleEntityFromPathProps<TrainerData, TrainerDataForSaving> & WithTranslation;

const EditTrainerComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return (
        <>
            <BreadcrumbsItem to={"/trainers"}>{t("Trainers")}</BreadcrumbsItem>
            {entity ? <BreadcrumbsItem
                to={"/trainers/" + entity.id + "/edit"}>
                {t("Trainer profile for")} {entity.entity.name}
            </BreadcrumbsItem> : null}
            <SimpleEditHeader entity={entity} entityTypeName={t("trainer")}/>
            {entity ?
                <Formik
                    initialValues={{
                        trainerType: entity.entity.trainerType,
                        shortDescription: entity.entity.shortDescription,
                        avatarFile: entity.entity.avatarFile,
                    }}
                    validationSchema={Yup.object().shape({
                        trainerType: Yup.string().required(t("Trainer type") + " " + t("translation:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const saving = new EntityWrapper(entity.id,
                            new TrainerDataForSaving(
                                entity.entity.userIdRef,
                                values.shortDescription,
                                values.trainerType,
                                values.avatarFile ? values.avatarFile.id as string : undefined
                            )
                        );
                        upsertEntity(saving);
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={t("Trainer profile for") + " " + entity.entity.name}>
                                <SaveAndBackButtons isSubmitting={isSubmitting}
                                                    entity={entity}
                                                    backPath={"/trainers"}/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                <div className="form-group row">
                                    <ValidatedField icon={faHeading} label={t("Trainer type")} name="trainerType"
                                                    errors={errors}
                                                    touched={touched} component={TrainerTypeField}
                                                    required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField label={t("Short description")}
                                                    name="shortDescription"
                                                    errors={errors}
                                                    touched={touched}
                                                    component={WysiwygField}
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField label={t("Avatar")}
                                                    name="avatarFile"
                                                    errors={errors}
                                                    touched={touched}
                                                    isSingleSelect={true}
                                                    component={AttachmentField}
                                    />
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik> : null}
        </>
    );
};

export const EditTrainer = WithSingleEntityFromPath<{}, TrainerData, TrainerDataForSaving>(
    withTranslation("courses")(EditTrainerComponent), TrainerData.TYPE,
    "trainerId");
