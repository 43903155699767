import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    BlueBgContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../../core/components/containers/Container";
import { SimpleHeader } from "../../../core/components/header/SimpleHeader";
import Pagination from "../../../core/components/pagination/Pagination";
import { Transaction } from "../../model/Transaction";
import { TransactionFilters } from "./TransactionFilters";

import { TransactionTable } from "./TransactionTable";

const TransactionOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to="/transactions">{t("transaction-overview.heading")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("transaction-overview.heading")}/>
            </HeadingContainer>
            <BlueBgContainer>
                <TransactionFilters/>
            </BlueBgContainer>
            <ContentContainer>
                <TransactionTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={Transaction.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const TransctionOverview = withTranslation("transactionlog")(TransactionOverviewComponent);
