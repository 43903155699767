import { FieldProps } from "formik";
import React, { Component } from "react";
import { YearlySchedule } from "../../../../model/schedule/yearly-schedule";
import { EveryXScheduleField } from "./EveryXScheduleField";

interface YearlyScheduleFieldState {
    startDate?: string;
    endDate?: string;
    every: number;
}

export class YearlyScheduleField extends Component<FieldProps, YearlyScheduleFieldState> {
    public constructor(props: FieldProps) {
        super(props);
        this.updateSchedule = this.updateSchedule.bind(this);
        const {field} = props;
        const schedule = field.value && field.value.scheduleType === YearlySchedule.TYPE ?
            field.value as YearlySchedule : new YearlySchedule(new Date().toISOString(), undefined, 1);
        this.state = {
            startDate: schedule.start,
            endDate: schedule.end,
            every: schedule.every
        };
    }

    public updateSchedule(newState: YearlyScheduleFieldState) {
        this.setState(newState);

        const newSchedule = new YearlySchedule(
            newState.startDate,
            newState.endDate,
            newState.every,
        );
        this.props.form.setFieldValue(this.props.field.name, newSchedule);
    }

    public render() {
        return (
            <EveryXScheduleField
                onChange={(startDate, endDate, every) => {
                    this.updateSchedule({startDate, endDate, every});
                }}
                typeName="years"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                every={this.state.every}/>
        );
    }
}
