import { EntityWrapper } from "@thekeytechnology/framework-react";
import { TrainerData } from "../../courses/model/trainer/trainer-data";
import { BookedSession } from "./booked-session";

export class CoachingOffer {
    public static TYPE = "coaching-offer";

    public static new() {
        return new EntityWrapper(undefined, new CoachingOffer(
            undefined,
            [],
            "",
            "",
            0,
            false
        ));
    }

    constructor(public trainer: EntityWrapper<TrainerData> | undefined,
                public sessions: Array<EntityWrapper<BookedSession>>,
                public title: string,
                public description: string | undefined,
                public cost: number,
                public freeFirstSession: boolean) {
    }
}
