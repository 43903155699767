import { EntityWrapper, SelectionModel } from "@thekeytechnology/framework-react";
import { TkFile } from "@thekeytechnology/framework-react";
import { FieldProps } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ModalPortal } from "../../../core/components/modal/ModalPortal";
import { BrowseFilesModal } from "../modal/BrowseFilesModal";
import { AttachmentDisplay } from "./AttachmentDisplay";

interface AttachmentFieldProps extends WithTranslation {
    selectionModel: SelectionModel<string>
    isSingleSelect: boolean;
}

interface AttachmentFieldState {
    modalShown: boolean;
}

class AttachmentFieldComponent extends Component<FieldProps & AttachmentFieldProps, AttachmentFieldState> {
    public constructor(props: FieldProps & AttachmentFieldProps) {
        super(props);
        this.state = {
            modalShown: false
        };
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.remove = this.remove.bind(this);
    }

    public remove(item: EntityWrapper<TkFile>) {
        const {field, isSingleSelect} = this.props;

        if (isSingleSelect) {
            this.onSelectionChange(undefined);
        } else {
            this.onSelectionChange(field.value.filter((f: any) => f.id !== item.id));
        }
    }

    public onSelectionChange(selections: Array<EntityWrapper<TkFile>> | undefined) {
        const {field, form, isSingleSelect} = this.props;

        const newValue = isSingleSelect ? (selections && selections.length > 0 ? selections[0] : undefined) : selections;
        form.setFieldValue(field.name, newValue);
        this.setState({
            modalShown: false
        });
    }

    public render() {
        const {field, isSingleSelect, t} = this.props;
        const {modalShown} = this.state;

        return (
            <>
                <div className="">
                    {field.value ?
                        <ul className="list-group mb-3">
                            {isSingleSelect ? <AttachmentDisplay item={field.value} remove={this.remove}/>
                                :
                                field.value.map((file: EntityWrapper<TkFile>) =>
                                    <AttachmentDisplay key={file.id} item={file} remove={this.remove}/>
                                )
                            }
                        </ul>
                        : null}
                    <button type="button" className="btn btn-secondary" onClick={() => {
                        this.setState({
                            modalShown: true
                        });
                    }}>
                        {t("Browse")}
                    </button>
                </div>
                <ModalPortal show={modalShown}>
                    <BrowseFilesModal
                        isSingleSelect={isSingleSelect}
                        initialSelections={
                            isSingleSelect ? (field.value ? [field.value.id] : [])
                                : field.value.map((f: any) => f.id)
                        }
                        onSelection={this.onSelectionChange}
                        onClose={() => {
                            this.setState({
                                modalShown: false
                            });
                        }}/>
                </ModalPortal>
            </>
        );
    }
}

export const AttachmentField = withTranslation()(AttachmentFieldComponent);
