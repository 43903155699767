import { EntityWrapper, UserWithRoles } from "@thekeytechnology/framework-react";

export class BookedSession {
    public static STATUS_REQUESTED = "requested";
    public static STATUS_BOOKED = "booked";
    public static STATUS_DECLINED = "declined";

    constructor(public offerId: string,
                public user: EntityWrapper<UserWithRoles>,
                public cost: number,
                public status: string) {
    }
}
