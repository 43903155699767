import {
    addFiltersAction,
    FilterTerm,
    PropertyFilter,
    removeFiltersAction,
    WithEntityList
} from "@thekeytechnology/framework-react";
import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    AboveTableContainer,
    BlueBgContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer
} from "../../../../core/components/containers/Container";
import Pagination from "../../../../core/components/pagination/Pagination";
import PaginationInfo from "../../../../core/components/pagination/PaginationInfo";
import {CourseWithMeta} from "../../../model/course/course-with-meta";
import {EnrollFilters} from "./filter/EnrollFilters";
import {MyCourseGroups} from "./course-groups/MyCourseGroups";
import {EnrollTable} from "./table/EnrollTable";

import "./course-overview.scss";

interface DispatchProps {
    addFilters: ReturnType<typeof addFiltersAction>
    removeFilters: ReturnType<typeof removeFiltersAction>
}

type Props = DispatchProps & WithTranslation;

class EnrollComponent extends React.Component<Props> {

    public componentDidMount(): void {
        this.props.addFilters("enroll-base-filters", [new PropertyFilter("entity.dateTime",
            new FilterTerm(FilterTerm.TYPE_DATE_TIME, FilterTerm.OPERATION_GTE, new Date().toISOString())
        )]);
    }

    public componentWillUnmount(): void {
        this.props.removeFilters(["enroll-base-filters"]);
    }

    public render() {
        const {t} = this.props;

        const EnrollTableChild = WithEntityList<{}, CourseWithMeta>(EnrollTable, CourseWithMeta.TYPE, "enroll");

        return (
            <>
                <BreadcrumbsItem to="/enroll">{t("enroll.heading")}</BreadcrumbsItem>

                <HeadingContainer>
                    <h1>{t("enroll.heading")}</h1>
                </HeadingContainer>

                <MyCourseGroups/>

                <BlueBgContainer>
                    <EnrollFilters/>
                </BlueBgContainer>
                <ContentContainer>
                    <AboveTableContainer>
                        <PaginationInfo entityType={CourseWithMeta.TYPE} className="mr-auto"/>
                    </AboveTableContainer>
                    <EnrollTableChild/>
                </ContentContainer>
                <PaginationContainer>
                    <Pagination entityType={CourseWithMeta.TYPE}/>
                </PaginationContainer>
            </>
        );
    }
}

export const Enroll = connect<{}, DispatchProps, {}>(
    null,
    {
        addFilters: addFiltersAction(CourseWithMeta.TYPE),
        removeFilters: removeFiltersAction(CourseWithMeta.TYPE)
    }
)(withTranslation("courses")(EnrollComponent));
