import { FieldProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Select  from "react-select";
import { ClearIndicator } from "../../entity/components/ClearIndicator";

interface LanguageSelectFieldProps extends WithTranslation {
    availableLanguages: string[];
}

const LanguageSelectFieldComponent = (props: LanguageSelectFieldProps & FieldProps) => {
    const {availableLanguages, field, form, t} = props;

    const languageOptions = availableLanguages.map((language: string) => {
        return {
            value: language,
            label: t(language)
        };
    });

    return (
        <>
            <Select
                className="react-select category-select"
                classNamePrefix="react-select"
                components={{ClearIndicator}}
                options={languageOptions}
                name={field.name}
                value={languageOptions.find(o => o.value === field.value)}
                onChange={(item: any) => form.setFieldValue(field.name, item.value)}
                getOptionValue={opt => opt.value}
                getOptionLabel={item => item.label}
                onBlur={field.onBlur}
            />
        </>
    );
};

export const LanguageSelectField = withTranslation()(LanguageSelectFieldComponent);
