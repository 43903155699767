import {
    ApiCallSucceededAction,
    matchesAnyOfTheseApiSuccesses,
    matchesApiSuccess
} from "@thekeytechnology/framework-react";
import {
    API_CREATE_ENTITIES,
    API_UPDATE_ENTITIES,
    EntitiesCreatedPayload
} from "@thekeytechnology/framework-react";
import { push } from "connected-react-router";
import { Action } from "redux";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { API_SEND_MAIL } from "../actions/send-email";
import { CourseCategory } from "../model/course-category";
import { CourseDefinition } from "../model/course-definition/course-definition.model";
import { CourseGroupWithMeta } from "../model/course-group/course-group-with-meta";
import { CourseWithMeta } from "../model/course/course-with-meta";
import { ScheduledEmail } from "../model/scheduled-email/scheduled-email";
import { TrainerData } from "../model/trainer/trainer-data";

const redirectAfterCourseWrite$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesAnyOfTheseApiSuccesses({
            apiType: API_CREATE_ENTITIES, callType: CourseWithMeta.TYPE
        }, {
            apiType: API_UPDATE_ENTITIES, callType: CourseWithMeta.TYPE
        }), map(() => push("/courses"))
    );

const redirectAfterCourseDefinitionWrite$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesAnyOfTheseApiSuccesses({
            apiType: API_CREATE_ENTITIES, callType: CourseDefinition.TYPE
        }, {
            apiType: API_UPDATE_ENTITIES, callType: CourseDefinition.TYPE
        }), map(() => push("/course-definitions"))
    );

const redirectAfterCourseCategoryWrite$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesAnyOfTheseApiSuccesses({
            apiType: API_CREATE_ENTITIES, callType: CourseCategory.TYPE
        }, {
            apiType: API_UPDATE_ENTITIES, callType: CourseCategory.TYPE
        }),
        map(() => push("/course-categories"))
    );

const redirectAfterCourseGroupWrite$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesAnyOfTheseApiSuccesses({
            apiType: API_CREATE_ENTITIES, callType: CourseGroupWithMeta.TYPE
        }, {
            apiType: API_UPDATE_ENTITIES, callType: CourseGroupWithMeta.TYPE
        }),
        map(() => push("/course-groups"))
    );

const redirectAfterEmailSent$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_SEND_MAIL),
        map(() => push("/courses"))
    );

const redirectAfterScheduledEmailWrite$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesAnyOfTheseApiSuccesses({
            apiType: API_CREATE_ENTITIES, callType: ScheduledEmail.TYPE
        }, {
            apiType: API_UPDATE_ENTITIES, callType: ScheduledEmail.TYPE
        }), map((action: ApiCallSucceededAction<EntitiesCreatedPayload>) => push("/course-definitions/" +
            action.payload.relatedEntityId + "/scheduled-emails"))
    );

const redirectAfterTrainerUpdate$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_UPDATE_ENTITIES, TrainerData.TYPE),
        map(() => push("/trainers"))
    );

export const redirectEpics$ = combineEpics(
    redirectAfterCourseWrite$,
    redirectAfterCourseDefinitionWrite$,
    redirectAfterCourseCategoryWrite$,
    redirectAfterCourseGroupWrite$,
    redirectAfterScheduledEmailWrite$,
    redirectAfterEmailSent$,
    redirectAfterTrainerUpdate$,
);
