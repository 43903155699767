import { EntityWrapper } from "@thekeytechnology/framework-react";
import { TkFile } from "@thekeytechnology/framework-react";
import { FixedDatesSchedule } from "../schedule/fixed-dates-schedule";
import { Schedule } from "../schedule/schedule";
import { CourseCategory } from "../course-category";
import { TrainerData } from "../trainer/trainer-data";

export class CourseDefinition {
    public static TYPE = "course-definition";

    public static new() {
        return new EntityWrapper(undefined, new CourseDefinition(
            "",
            "",
            1,
            60,
            new FixedDatesSchedule([]),
            undefined,
            undefined,
            0,
            undefined,
            []
        ));
    }

    constructor(public name: string,
                public description: string,
                public capacity: number,
                public durationInMinutes: number,
                public schedule: Schedule,
                public courseCategory: EntityWrapper<CourseCategory> | undefined,
                public trainer: EntityWrapper<TrainerData> | undefined,
                public cost: number,
                public accountingType: string | undefined,
                public attachments: Array<EntityWrapper<TkFile>>) {
    }

}
