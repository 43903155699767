import {faGraduationCap} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    EntityWrapper,
    reloadEntityListAction,
    selectEntities,
    setPaginationQueryAction
} from "@thekeytechnology/framework-react";
import {FieldProps} from "formik";
import React, {Component} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import Select from "react-select";
import {ClearIndicator} from "../../../../entity/components/ClearIndicator";
import {connect} from "react-redux";
import {TrainerData} from "../../../model/trainer/trainer-data";

interface StateProps {
    trainers: Array<EntityWrapper<TrainerData>>;
}

interface DispatchProps {
    fetchTrainers: ReturnType<typeof reloadEntityListAction>
    setPaginationQuery: ReturnType<typeof setPaginationQueryAction>
}

type OwnProps = FieldProps & WithTranslation;

type Props = StateProps & OwnProps & DispatchProps;

class TrainerSelectField extends Component<Props> {

    public constructor(props: Props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    public componentDidMount(): void {
        this.props.setPaginationQuery({page: 0, docsPerPage: -1});
        this.props.fetchTrainers();
    }

    public onChange(trainer: EntityWrapper<TrainerData>) {
        this.props.form.setFieldValue(this.props.field.name, trainer);
    }

    public render() {
        const {trainers, field, t} = this.props;
        return (
            <div className="input-field-wrapper">
                <FontAwesomeIcon className="input-icon" icon={faGraduationCap}/>
                <Select
                    className="react-select trainer-select"
                    classNamePrefix="react-select"
                    placeholder={t("Select trainer")}
                    components={{ClearIndicator}}
                    isClearable={true}
                    options={trainers}
                    name={field.name}
                    value={trainers ?
                        trainers.find((option: any) => field.value && (field.value.id === option.id)) : null}
                    onChange={this.onChange as any}
                    getOptionValue={opt => opt.id as any}
                    getOptionLabel={(item: EntityWrapper<TrainerData>) => item.entity.name}
                    onBlur={field.onBlur}
                />
            </div>
        );
    }
}

export default connect<StateProps, DispatchProps, OwnProps>(
    (state: any) => {
        return {
            trainers: selectEntities<TrainerData>(TrainerData.TYPE)(state)
        };
    },
    {
        fetchTrainers: reloadEntityListAction(TrainerData.TYPE),
        setPaginationQuery: setPaginationQueryAction(TrainerData.TYPE)
    }
)(withTranslation("courses")(TrainerSelectField));
