import { UserWithRoles } from "@thekeytechnology/framework-react";
import React from "react";
import { ModalPortal } from "../core/components/modal/ModalPortal";
import InviteUsersModal from "./components/overview/invite/InviteUsersModal";
import { EditUser } from "./components/single/EditUser";
import { UsersInAccountOverview } from "./components/overview/UsersInAccountOverview";
import { EditTeam } from "./components/teams/single/EditTeam";
import { TeamsOverview } from "./components/teams/TeamsOverview";
import {PrivateRoute} from "../core/components/navigation/PrivateRoute";

export const UsersModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact requiredRoles={[UserWithRoles.ROLE_ADMIN]} path="/users"
                          component={UsersInAccountOverview}/>

            <PrivateRoute exact requiredRoles={[UserWithRoles.ROLE_ADMIN]} path="/users/teams"
                          component={TeamsOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/users/teams/:teamId/edit" component={EditTeam}/>
            <PrivateRoute exact requiredRoles={[UserWithRoles.ROLE_ADMIN]} path="/users/:userId/roles"
                          component={EditUser}/>

            <PrivateRoute exact requiredRoles={[UserWithRoles.ROLE_ADMIN]} path="/users/invite"
                          component={(props: any) => (
                              <>
                                  <UsersInAccountOverview {...props}/>
                                  <ModalPortal show={true}>
                                      <InviteUsersModal {...props} />
                                  </ModalPortal>,
                              </>
                          )}
            />
        </>
    );
};
