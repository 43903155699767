import { addFiltersAction, selectFilter } from "@thekeytechnology/framework-react";
import {
    Filter,
    FilterTerm,
    PropertyFilter,
} from "@thekeytechnology/framework-react";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ClearIndicator } from "../../../../../entity/components/ClearIndicator";
import { connect } from "react-redux";
import Select from "react-select";
import { CourseWithMeta } from "../../../../model/course/course-with-meta";

interface StateProps {
    currentFilters?: Filter[];
}

interface DispatchProps {
    addFilters: ReturnType<typeof addFiltersAction>
}

type Props = StateProps & DispatchProps & WithTranslation;

class StatusFilter extends Component<Props> {

    public constructor(props: Props) {
        super(props);
        this.filter = this.filter.bind(this);
    }

    public render() {
        const {t} = this.props;

        return (
            <Select
                components={{ClearIndicator}}
                placeholder={t("Filter by status")}
                className="filter react-select"
                classNamePrefix="react-select"
                isClearable={true}
                onChange={this.filter}
                options={this.getOptions()}
                value={this.determineStatusSelectionFromFilter()}
            />
        );
    }

    public determineStatusSelectionFromFilter() {
        const {currentFilters} = this.props;
        if (!currentFilters) {
            return null;
        }

        const options = this.getOptions();

        const firstFilter = currentFilters[0] as PropertyFilter;
        if (firstFilter.property === "entity.isCancelled") {
            return options[2];
        }
        if (firstFilter.property === "entity.status") {
            return options[3];
        }
        if (currentFilters.length === 2) {
            return options[1];
        }
        if (currentFilters.length === 3) {
            return options[0];
        }
        return null;
    }

    public filter(selection: any) {
        const {addFilters} = this.props;

        if (selection === null) {
            addFilters("course-status", []);
        } else {
            const now = new Date().toISOString();
            switch (selection.value) {
                case "cancelled":
                    addFilters("course-status", [
                        new PropertyFilter(
                            "entity.isCancelled",
                            new FilterTerm(FilterTerm.TYPE_BOOLEAN, FilterTerm.OPERATION_EQ, true)
                        ),
                    ]);
                    break;
                case "finalized":
                    addFilters("course-status", [
                        new PropertyFilter(
                            "entity.status",
                            new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, "finalized")
                        ),
                    ]);
                    break;
                case "not-finalized":
                    addFilters("course-status", [
                        new PropertyFilter(
                            "entity.dateTime",
                            new FilterTerm(FilterTerm.TYPE_DATE_TIME, FilterTerm.OPERATION_LT, now)
                        ),
                        new PropertyFilter(
                            "entity.status",
                            new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, "not-finalized")
                        ),
                    ]);
                    break;
                case "future":
                    addFilters("course-status", [
                        new PropertyFilter(
                            "entity.dateTime",
                            new FilterTerm(FilterTerm.TYPE_DATE_TIME, FilterTerm.OPERATION_GTE, now)
                        ),
                        new PropertyFilter(
                            "entity.status",
                            new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, "not-finalized")
                        ),
                        new PropertyFilter(
                            "entity.isCancelled",
                            new FilterTerm(FilterTerm.TYPE_BOOLEAN, FilterTerm.OPERATION_EQ, false)
                        ),
                    ]);
                    break;
            }
        }
    }

    private getOptions() {
        const {t} = this.props;

        return [
            {
                value: "future",
                label: t("status.future")
            },
            {
                value: "not-finalized",
                label: t("status.not-finalized")
            },
            {
                value: "cancelled",
                label: t("status.cancelled")
            },
            {
                value: "finalized",
                label: t("status.finalized")
            }
        ];
    }
}

export default withTranslation("courses")(connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            currentFilters: selectFilter(CourseWithMeta.TYPE, "course-status")(state)
        };
    },
    {
        addFilters: addFiltersAction(CourseWithMeta.TYPE)
    }
)(StatusFilter));
