import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Filters } from "../../../../../core/components/filters/Filters";
import TextFilter from "../../../../../entity/components/TextFilter";
import { CourseGroupWithMeta } from "../../../../model/course-group/course-group-with-meta";

const CourseGroupFiltersComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <Filters
            entityType={CourseGroupWithMeta.TYPE}
            filterKeys={["entity.name"]}>
            <TextFilter
                placeholder={t("Filter by name")}
                entityType={CourseGroupWithMeta.TYPE}
                property="entity.name"/>
        </Filters>
    );
};

export const CourseGroupFilters = withTranslation("courses")(CourseGroupFiltersComponent);
