import { ApiCallAction, apiCallFailedAction, ApiQualifier } from "@thekeytechnology/framework-react";
import { API_LOGIN, googleLoginAttemptAction } from "@thekeytechnology/framework-react";
import React from "react";
import GoogleLogin from "react-google-login";
import { withTranslation } from "react-i18next";
import { Action } from "redux";
import { connect } from "react-redux";
import GoogleIcon from "./google-icon.png";

import "./google-login-button.scss";

interface GoogleLoginButtonProps {
    googleLogin: (token: string) => ApiCallAction<string>
    loginFailed: (apiQualifier: ApiQualifier, message: string) => Action,
    t: (key: string) => string;
}

const GoogleLoginButtonComponent = (props: GoogleLoginButtonProps) => {
    const {googleLogin, loginFailed, t} = props;
    return (
        <GoogleLogin
            clientId="18091215763-1a48er3stlh3hpsmff67g6v7lplnlske.apps.googleusercontent.com"
            render={renderProps => (
                <button type="button"
                        className="google-login-button"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}>
                    <img alt="Google" src={GoogleIcon}/>
                    <span>{t("Sign in with Google")}</span>
                </button>
            )}
            responseType="id_token"
            buttonText="Login"
            onSuccess={response => {
                // @ts-ignore
                googleLogin(response.getAuthResponse().id_token);
            }}
            onFailure={() => loginFailed({apiType: API_LOGIN, callType: "google"}, "google authentication failed")}
            cookiePolicy={"single_host_origin"}
        />
    );
};

export const GoogleLoginButton = withTranslation()(connect(
    null,
    {
        googleLogin: googleLoginAttemptAction,
        loginFailed: apiCallFailedAction
    }
)(GoogleLoginButtonComponent));
