import { faEllipsisV, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    reloadEntityListAction,
    selectEntities,
    setPaginationQueryAction
} from "@thekeytechnology/framework-react";
import { EntityWrapper} from "@thekeytechnology/framework-react";
import { FieldProps } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import { ClearIndicator } from "../../../../entity/components/ClearIndicator";
import { connect } from "react-redux";
import { CourseDefinition } from "../../../model/course-definition/course-definition.model";
import Select from "react-select";
import { CourseDefinitionForGroupDto } from "../../../model/course-group/course-definition-for-group";

import "./course-definition-select-field.scss";

interface CourseDefinitionListSelectFieldState {
    toAdd?: EntityWrapper<CourseDefinition>
}

interface StateProps {
    courseDefinitions: Array<EntityWrapper<CourseDefinition>>;
}

interface DispatchProps {
    setPaginationQuery: ReturnType<typeof setPaginationQueryAction>
    fetchDefinitions: ReturnType<typeof reloadEntityListAction>
}

type Props = StateProps & DispatchProps & WithTranslation & FieldProps;

const SortableItem = SortableElement(
    ({onRemove, value}: any) => <li className="list-group-item d-flex align-items-center">
        <FontAwesomeIcon
            className="mr-2 dnd-handle"
            icon={faEllipsisV}/>
        <span>{value.entity.name}</span>
        <a href="/" className="ml-auto" onClick={e => {
            onRemove(value)
            e.preventDefault();
            return false;
        }}>
            <FontAwesomeIcon className="skip-dnd" icon={faTimes}/>
        </a>
    </li>
);

const SortableList = SortableContainer(({items, onRemove}: any) => {
    return (
        <ul className="list-group">
            {items.map((value: any, index: any) => (
                <SortableItem key={`item-${value.id}`} index={index} value={value} onRemove={onRemove}/>
            ))}
        </ul>
    );
});

class CourseDefinitionListSelectField extends Component<Props, CourseDefinitionListSelectFieldState> {

    public constructor(props: Props) {
        super(props);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.resolveSelectedDefinitions = this.resolveSelectedDefinitions.bind(this);
        this.setFieldValue = this.setFieldValue.bind(this);

        this.state = {toAdd: undefined};
    }

    public componentDidMount(): void {
        this.props.setPaginationQuery({page: 0, docsPerPage: -1});
        this.props.fetchDefinitions();
    }

    public onSortEnd(change: any) {
        const selectedCourseDefinitions = this.resolveSelectedDefinitions();
        const reorderedCourseDefinitions = arrayMove<EntityWrapper<CourseDefinition>>(selectedCourseDefinitions, change.oldIndex, change.newIndex);
        this.setFieldValue(reorderedCourseDefinitions);
    }

    public resolveSelectedDefinitions() {
        const {courseDefinitions, field} = this.props;
        const selectedCourseDefinitionsForGroup = field.value ? field.value : [];
        const selectedIds = selectedCourseDefinitionsForGroup.map((cd: any) => cd.id);
        return selectedIds.map((id: any) => courseDefinitions.find(cd => cd.id === id)).filter((cd: any) => cd !== undefined);
    }

    public setFieldValue(courseDefinitions: Array<EntityWrapper<CourseDefinition>>) {
        const {field, form} = this.props;

        form.setFieldValue(field.name,
            [
                ...courseDefinitions.map(cd => new CourseDefinitionForGroupDto(cd.id as string, cd.entity.name))
            ]
        );
    }

    public render() {
        const {courseDefinitions, t} = this.props;
        const {toAdd} = this.state;

        const selectedCourseDefinitions = this.resolveSelectedDefinitions();
        const selectedIds = selectedCourseDefinitions.map((cd: any) => cd.id);
        const notSelectedCourseDefinitions = courseDefinitions.filter(cd => selectedIds.indexOf(cd.id) === -1);

        return (
            <div className="course-definition-select-list">
                <SortableList items={selectedCourseDefinitions}
                              shouldCancelStart={(event: any) => {
                                  return (!event.target as any).parentNode.classList.contains("dnd-handle");
                              }}
                              onSortEnd={this.onSortEnd}
                              onRemove={
                                  (item: EntityWrapper<CourseDefinition>) => {
                                      const currentDefinitions = this.resolveSelectedDefinitions();
                                      this.setFieldValue([
                                          ...currentDefinitions.filter((cd: any) => cd.id !== item.id)
                                      ]);
                                  }}/>

                <div className="add-form mt-2 d-flex">
                    <Select
                        className="react-select category-select mr-2"
                        classNamePrefix="react-select"
                        placeholder={t("Select course definition")}
                        components={{ClearIndicator}}
                        isClearable={true}
                        options={notSelectedCourseDefinitions}
                        value={courseDefinitions && toAdd ?
                            courseDefinitions.find(option => toAdd && (toAdd.id === option.id)) : null}
                        onChange={item => this.setState({toAdd: item as any})}
                        getOptionValue={opt => opt.id as any}
                        getOptionLabel={(item: EntityWrapper<CourseDefinition>) => item.entity.name}
                    />
                    <button type="button" className="btn btn-secondary" disabled={toAdd === null} onClick={() => {
                        const currentDefinitions = this.resolveSelectedDefinitions();
                        this.setFieldValue([
                            ...currentDefinitions,
                            toAdd
                        ]);
                        this.setState({toAdd: undefined});
                    }}>{t("Add")}
                    </button>
                </div>
            </div>
        );
    }
}

export default SortableContainer(connect<StateProps, DispatchProps, FieldProps>(
    (state: any) => {
        return {
            courseDefinitions: selectEntities<CourseDefinition>(CourseDefinition.TYPE)(state)
        };
    },
    {
        setPaginationQuery: setPaginationQueryAction(CourseDefinition.TYPE),
        fetchDefinitions: reloadEntityListAction(CourseDefinition.TYPE)
    }
)(withTranslation("courses")(CourseDefinitionListSelectField)));
