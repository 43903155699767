import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { ApiCallAction, ApiCallState, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { API_FORGOT_PASSWORD, forgotPasswordAction } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ValidatedField } from "../../../core/components/form/ValidatedField";
import * as Yup from "yup";

import { BaseAccountScreen } from "../common/BaseAccountScreen";

interface ForgotPasswordComponentProps {
    t: (key: string) => string;
    apiCallState: ApiCallState;
    forgotPassword: (email: string) => ApiCallAction<string>;
}

class ForgotPasswordComponent extends React.Component<ForgotPasswordComponentProps> {
    public render() {
        const {t, apiCallState, forgotPassword} = this.props;

        return (
            <BaseAccountScreen>
                <h1 className="text-white text-center mb-5">{t("Forgot your password?")}</h1>

                {apiCallState.succeeded ?
                    <div className="paragraph text-center">If that email is associated with an account, you should
                        receive an email with password resetting instructions
                    </div> : <Formik
                        initialValues={{
                            email: "",
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email().required(),
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            forgotPassword(values.email);
                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting, errors, touched}) => (
                            <Form>
                                <div className="form-group">
                                    <ValidatedField errors={errors} touched={touched} type="email" icon={faEnvelope}
                                                    placeholder={t("E-Mail")}
                                                    name="email"
                                                    className="form-control default-input" required/>
                                </div>
                                <button disabled={isSubmitting || apiCallState.inProgress}
                                        type="submit"
                                        className="btn btn-primary w-100">
                                    Request reset email
                                </button>
                            </Form>
                        )}
                    </Formik>

                }
            </BaseAccountScreen>
        );
    }
}

export const ForgotPassword = withTranslation()(connect(
    (state: any) => ({
        apiCallState: selectCombinedApiState(API_FORGOT_PASSWORD)(state)
    }),
    {forgotPassword: forgotPasswordAction}
)(ForgotPasswordComponent));
