import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_DOWNLOAD_LOG = "download-log";

export const downloadLogAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_LOG,
            callType: API_DOWNLOAD_LOG
        },
        payload: null
    } as ApiCallAction<null>;
};
