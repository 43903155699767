import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { DailySchedule } from "../../../../model/schedule/daily-schedule";
import { FixedDatesSchedule } from "../../../../model/schedule/fixed-dates-schedule";
import { MonthlySchedule } from "../../../../model/schedule/monthly-schedule";
import { Schedule } from "../../../../model/schedule/schedule";
import { WeeklySchedule } from "../../../../model/schedule/weekly-schedule";
import { YearlySchedule } from "../../../../model/schedule/yearly-schedule";
import moment from "moment";

interface ScheduleDisplayProps extends WithTranslation {
    schedule: Schedule;
}

const ScheduleDisplayComponent = (props: ScheduleDisplayProps) => {
    const {schedule, t} = props;
    if (schedule.scheduleType === FixedDatesSchedule.TYPE) {
        const setSchedule = schedule as FixedDatesSchedule;
        return <>{t("Fixed schedule at")} {setSchedule.dateTimes.length} {t("dates")}</>;
    } else if (schedule.scheduleType === DailySchedule.TYPE) {
        const dailySchedule = schedule as DailySchedule;
        const start = moment(dailySchedule.start).format("DD.MM.YYYY HH:mm A");
        return <>{t("Recurring every")} {dailySchedule.every} {t("day(s)")}, {t("starting from")} {start}</>;
    } else if (schedule.scheduleType === WeeklySchedule.TYPE) {
        const weeklySchedule = schedule as WeeklySchedule;
        const weekString = weeklySchedule.daysOfWeek.map(day => t(day)).reduce((x, y) => x + ", " + y);
        const start = moment(weeklySchedule.start).format("DD.MM.YYYY HH:mm A");
        return <>{t("Recurring every")} {weeklySchedule.every} {t("weeks(s)")} ({weekString}), {t("starting from")} {start}</>;
    } else if (schedule.scheduleType === MonthlySchedule.TYPE) {
        const monthly = schedule as MonthlySchedule;
        const start = moment(monthly.start).format("DD.MM.YYYY HH:mm A");
        return <>{t("Recurring every")} {monthly.every} {t("month(s)")}, {t("starting from")} {start}</>;
    } else if (schedule.scheduleType === YearlySchedule.TYPE) {
        const yearly = schedule as YearlySchedule;
        const start = moment(yearly.start).format("DD.MM.YYYY HH:mm A");
        return <>{t("Recurring every")} {yearly.every} {t("year(s)")}, {t("starting from")} ${start}</>;
    } else {
        return null;
    }
};

export const ScheduleDisplay = withTranslation("courses")(ScheduleDisplayComponent);
