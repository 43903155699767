import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_SET_USER_BUDGETS = "set-user-budget";

export interface SetUserBudgetsPayload {
    userIds: string[];
    budget: number;
    proRateRatio: number;
}

export const setUserBudgetsAction = (userIds: string[], budget: number, proRateRatio: number) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SET_USER_BUDGETS,
            callType: API_SET_USER_BUDGETS
        },
        payload: {
            userIds,
            budget,
            proRateRatio
        }
    } as ApiCallAction<SetUserBudgetsPayload>;
};
