import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    ApiCallState,
    DialogResult,
    DialogSettings, selectCombinedApiState,
    showDialogAction
} from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import { API_RESET_BUDGETS, resetBudgetsAction } from "../actions/reset-budget";
import { ShowOnlyWhenBudgetEnabled } from "./ShowOnlyWhenBudgetEnabled";

interface StateProps {
    apiCallState: ApiCallState;
}

interface DispatchProps {
    showDialog: (dialogSettings: DialogSettings,
                 callback: (result: DialogResult) => void) => void;
    resetBudgets: () => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

const BudgetResetButtonComponent = (props: Props) => {
    const {t, showDialog, resetBudgets, apiCallState} = props;

    return (<ShowOnlyWhenBudgetEnabled>
        <>
            <h3 className="mb-3 mt-5">{t("budget-reset-button.heading")}</h3>
            <button onClick={() => {
                showDialog({
                    title: t("budget-reset-button.dialog.title"),
                    question: t("budget-reset-button.dialog.question"),
                    explanation: t("budget-reset-button.dialog.explanation"),
                    affirmativeText: t("budget-reset-button.dialog.affirmative"),
                    negativeText: t("budget-reset-button.dialog.negative"),
                    affirmativeButtonClass: "btn btn-danger"
                }, (r: DialogResult) => {
                    if (r === DialogResult.ACCEPT) {
                        resetBudgets();
                    }
                });
            }} className="btn btn-outline-success">
                {apiCallState.succeeded ? <FontAwesomeIcon icon={faCheck}/> : t("budget-reset-button.reset")}
            </button>
        </>
    </ShowOnlyWhenBudgetEnabled>);
};

export const BudgetResetButton = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        apiCallState: selectCombinedApiState(API_RESET_BUDGETS)(state)
    }),
    (dispatch: Dispatch<Action>) => {
        // noinspection JSUnusedGlobalSymbols
        return {
            showDialog: (dialogSettings: DialogSettings,
                         callback: (result: DialogResult) => void) =>
                dispatch(showDialogAction(dialogSettings, callback)),
            resetBudgets: () => dispatch(resetBudgetsAction())
        };
    }
)(withTranslation(["budget", "translation"])(BudgetResetButtonComponent));
