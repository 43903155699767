import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const ClearIndicator = (props: any)  => {
    const {
        children = <FontAwesomeIcon icon={faTimes}/>,
        getStyles,
        innerProps: {ref, ...restInnerProps},
    } = props;
    return (
        <div className="react-select__clear-indicator"
             {...restInnerProps}
             ref={ref}
             style={getStyles("clearIndicator", props)}
        >
            {children}
        </div>
    );
};
