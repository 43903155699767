import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { removeFiltersAction } from "@thekeytechnology/framework-react";
import { TkFile } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./file-filters.scss";
import TextFilter from "../../../../entity/components/TextFilter";

interface DispatchProps {
    removeFilters: ReturnType<typeof removeFiltersAction>
}

type Props = DispatchProps & WithTranslation;

class FileFiltersComponent extends React.Component<Props> {

    public constructor(props: Props) {
        super(props);
        this.clearFilters = this.clearFilters.bind(this);
    }

    public render() {
        const {t} = this.props;
        return (
            <div className="filters file-filters">
                <FontAwesomeIcon className="filter-icon" icon={faFilter}/>
                <TextFilter
                    placeholder={t("Filter by name")}
                    entityType={TkFile.TYPE}
                    property="entity.name"/>
                <button type="button" onClick={this.clearFilters} className="clear-filters">
                    <FontAwesomeIcon icon={faTimes}/>{t("translation:Clear filters")}
                </button>
            </div>
        );
    }

    public clearFilters() {
        this.props.removeFilters(
            ["entity.name"]
        );
    }
}

export const FileFilters = connect<{}, DispatchProps, {}>(
    null,
    {
        removeFilters: removeFiltersAction(TkFile.TYPE)
    }
)(withTranslation("files")(FileFiltersComponent));
