import { EntityWrapper } from "@thekeytechnology/framework-react";
import { ScheduleRelation } from "./schedule-relation";

export class ScheduledEmail {
    public static TYPE = "scheduled-email-definition";

    public static new(courseDefinitionRef: string) {
        return new EntityWrapper(undefined, new ScheduledEmail(
            courseDefinitionRef,
            {},
            {},
            "admin",
            new ScheduleRelation("after", 1)
        ));
    }

    constructor(public courseDefinitionRef: string,
                public subjects: { [lang: string]: string },
                public bodies: { [lang: string]: string },
                public recipientMode: string,
                public scheduleRelation: ScheduleRelation) {
    }
}
