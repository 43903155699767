import React from "react";
import { Menu } from "../../_modules/menu/components/menu/Menu";

import "./sidebar.scss";

export const Sidebar = () => (
    <div
        className={"container sidebar col-12 col-lg-3 col-xl-2 col h-100 p-0 gradient-bg shadow"}>
        <Menu/>
    </div>
);
