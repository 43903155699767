import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DialogResult, EntityWrapper, generateString, showDialogAction} from "@thekeytechnology/framework-react";
import {WithTranslation, withTranslation} from "react-i18next";
import React from "react";
import {connect} from "react-redux";
import {acceptDeclineCoachingSessionAction} from "../../../../actions/accept-decline-session";
import {UncontrolledTooltip} from "reactstrap";
import {BookedSession} from "../../../../model/booked-session";

interface OwnProps {
    bookedSession: EntityWrapper<BookedSession>;
}

interface DispatchProps {
    acceptDeclineSession: typeof acceptDeclineCoachingSessionAction;
    showDialog: typeof showDialogAction;
}

type Props = DispatchProps & OwnProps & WithTranslation;

const AcceptBookedSessionButtonComponent = (props: Props) => {
    const {bookedSession, showDialog, acceptDeclineSession, t} = props;
    const id = "accept-" + generateString(10);
    return (
        <>
            <a href="/" className="action" id={id} onClick={e => {
                showDialog({
                        title: t("booked-sessions.status-change.accept.dialog.heading"),
                        question: t("booked-sessions.status-change.accept.dialog.question"),
                        explanation: t("booked-sessions.status-change.accept.dialog.explanation"),
                        affirmativeText: t("booked-sessions.status-change.accept.dialog.affirmative"),
                        negativeText: t("booked-sessions.status-change.accept.dialog.cancel"),
                    },
                    (r: DialogResult) => {
                        if (r === DialogResult.ACCEPT) {
                            acceptDeclineSession(bookedSession.id as string, BookedSession.STATUS_BOOKED);
                        }
                    });
                e.preventDefault();
                return false;
            }}>
                <FontAwesomeIcon icon={faCheck}/>
                <div className="lg-and-above-hide">{t("booked-sessions.status-change.accept.tooltip")}</div>
            </a>
            <UncontrolledTooltip placement="top" target={id}>
                {t("booked-sessions.status-change.accept.tooltip")}
            </UncontrolledTooltip>
        </>
    );
};

export const AcceptBookedSessionButton = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        showDialog: showDialogAction,
        acceptDeclineSession: acceptDeclineCoachingSessionAction
    }
)(withTranslation("coaching")(AcceptBookedSessionButtonComponent));
