import { faCalendarAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    addFiltersAction,
    removeFiltersAction,
    selectFilter
} from "@thekeytechnology/framework-react";
import { Filter, FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import React from "react";
import { connect } from "react-redux";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";

interface OwnProps {
    entityType: string;
    property: string;
}

interface StateProps {
    currentFilters?: Filter[];
}

interface DispatchProps {
    addFilters: ReturnType<typeof addFiltersAction>
    removeFilters: ReturnType<typeof removeFiltersAction>
}

type Props = OwnProps & StateProps & DispatchProps;

const DateTimeRangeFilter = (props: Props) => {
    const filter = (range: any) => {
        if (range == null) {
            props.removeFilters([props.property]);
        } else {
            props.addFilters(props.property, [new PropertyFilter(props.property,
                new FilterTerm(FilterTerm.TYPE_DATE_TIME_RANGE, FilterTerm.OPERATION_RANGE, range)
            )]);
        }
    };

    const currentFilters = props.currentFilters && props.currentFilters.length ?
        (props.currentFilters[0] as PropertyFilter).filterTerm.value
        : null;

    return (
        <DateTimeRangePicker
            className="filter date-range-filter"
            onChange={filter}
            showLeadingZeros={false}
            clearIcon={<FontAwesomeIcon icon={faTimes}/>}
            calendarIcon={<FontAwesomeIcon icon={faCalendarAlt}/>}
            value={currentFilters}/>
    );
};

export default connect<StateProps, DispatchProps, OwnProps>(
    (state: any, ownProps: OwnProps) => {
        return {
            currentFilters: selectFilter(ownProps.entityType, ownProps.property)(state)
        };
    },
    (dispatch, ownProps: OwnProps) => {
        return {
            addFilters: (key: string, filters: Filter[]) =>
                dispatch(addFiltersAction(ownProps.entityType)(key, filters)),
            removeFilters: (keys: string[]) =>
                dispatch(removeFiltersAction(ownProps.entityType)(keys))
        } as DispatchProps;
    }
)(DateTimeRangeFilter);
