import React, {Suspense} from "react";
import {composeWithDevTools} from "redux-devtools-extension";
import {createEpicMiddleware} from "redux-observable";
import {applyMiddleware, createStore} from "redux";
import {Provider} from "react-redux";
import {ConnectedRouter, routerMiddleware} from "connected-react-router";
import {AccountActivation} from "./_modules/auth/components/activation/AccountActivation";
import {ForgotPassword} from "./_modules/auth/components/forgot-password/ForgotPasswordScreen";
import {ResetPassword} from "./_modules/auth/components/forgot-password/ResetPasswordScreen";
import {InvitationScreen} from "./_modules/auth/components/invitation/InvitationScreen";
import Login from "./_modules/auth/components/login/LoginScreen";
import Registration from "./_modules/auth/components/registration/RegistrationScreen";
import {MessageDisplay} from "./_modules/messages-display/components/MessageDisplay";
import {appEpics} from "./_modules/epics";
import {appReducers} from "./_modules/reducers";
import {loadState, saveState} from "./localStorage";
import * as Sentry from "@sentry/browser";
// @ts-ignore
import {ThroughProvider} from "react-through";

import "./_modules/i18n";
import "./assets/scss/index.scss";
import "./_redirects";

import {App} from "./components/App";
import {SentryErrorBoundary} from "./components/sentry-error-boundary";
import {createBrowserHistory} from "history";
import {Route, Switch} from "react-router";
import {render} from "react-dom";
import {throttle} from "lodash";
import {OnlyNonLoggedInRoute} from "./_modules/core/components/navigation/OnlyNonLoggedInRoute";
import {PrivateRoute} from "./_modules/core/components/navigation/PrivateRoute";

const isProduction = process.env.REACT_APP_APP_ENVIRONMENT === "prod";

export const history = createBrowserHistory();
const epicMiddleware = createEpicMiddleware();

const persistedState = loadState();

const middlewares = applyMiddleware(
    routerMiddleware(history), // for dispatching history actions
    epicMiddleware,
);

const storeEnhancers = isProduction ? middlewares : composeWithDevTools({
    trace: true,
})(middlewares);

const store = createStore(
    appReducers(history, process.env.REACT_APP_API_BASE!), // root reducer with router state
    persistedState,
    storeEnhancers,
);

store.subscribe(throttle(() => {
    saveState({
        auth: {
            authState: store.getState().auth.authState
        },
    });
}, 1000));

epicMiddleware.run(appEpics);

if (isProduction) {
    Sentry.init({dsn: "https://e0fc515931ec4f158b1f33910b208810@sentry.io/1757203"});
}

const rootEl = document.getElementById("root");
render(
    <SentryErrorBoundary>
        <ThroughProvider>
            <Provider store={store}>
                <Suspense fallback="loading">
                    <>
                        <MessageDisplay/>
                        <ConnectedRouter history={history}>
                            <Switch>
                                <Route exact path="/invitation" component={InvitationScreen}/>
                                <OnlyNonLoggedInRoute exact path="/login" component={Login}/>
                                <OnlyNonLoggedInRoute exact path="/register" component={Registration}/>
                                <OnlyNonLoggedInRoute exact path="/activation" component={AccountActivation}/>
                                <OnlyNonLoggedInRoute exact path="/forgot-password" component={ForgotPassword}/>
                                <OnlyNonLoggedInRoute exact path="/reset-password" component={ResetPassword}/>
                                <PrivateRoute component={App}/>
                            </Switch>
                        </ConnectedRouter>
                    </>
                </Suspense>
            </Provider>
        </ThroughProvider>
    </SentryErrorBoundary>,
    rootEl,
);
