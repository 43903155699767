import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { CurrentBudget } from "../../_modules/budget/components/CurrentBudget";
import { Menu } from "../../_modules/menu/components/menu/Menu";
import { BreadcrumbsComponent } from "./breadcrumbs/Breadcrumbs";
import { UserMenu } from "./usermenu/UserMenu";

import "./header.scss";
import {AccountSwitcher} from "../../_modules/core/components/Account/AccountsSwitcher";

interface HeaderState {
    mobileOpen: boolean;
}

export class Header extends Component<{}, HeaderState> {

    public constructor(props: {}, state: HeaderState) {
        super(props, state);
        this.state = {
            mobileOpen: false
        };
    }

    public render() {
        const {mobileOpen} = this.state;

        return (
            <>
                <header className="pl-5 pt-3 pb-3 navbar header">
                    <BreadcrumbsComponent/>
                    <AccountSwitcher/>
                    <CurrentBudget/>
                    <UserMenu/>
                </header>
                <header className="navbar mobile-header">
                    <div className="always-visible p-3 w-100">
                        <div className={"mobile-toggle" + (mobileOpen ? " toggle-open" : "")}>
                            <button type="button" className={"mobile-toggle-button"}
                                    onClick={() => this.setState({mobileOpen: !mobileOpen})}>
                                <FontAwesomeIcon icon={faBars}/>
                            </button>
                        </div>
                        <div className="logo">
                        </div>
                    </div>

                    <div className={"toggleable-area gradient-bg w-100" + (mobileOpen ? " open" : "")}>
                        <AccountSwitcher/>
                        <UserMenu className="p-3"/>
                        <Menu/>
                    </div>
                </header>
            </>
        );
    }
}
