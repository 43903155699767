import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { DateTimeDisplay } from "../../../../../core/components/display/DateTimeDisplay";
import { CourseWithMeta } from "../../../../model/course/course-with-meta";
import { ModalHeader } from "./ModalHeader";

interface EditParticipantsModalHeaderProps {
    course?: EntityWrapper<CourseWithMeta>;
    title: string;
}

export const CourseRelatedModalHeader = (props: EditParticipantsModalHeaderProps) => {
    const {course, title} = props;

    const Lead = course ?
        <>
            {course.entity.name} | <DateTimeDisplay dateTime={course.entity.dateTime}/>
        </>
        : <>&nbsp;</>;

    return (
        <ModalHeader title={title} Lead={Lead} returnPath="/courses"/>
    );
};
