import { combineEpics } from "redux-observable";
import { courseEpics$ } from "./course";
import { emailEpics$ } from "./emails";
import { enrollEpics$ } from "./enroll";
import { myCoursesEpic$ } from "./my-courses";
import { participantEpics$ } from "./participants";
import { redirectEpics$ } from "./redirects";
import { reloadEpics$ } from "./reloads";

export const courseModuleEpics$ = combineEpics(
    redirectEpics$,
    emailEpics$ as any,
    participantEpics$,
    courseEpics$,
    reloadEpics$,
    enrollEpics$,
    myCoursesEpic$
);
