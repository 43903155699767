import {faEnvelope, faHeading, faLock} from "@fortawesome/free-solid-svg-icons";
import {
    API_CHANGE_EMAIL,
    API_CHANGE_NAME,
    API_CHANGE_PASSWORD,
    changeEmailAction,
    changeNameAction,
    changePasswordAction,
    EntityWrapper,
    selectCurrentUser,
    UserWithRoles
} from "@thekeytechnology/framework-react";
import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {ContentContainer, HeadingContainer} from "../../core/components/containers/Container";
import {SingleValueForm} from "../../core/components/form/SingleValueForm";
import {SimpleHeader} from "../../core/components/header/SimpleHeader";
import {GlobalLanguageSwitcher} from "../../core/components/language-switcher/GlobalLanguageSwitcher";
import {connect} from "react-redux";
import * as Yup from "yup";

interface StateProps {
    user?: EntityWrapper<UserWithRoles>,
}

interface DispatchProps {
    changeName: (name: string) => void;
    changeEmail: (email: string) => void;
    changePassword: (password: string) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class ProfileComponent extends React.Component<Props> {
    public render() {
        const {
            user, changeName, changeEmail, changePassword, t
        } = this.props;
        return (
            <>
                <BreadcrumbsItem to={"/profile"}>{t("Profile and user settings")}</BreadcrumbsItem>
                <HeadingContainer>
                    <SimpleHeader heading={t("Profile and user settings")}/>
                </HeadingContainer>

                <ContentContainer>
                    <div className="form-group row">
                        <div className="col-sm-2">
                            <label htmlFor="value" className="col-form-label">{t("User language")}</label>
                        </div>
                        <div className=" col-sm-9">
                            <GlobalLanguageSwitcher/>
                        </div>
                    </div>
                    {user ? <>
                        <SingleValueForm initialValue={user.entity.name}
                                         label={t("translation:Name")} icon={faHeading}
                                         validationSchema={Yup.string().required(t("Name") + " " + t("translation:is a required field"))}
                                         apiQualifier={API_CHANGE_NAME}
                                         onSubmit={changeName}/>
                        <SingleValueForm initialValue={user.entity.email}
                                         label={t("translation:Email")} icon={faEnvelope}
                                         validationSchema={Yup.string().email().required(t("Email") + " " + t("translation:is a required field"))}
                                         apiQualifier={API_CHANGE_EMAIL}
                                         onSubmit={changeEmail}/>
                        <SingleValueForm initialValue={""}
                                         label={t("translation:Password")} icon={faLock}
                                         validationSchema={Yup.string().min(8).required(t("Password") + " " + t("translation:is a required field"))}
                                         apiQualifier={API_CHANGE_PASSWORD}
                                         onSubmit={changePassword}/>
                    </> : null}
                </ContentContainer>
            </>
        );
    }
}

export const Profile = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            user: selectCurrentUser(state),
        };
    },
    {
        changeName: changeNameAction,
        changeEmail: changeEmailAction,
        changePassword: changePasswordAction,
    }
)(withTranslation(["auth", "translation"])(ProfileComponent));
