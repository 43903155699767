import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Filters } from "../../../../../core/components/filters/Filters";
import { EntitySelectFilter } from "../../../../../entity/components/EntitySelectFilter";
import TextFilter from "../../../../../entity/components/TextFilter";
import { CourseCategory } from "../../../../model/course-category";
import { CourseDefinition } from "../../../../model/course-definition/course-definition.model";
import { TrainerData } from "../../../../model/trainer/trainer-data";

const CourseDefinitionFiltersComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <Filters entityType={CourseDefinition.TYPE}
                 filterKeys={["entity.name", "entity.courseCategoryRef", "entity.trainerUserIdRef"]}>
            <TextFilter
                placeholder={t("Filter by name")}
                entityType={CourseDefinition.TYPE}
                property="entity.name"/>
            <EntitySelectFilter
                placeholder={t("Filter by categories")}
                fetchedEntityType={CourseCategory.TYPE}
                listRenderer={(item: EntityWrapper<CourseCategory>) => item.entity.name}
                filteredEntityType={CourseDefinition.TYPE}
                property="entity.categoryRef"
            />
            <EntitySelectFilter
                placeholder={t("Filter by trainer")}
                fetchedEntityType={TrainerData.TYPE}
                listRenderer={(item: EntityWrapper<TrainerData>) => item.entity.name}
                filteredEntityType={CourseDefinition.TYPE}
                property="entity.trainerUserIdRef"
            />
        </Filters>
    );
};

export const CourseDefinitionFilters = withTranslation("courses")(CourseDefinitionFiltersComponent);
