import {EntityWrapper, logoutAction, selectCurrentUser, UserWithRoles} from "@thekeytechnology/framework-react";
import { push } from "connected-react-router";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import AvatarPlaceholder from "./avatar-placeholder.png";
import "./user-menu.scss";

interface UserMenuProps {
    className?: string;
}

interface StateProps {
    user?: EntityWrapper<UserWithRoles>;
}

interface DispatchProps {
    logout: () => void;
    toProfile: () => void;
}

type Props = StateProps & DispatchProps & UserMenuProps & WithTranslation;

interface UserMenuState {
    dropdownOpen: boolean;
}

class UserMenuComponent extends React.Component<Props, UserMenuState> {
    constructor(props: Props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    public toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    public render() {
        const {user, logout, toProfile, className, t} = this.props;
        const {dropdownOpen} = this.state;

        if (!user) {
            return null;
        }

        return (
            <div className={"user-menu " + className}>
                <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle caret>
                        <img src={AvatarPlaceholder} alt="avatar-placeholder"/>
                        <div className="name-wrapper">
                            <div className="name">{user.entity.name}</div>
                            <div className="roles">
                                {
                                    user.entity.roles
                                        .map(role => t("user-role." + role))
                                        .join(", ")
                                }
                            </div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={toProfile}>{t("Edit profile")}</DropdownItem>
                        <DropdownItem onClick={logout}>{t("Logout")}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}

export const UserMenu = connect<StateProps, DispatchProps, UserMenuProps>(
    (state: any) => {
        return {
            user: selectCurrentUser(state)
        };
    },
    dispatch => {
        // noinspection JSUnusedGlobalSymbols
        return {
            logout: () => dispatch(logoutAction()),
            toProfile: () => dispatch(push("/profile"))
        };
    }
)(withTranslation("auth")(UserMenuComponent));
