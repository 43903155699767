import { faCheck, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiCallState, selectApiCallState } from "@thekeytechnology/framework-react";
import { API_CHECK_STATUS, checkStatusAction, selectStatus, Status } from "@thekeytechnology/framework-react";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";

interface ApiStatusButtonProps {
    apiIdentifier: string;
}

interface StateProps {
    checkStatusApiState: ApiCallState;
    calendarStatus: Status;
}

interface DispatchProps {
    checkStatus: (key: string) => void;
}

type Props = ApiStatusButtonProps & StateProps & DispatchProps & WithTranslation;

class ApiStatusButtonComponent extends Component<Props> {
    public constructor(props: Props) {
        super(props);
        this.checkApi = this.checkApi.bind(this);
    }

    public checkApi(): void {
        this.props.checkStatus(this.props.apiIdentifier);
    }

    public render() {
        const {t, checkStatusApiState, calendarStatus} = this.props;

        if (checkStatusApiState.succeeded) {
            return <p className="mb-0">{calendarStatus.status ?
                <FontAwesomeIcon className="text-success" icon={faCheck}/> : t(calendarStatus.error)}</p>;
        }
        if (checkStatusApiState.inProgress) {
            return <FontAwesomeIcon icon={faCircleNotch} spin/>;
        }

        return (
            <button type="button"
                    onClick={() => this.checkApi()}
                    className="btn btn-secondary">{t("check-api")}</button>
        );
    }
}

export const ApiStatusButton = connect<StateProps, DispatchProps, ApiStatusButtonProps>(
    (state, ownProps: ApiStatusButtonProps) => {
        return {
            checkStatusApiState: selectApiCallState(API_CHECK_STATUS, ownProps.apiIdentifier)(state),
            calendarStatus: selectStatus(ownProps.apiIdentifier)(state)
        };
    },
    {
        checkStatus: checkStatusAction
    }
)(withTranslation("integrations")(ApiStatusButtonComponent));
