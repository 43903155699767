import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import {
    ContentContainer,
    HeadingContainer,
} from "../../../../core/components/containers/Container";
import { EditHeaderWithBackground } from "../../../../courses/components/course/common/edit/EditHeaderWithBackground";
import { CoachingOffer } from "../../../model/coaching-offer";
import { BookedSessionTable } from "./table/BookedSessionTable";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";

type Props = WithSingleEntityFromPathProps<CoachingOffer, CoachingOffer> & WithTranslation & RouteComponentProps<any>;

const BookedSessionsOverviewComponent = (props: Props) => {
    const {entity, t} = props;
    if (!entity) {
        return <HeadingContainer><h1>{t("Loading")} {t("coaching-offers.heading")}</h1></HeadingContainer>;
    }
    return (
        <>
            <BreadcrumbsItem to={"/coaching-offers/"}>{t("coaching-offers.heading")}</BreadcrumbsItem>
            <BreadcrumbsItem
                to={"/coaching-offers/" + entity.id + "/edit"}>{entity.entity.title}</BreadcrumbsItem>
            <BreadcrumbsItem
                to={"/coaching-offers/" + entity.id + "/booked-sessions"}>{t("booked-sessions.heading")}</BreadcrumbsItem>

            <HeadingContainer>
                <h1 className="mb-0">{t("booked-sessions.heading")}</h1>
            </HeadingContainer>
            <EditHeaderWithBackground heading={t("booked-sessions.for") + ": " + entity.entity.title}>
            </EditHeaderWithBackground>
            <ContentContainer>
                <BookedSessionTable bookedSessions={entity.entity.sessions}/>
            </ContentContainer>
        </>
    );
};

export const BookedSessionsOverview = WithSingleEntityFromPath<{}, CoachingOffer, CoachingOffer>(
    withTranslation("coaching")(
        withRouter(BookedSessionsOverviewComponent)
    ) as any,
    CoachingOffer.TYPE,
    "coachingOfferId"
);
