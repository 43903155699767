import { UserWithRoles } from "@thekeytechnology/framework-react";
import React from "react";
import { Settings } from "./components/Settings";
import {PrivateRoute} from "../core/components/navigation/PrivateRoute";

export const SettingsModuleRoutes = () => {
    return (
        <>
            <PrivateRoute
                exact
                requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                path="/settings" component={Settings}/>
        </>
    );
};
