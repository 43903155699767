import {faCheck, faFrown, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityWrapper, generateString} from "@thekeytechnology/framework-react";
import React, {Component} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {ActionTableColumn} from "../../../../../core/components/table/ActionTableColumn";
import {Table, TableBody, TableColumn, TableHeader, TableRow} from "../../../../../core/components/table/Table";
import {changeParticipantStatusAction} from "../../../../actions/change-participant-status";
import {removeParticipantAction} from "../../../../actions/remove-participants";
import {CourseWithMeta} from "../../../../model/course/course-with-meta";

import {Participant} from "../../../../model/participant";
import {ParticipantStatusDisplay} from "./ParticipantStatusDisplay";
import {connect} from "react-redux";

import "./participants-table.scss";
import {UncontrolledTooltip} from "reactstrap";

interface EditParticipantsTableProps {
    courseId: string;
    participants: Array<EntityWrapper<Participant>>;
    waitingListMode: boolean;
}

interface DispatchProps {
    changeParticipantStatus: (courseId: string, email: string, status: string) => void;
    removeParticipant: (parentEntityType: string,
                        parentEntityId: string,
                        participantEmails: string[]) => void;
}

type Props = DispatchProps & WithTranslation & EditParticipantsTableProps;

class EditParticipantsTable extends Component<Props> {
    public render() {
        const {participants, courseId, changeParticipantStatus, removeParticipant, waitingListMode, t} = this.props;
        return (
            <Table
                itemCount={participants.length}
                className={"participants-table" + (waitingListMode ? " waiting-list" : "")}>
                <TableHeader>
                    <TableColumn>{t("Name")}</TableColumn>
                    <TableColumn>{t("Email")}</TableColumn>

                    {!waitingListMode ?
                        <TableColumn>{t("Status")}</TableColumn>
                        : <></>
                    }
                    <TableColumn>{t("Actions")}</TableColumn>
                </TableHeader>
                <TableBody>
                    {participants.map(participant => {
                        const randomId = generateString(10);
                        return (
                            <TableRow key={participant.id}>
                                <TableColumn className="bold title-column">
                                    {participant.entity.name}
                                </TableColumn>
                                <TableColumn>
                                    {participant.entity.email}
                                </TableColumn>

                                {!waitingListMode ?
                                    <TableColumn className="footer-column">
                                        <ParticipantStatusDisplay participant={participant}/>
                                    </TableColumn>
                                    : <></>
                                }

                                <ActionTableColumn>
                                    {!waitingListMode ?
                                        <>
                                            <a href="/" id={"attended-" + randomId}
                                               onClick={e => {
                                                   changeParticipantStatus(
                                                       courseId,
                                                       participant.entity.email,
                                                       "attended")
                                                   e.preventDefault();
                                                   return false;
                                               }}>
                                                <FontAwesomeIcon icon={faCheck}/>
                                            </a>
                                            <UncontrolledTooltip placement="top" target={"attended-" + randomId}>
                                                {t("Participant did attend")}
                                            </UncontrolledTooltip>
                                            <a href="/" id={"excused-" + randomId}
                                               onClick={e => {
                                                   changeParticipantStatus(
                                                       courseId,
                                                       participant.entity.email,
                                                       "excused")
                                                   e.preventDefault();
                                                   return false;
                                               }}>
                                                <FontAwesomeIcon icon={faFrown}/>
                                            </a>
                                            <UncontrolledTooltip placement="top" target={"excused-" + randomId}>
                                                {t("Participant was excused")}
                                            </UncontrolledTooltip>
                                            <a href="/" id={"not-attended-" + randomId}
                                               onClick={e => {
                                                   changeParticipantStatus(
                                                       courseId,
                                                       participant.entity.email,
                                                       "not-attended")
                                                   e.preventDefault();
                                                   return false;
                                               }}>
                                                <FontAwesomeIcon icon={faTimes}/>
                                            </a>
                                            <UncontrolledTooltip placement="top" target={"not-attended-" + randomId}>
                                                {t("Participant did not attend (and was not excused)")}
                                            </UncontrolledTooltip>
                                        </>
                                        : <></>
                                    }

                                    <a href="/" id={"remove-" + randomId}
                                       onClick={e => {
                                           removeParticipant(CourseWithMeta.TYPE, courseId, [participant.entity.email])
                                           e.preventDefault();
                                           return false;
                                       }}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </a>
                                    <UncontrolledTooltip placement="top" target={"remove-" + randomId}>
                                        {t("Remove participant")}
                                    </UncontrolledTooltip>
                                </ActionTableColumn>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

export default connect<{}, DispatchProps, EditParticipantsTableProps>(
    null,
    {
        changeParticipantStatus: changeParticipantStatusAction,
        removeParticipant: removeParticipantAction,
    }
)(withTranslation("courses")(EditParticipantsTable));
