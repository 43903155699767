import { UserWithRoles } from "@thekeytechnology/framework-react";
import React from "react";
import { TransctionOverview } from "./components/overview/TransactionOverview";
import {PrivateRoute} from "../core/components/navigation/PrivateRoute";

export const TransactionLogModuleRoutes = () => {
    return (
        <>
            <PrivateRoute
                exact
                requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                path="/transactions" component={TransctionOverview}/>
        </>
    );
};
