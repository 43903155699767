import { ApiCallState, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { selectCurrentUser, UserWithRoles } from "@thekeytechnology/framework-react";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import {
    API_INVITATION_DATA,
    Invitation,
    invitationDataRetrievalAttempt,
    selectInvitation
} from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { BaseAccountScreen } from "../common/BaseAccountScreen";
import { AcceptInvitation } from "./AcceptInvitation";
import AcceptInvitationWithNewRegistration from "./AcceptInvitationWIthNewRegistration";

interface InvitationScreenState {
    token?: string;
}

interface StateProps {
    apiCallState: ApiCallState;
    currentUser?: EntityWrapper<UserWithRoles>;
    invitation?: EntityWrapper<Invitation>;
}

interface DispatchProps {
    fetchInvitation: (token: string) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class InvitationScreenComponent extends React.Component<Props, InvitationScreenState> {

    public constructor(props: Props, state: InvitationScreenState) {
        super(props, state);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get("token");
        this.state = {token: token ? token : undefined};
    }

    public componentDidMount(): void {
        const {token} = this.state;
        this.props.fetchInvitation(token!);
    }

    public render() {
        const {apiCallState, invitation, currentUser} = this.props;
        const {token} = this.state;

        return (
            <BaseAccountScreen>
                <h1 className="text-white text-center mb-5">Join account</h1>

                {apiCallState.inProgress ?
                    <div className="d-flex justify-content-center">
                        <i className="fa fa-spinner fa-spin fa-3x fa-fw"/>
                    </div> : null
                }
                {apiCallState.succeeded && token && invitation ?
                    <>
                        <div className="d-flex justify-content-center paragraph text-center mb-3">
                            {invitation.entity.invitingUserName} has invited you to the account
                            "{invitation.entity.accountName}".
                        </div>
                        {currentUser ?
                            <AcceptInvitation  token={token}/>
                            : <AcceptInvitationWithNewRegistration token={token}/>
                        }
                    </>
                    : null
                }
            </BaseAccountScreen>
        );
    }
}

export const InvitationScreen = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        currentUser: selectCurrentUser(state),
        invitation: selectInvitation(state),
        apiCallState: selectCombinedApiState(API_INVITATION_DATA)(state)
    }),
    {fetchInvitation: invitationDataRetrievalAttempt}
)(withTranslation()(InvitationScreenComponent));
