import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_GET_USER_BUDGETS = "get-user-budget";

export interface GetUserBudgetsPayload {
    userIds: string[];
}

export const getUserBudgetsAction = (userIds: string[]) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_USER_BUDGETS,
            callType: API_GET_USER_BUDGETS
        },
        payload: {
            userIds,
        }
    } as ApiCallAction<GetUserBudgetsPayload>;
};
