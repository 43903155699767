import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    BlueBgContainer,
    HeadingContainer,
    PaginationContainer,
    ContentContainer
} from "../../../../core/components/containers/Container";
import { SimpleHeader } from "../../../../core/components/header/SimpleHeader";
import Pagination from "../../../../core/components/pagination/Pagination";
import { CourseCategory } from "../../../model/course-category";
import { CourseCategoriesFilter } from "./filter/CourseCategoriesFilter";
import { CourseCategoriesTable } from "./table/CourseCategoriesTable";

function CourseCategoryOverviewComponent(props: WithTranslation) {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/course-categories"}>{t("Course categories")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("Course categories")}/>
            </HeadingContainer>
            <BlueBgContainer>
                <CourseCategoriesFilter/>
            </BlueBgContainer>
            <ContentContainer>
                <CourseCategoriesTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={CourseCategory.TYPE}/>
            </PaginationContainer>
        </>
    );
}

export const CourseCategoryOverview = withTranslation("courses")(CourseCategoryOverviewComponent);
