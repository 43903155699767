import {faPencilAlt, faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {EntityCreateAndDeleteButtons} from "../../../../../core/components/buttons/EntityCreateAndDeleteButtons";
import {AboveTableContainer} from "../../../../../core/components/containers/Container";
import {ActionLink} from "../../../../../core/components/icon-link-tooltip/ActionLink";
import {ActionTableColumn} from "../../../../../core/components/table/ActionTableColumn";
import {EntityTableHeader} from "../../../../../core/components/table/EntityTableHeader";
import {EntityTableRow} from "../../../../../core/components/table/EntityTableRow";
import {Table, TableBody, TableColumn} from "../../../../../core/components/table/Table";
import {AsyncEntityTableBody} from "../../../../../entity/components/AsyncEntityTableBody";
import "./course-groups-table.scss";
import {CourseGroupWithMeta} from "../../../../model/course-group/course-group-with-meta";
import PaginationInfo from "../../../../../core/components/pagination/PaginationInfo";

type Props = WithEntityListProps<CourseGroupWithMeta> & WithSelectionsProps<string> & WithTranslation;

const CourseGroupsTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={CourseGroupWithMeta.TYPE} className="mr-auto"/>
                <EntityCreateAndDeleteButtons
                    entityType={CourseGroupWithMeta.TYPE}
                    selectedEntities={selectionModel.selections}
                    createPath="/course-groups/new/edit"
                    label={t("course group")}
                    labelPlural={t("course groups")}
                />
            </AboveTableContainer>
            <Table itemCount={entities.length} className="course-groups-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("Name")}</TableColumn>
                    <TableColumn>{t("Participants")}</TableColumn>
                    <TableColumn>{t("Actions")}</TableColumn>
                </EntityTableHeader>
                <TableBody>
                    <AsyncEntityTableBody apiCallState={entityApiState}
                                          items={entities}
                                          renderItem={courseGroup =>
                                              <EntityTableRow entity={courseGroup} selectionModel={selectionModel}>
                                                  <TableColumn className="bold title-column">
                                                      {courseGroup.entity.name}
                                                  </TableColumn>
                                                  <TableColumn>
                                                      <FontAwesomeIcon icon={faUser}
                                                                       className="mobile-only-icon lg-and-above-hide mr-2"/>
                                                      {courseGroup.entity.participants.length}
                                                  </TableColumn>
                                                  <ActionTableColumn>
                                                      <ActionLink
                                                          to={"/course-groups/" + courseGroup.id + "/edit"}
                                                          tooltip={t("Edit course group")}
                                                          icon={faPencilAlt}/>
                                                      <ActionLink
                                                          to={"/course-groups/" + courseGroup.id + "/participants"}
                                                          tooltip={t("Edit participants")}
                                                          icon={faUsers}/>
                                                  </ActionTableColumn>
                                              </EntityTableRow>
                                          }/>
                </TableBody>
            </Table>
        </>
    );
};

export const CourseGroupsTable = WithSelections<{}, string>(
    WithEntityList<WithSelectionsProps<string>, CourseGroupWithMeta>(
        withTranslation("courses")(CourseGroupsTableComponent),
        CourseGroupWithMeta.TYPE
    ) as any
);
