import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    HeadingContainer,
    PaginationContainer,
    ContentContainer
} from "../../../../core/components/containers/Container";
import { SimpleHeader } from "../../../../core/components/header/SimpleHeader";
import Pagination from "../../../../core/components/pagination/Pagination";
import { TrainerData } from "../../../model/trainer/trainer-data";
import { TrainerCards } from "../cards/TrainerCards";

const TrainerOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/trainers"}>{t("Trainer profiles")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("Trainer profiles")}/>
            </HeadingContainer>
            <ContentContainer>
                <TrainerCards/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={TrainerData.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const TrainerOverview = withTranslation("courses")(TrainerOverviewComponent);
