import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { HeadingContainer, PaginationContainer, ContentContainer } from "../../../core/components/containers/Container";
import { SimpleHeader } from "../../../core/components/header/SimpleHeader";
import Pagination from "../../../core/components/pagination/Pagination";
import { EmailConfiguration } from "../../model/email-configuration.model";
import { EmailTable } from "./table/EmailTable";

const EmailOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/emails"}>{t("Emails")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("Emails")}/>
            </HeadingContainer>
            <ContentContainer>
                <EmailTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={EmailConfiguration.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const EmailOverview = withTranslation("emails")(EmailOverviewComponent);
