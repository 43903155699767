import { faFlag } from "@fortawesome/free-solid-svg-icons";
import {
    fetchLanguageSettingsAction,
    LanguageSettings,
    selectLanguageSettings, setApplicationLanguageAction
} from "@thekeytechnology/framework-react";
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as Yup from "yup";
import { BudgetResetButton } from "../../budget/components/BudgetResetButton";
import { BudgetSettingsForm } from "../../budget/components/BudgetSettingsForm";
import { HeadingContainer, ContentContainer } from "../../core/components/containers/Container";
import { SingleValueForm } from "../../core/components/form/SingleValueForm";
import { SimpleHeader } from "../../core/components/header/SimpleHeader";
import { BambooIntegrationForm } from "./BambooIntegrationForm";
import { CalendarIntegrationForm } from "./CalendarIntegrationForm";
import { LanguageSelectField } from "./LanguageSelectField";
import {SmtpIntegrationForm} from "./SMTPForm";

interface StateProps {
    languageSettings?: LanguageSettings;
}

interface DispatchProps {
    fetchLanguageSettings: () => void;
    setApplicationLanguage: (language: string) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class SettingsComponent extends React.Component<Props> {

    public componentDidMount(): void {
        this.props.fetchLanguageSettings();
    }

    public render() {
        const {languageSettings, setApplicationLanguage, t} = this.props;

        if (!languageSettings) {
            return null;
        }

        return (
            <>
                <BreadcrumbsItem to={"/profile"}>{t("Application wide settings")}</BreadcrumbsItem>
                <HeadingContainer>
                    <SimpleHeader heading={t("Application wide settings")}/>
                </HeadingContainer>

                <ContentContainer>
                    <SingleValueForm initialValue={languageSettings.currentLanguage}
                                     label={t("Application language")}
                                     icon={faFlag}
                                     validationSchema={Yup.string().required()}
                                     apiQualifier="API_FETCH_LANGUAGE_SETTINGS"
                                     component={LanguageSelectField}
                                     availableLanguages={languageSettings.availableLanguages}
                                     onSubmit={item => setApplicationLanguage(item)}/>

                    <CalendarIntegrationForm/>
                    <BambooIntegrationForm/>
                    <BudgetSettingsForm/>
                    <BudgetResetButton/>
                    <SmtpIntegrationForm/>
                </ContentContainer>

            </>
        );
    }
}

export const Settings = connect<StateProps, DispatchProps, {}>(
    state => {
        return {
            languageSettings: selectLanguageSettings(state),
        };
    },
    {
        fetchLanguageSettings: fetchLanguageSettingsAction,
        setApplicationLanguage: setApplicationLanguageAction
    }
)(withTranslation("settings")(SettingsComponent));
