import { WithEntityList } from "@thekeytechnology/framework-react";
import { WithEntityListProps } from "@thekeytechnology/framework-react";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import "./transaction-table.scss";
import { AboveTableContainer } from "../../../core/components/containers/Container";
import { DateTimeDisplay } from "../../../core/components/display/DateTimeDisplay";
import PaginationInfo from "../../../core/components/pagination/PaginationInfo";
import { Table, TableBody, TableColumn, TableHeader, TableRow } from "../../../core/components/table/Table";
import { AsyncEntityTableBody } from "../../../entity/components/AsyncEntityTableBody";
import { Transaction } from "../../model/Transaction";
import { DownloadLogButton } from "./TransactionDownloadButton";

type Props = WithEntityListProps<Transaction> & WithTranslation;

const TransactionTableComponent = (props: Props) => {
    const {
        entities,
        entityApiState,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={Transaction.TYPE} className="mr-auto"/>
                <DownloadLogButton/>
            </AboveTableContainer>
            <Table className="transaction-table" itemCount={entities.length}>
                <TableHeader>
                    <TableColumn>{t("transaction-table.columns.dateTime")}</TableColumn>
                    <TableColumn>{t("transaction-table.columns.user")}</TableColumn>
                    <TableColumn>{t("transaction-table.columns.transactionType")}</TableColumn>
                    <TableColumn>
                        {t("transaction-table.columns.cost")}
                    </TableColumn>
                    <TableColumn>
                        {t("transaction-table.columns.entityType")}
                    </TableColumn>
                    <TableColumn>
                        {t("transaction-table.columns.comment")}
                    </TableColumn>
                </TableHeader>

                <TableBody>
                    <AsyncEntityTableBody
                        apiCallState={entityApiState}
                        items={entities}
                        renderItem={transaction => {
                            return (
                                <TableRow key={transaction.id}>
                                    <TableColumn className="bold name-column title-column">
                                        <DateTimeDisplay dateTime={transaction.entity.dateTime}/>
                                    </TableColumn>
                                    <TableColumn className="bold name-column title-column">
                                        {transaction.entity.user ? transaction.entity.user.entity.name : ""}
                                    </TableColumn>
                                    <TableColumn className="lg-and-below-hide">
                                        {t(`transaction-table.transaction-type.${transaction.entity.transactionType}`)}
                                    </TableColumn>
                                    <TableColumn>
                                        {transaction.entity.cost ? `${transaction.entity.cost.toFixed(2)}€` : t("transaction-table.no-cost")}
                                    </TableColumn>
                                    <TableColumn>
                                        {transaction.entity.entityType ? t(`transaction-table.entity-type.${transaction.entity.entityType}`) + ":" + transaction.entity.relatedEntityName
                                            : ""}
                                    </TableColumn>
                                    <TableColumn>
                                        {transaction.entity.comment}
                                    </TableColumn>
                                </TableRow>
                            );
                        }}/>
                </TableBody>
            </Table>
        </>
    );
};

export const TransactionTable = WithEntityList<{}, Transaction>(
    withTranslation("transactionlog")(TransactionTableComponent),
    Transaction.TYPE
);
