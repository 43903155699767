import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { getCurrentUserBudgetAction } from "../actions/get-current-user-budget";
import { UserBudget } from "../model/UserBudget";
import { selectCurrentUserBudget } from "../selectors";
import { ShowOnlyWhenBudgetEnabled } from "./ShowOnlyWhenBudgetEnabled";
import "./current-budget.scss";

interface StateProps {
    userBudget?: UserBudget;
}

interface DispatchProps {
    getCurrentUserBudget: typeof getCurrentUserBudgetAction;
}

type Props = StateProps & DispatchProps & WithTranslation;

class CurrentBudgetComponent extends Component<Props> {
    public componentDidMount(): void {
        this.props.getCurrentUserBudget();
    }

    public render() {
        const {t, userBudget} = this.props;

        return (
            <ShowOnlyWhenBudgetEnabled>
                <div
                    className={"ml-auto mr-3 current-budget " +
                    (userBudget && userBudget.budget < 0 ? "negative-balance" : "positive-balance")}>
                    <NavLink to="/transactions" exact activeClassName="active">
                        {t("current-budget.text")}: {userBudget ? userBudget.budget.toFixed(2) : 0}€
                    </NavLink>
                </div>
            </ShowOnlyWhenBudgetEnabled>
        );
    }
}

export const CurrentBudget = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            userBudget: selectCurrentUserBudget(state as never),
        };
    },
    {
        getCurrentUserBudget: getCurrentUserBudgetAction,
    }
)(withTranslation(["budget", "translation"])(CurrentBudgetComponent));
