import {
    matchesAnyOfTheseApiSuccesses,
    matchesApiSuccess, reloadEntityListAction
} from "@thekeytechnology/framework-react";
import {
    API_DELETE_ENTITIES,
} from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { API_CANCEL_COURSES } from "../actions/cancel-course";
import { API_CHANGE_COURSE_STATUS } from "../actions/change-course-status";
import { CourseCategory } from "../model/course-category";
import { CourseDefinition } from "../model/course-definition/course-definition.model";
import { CourseGroupWithMeta } from "../model/course-group/course-group-with-meta";
import { CourseWithMeta } from "../model/course/course-with-meta";
import { ScheduledEmail } from "../model/scheduled-email/scheduled-email";

const reloadCoursesOnStatusChange$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_CANCEL_COURSES}, {apiType: API_CHANGE_COURSE_STATUS}),
    map(() => reloadEntityListAction(CourseWithMeta.TYPE)())
);

const reloadOnScheduledEmailDeletion$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_DELETE_ENTITIES, ScheduledEmail.TYPE),
    map(() => reloadEntityListAction(ScheduledEmail.TYPE)())
);

const reloadOnCourseDefinitionDeletion$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_DELETE_ENTITIES, CourseDefinition.TYPE),
    map(() => reloadEntityListAction(CourseDefinition.TYPE)())
);

const reloadOnCourseCategoryDeletion$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_DELETE_ENTITIES, CourseCategory.TYPE),
    map(() => reloadEntityListAction(CourseCategory.TYPE)())
);

const reloadOnCourseGroupDeletion$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_DELETE_ENTITIES, CourseGroupWithMeta.TYPE),
    map(() => reloadEntityListAction(CourseGroupWithMeta.TYPE)())
);

export const reloadEpics$ = combineEpics(
    reloadCoursesOnStatusChange$,
    reloadOnScheduledEmailDeletion$,
    reloadOnCourseCategoryDeletion$,
    reloadOnCourseDefinitionDeletion$,
    reloadOnCourseGroupDeletion$
);
