import {faEuroSign, faGraduationCap, faUsers} from "@fortawesome/free-solid-svg-icons";
import {
    EntityWrapper,
} from "@thekeytechnology/framework-react";
import {Field, Form, Formik} from "formik";
import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {withTranslation, WithTranslation} from "react-i18next";
import {SaveAndBackButtons} from "../../../../core/components/buttons/SaveAndBackButtons";
import {ContentContainer} from "../../../../core/components/containers/Container";
import {ValidatedField} from "../../../../core/components/form/ValidatedField";
import {SimpleEditHeader} from "../../../../core/components/header/LoadingEditHeader";
import {BooleanSelectField} from "../../../../core/components/select/BooleanSelectField";
import {EditHeaderWithBackground} from "../../../../courses/components/course/common/edit/EditHeaderWithBackground";
import {CoachingOffer} from "../../../model/coaching-offer";
import * as Yup from "yup";
import {CoachingOfferForSaving} from "../../../model/coaching-offer-for-saving";
import {TrainerSelectField} from "./TrainerSelectField";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";
import {WysiwygField} from "../../../../core/components/wysiwyg/WysiwygField";

type Props = WithSingleEntityFromPathProps<CoachingOffer, CoachingOfferForSaving> & WithTranslation;

const EditCoachingOfferComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return (
        <>
            <BreadcrumbsItem to={"/coaching-offers"}>{t("coaching-offers.breadcrumb")}</BreadcrumbsItem>
            {entity ? <BreadcrumbsItem
                to={"/coaching-offer/" + entity.id + "/edit"}>
                {t("translation:Editing")} "{entity.entity.title ? entity.entity.title : t("coaching-offers.edit.new")}"
            </BreadcrumbsItem> : null}
            <SimpleEditHeader
                entityTypeName={t("coaching-offers.entity.singular")}
                entity={entity}
            />

            {entity ?
                <Formik
                    initialValues={{
                        title: entity.entity.title,
                        description: entity.entity.description,
                        trainer: entity.entity.trainer,
                        cost: entity.entity.cost,
                        freeFirstSession: entity.entity.freeFirstSession
                    }}
                    validationSchema={Yup.object().shape({
                        title: Yup.string().required(t("coaching-offers.edit.fields.title") + " " + t("translation:is a required field")),
                        description: Yup.string().required(t("coaching-offers.edit.fields.description") + " " + t("translation:is a required field")),
                        trainer: Yup.object().required()
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const coachingOfferForSaving = new EntityWrapper(entity.id,
                            new CoachingOfferForSaving(
                                values.trainer ? values.trainer.entity.userIdRef as string : "",
                                values.title,
                                values.description,
                                values.cost,
                                values.freeFirstSession
                            )
                        );
                        upsertEntity(coachingOfferForSaving);
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={
                                    entity.id ?
                                        entity.entity.title :
                                        t("coaching-offers.edit.new")
                                }>
                                <SaveAndBackButtons isSubmitting={isSubmitting}
                                                    entity={entity}
                                                    backPath={"/coaching-offers"}/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                <div className="form-group row">
                                    <ValidatedField icon={faUsers}
                                                    label={t("coaching-offers.edit.fields.title")}
                                                    name="title"
                                                    errors={errors}
                                                    touched={touched}
                                                    type="text"
                                                    placeholder={t("coaching-offers.edit.fields.title-placeholder")}
                                                    required
                                    />
                                </div>
                                <div className="form-group row ">
                                    <label htmlFor="description"
                                           className="col-sm-2 col-form-label">{t("coaching-offers.edit.fields.description")}</label>
                                    <div className="col-sm-10">
                                        <Field name="description"
                                               component={WysiwygField}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faGraduationCap}
                                                    label={t("coaching-offers.edit.fields.trainer")}
                                                    name="trainer"
                                                    errors={errors}
                                                    component={TrainerSelectField}
                                                    touched={touched}
                                                    required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField label={t("coaching-offers.edit.fields.cost")}
                                                    icon={faEuroSign}
                                                    name="cost"
                                                    errors={errors}
                                                    touched={touched}
                                                    type="number"
                                                    min={0}
                                                    step={0.01}
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField component={BooleanSelectField}
                                                    trueLabel={t("coaching-offers.edit.fields.free-first-session-true")}
                                                    falseLabel={t("coaching-offers.edit.fields.free-first-session-false")}
                                                    label={t("coaching-offers.edit.fields.free-first-session")}
                                                    name="freeFirstSession"
                                                    errors={errors}
                                                    touched={touched}
                                    />
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik> : null}
        </>
    );
};

export const EditCoachingOffer = WithSingleEntityFromPath<{}, CoachingOffer, CoachingOfferForSaving>(withTranslation(["coaching", "translation"])(EditCoachingOfferComponent),
    CoachingOffer.TYPE, "coachingOfferId", CoachingOffer.new);
