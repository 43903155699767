import {
    addFiltersAction, reloadEntityListAction, removeFiltersAction,
    selectEntities,
    selectFilter, setContextAction,
    setPaginationQueryAction
} from "@thekeytechnology/framework-react";
import {
    EntityWrapper,
    Filter,
    FilterTerm,
    PropertyFilter,
} from "@thekeytechnology/framework-react";
import { PaginationQuery } from "@thekeytechnology/framework-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { ClearIndicator } from "./ClearIndicator";

interface EntitySelectFilterProps<T> {
    filteredEntityType: string;
    property: string;
    fetchedEntityType: string;
    listRenderer: (item: EntityWrapper<T>) => string;
    placeholder: string;
    filterableIdGenerator?: (selected: EntityWrapper<T>) => string;
    context?: string;
}

interface StateProps<T> {
    currentFilters?: Filter[];
    entities: Array<EntityWrapper<T>>;
}

interface DispatchProps {
    addFilters: ReturnType<typeof addFiltersAction>
    removeFilters: ReturnType<typeof removeFiltersAction>
    setContext: ReturnType<typeof setContextAction>
    setPaginationQuery: ReturnType<typeof setPaginationQueryAction>
    reloadFetchedEntities: ReturnType<typeof reloadEntityListAction>
}

type Props<T> = EntitySelectFilterProps<T> & StateProps<T> & DispatchProps;

class EntitySelectFilterComponent<T> extends Component<Props<T>> {
    constructor(props: Props<T>) {
        super(props);
        this.filter = this.filter.bind(this);
    }

    public componentDidMount(): void {
        this.props.setPaginationQuery({docsPerPage: -1, page: 0});
        if (this.props.context) {
            this.props.setContext(this.props.context);
        }
        this.props.reloadFetchedEntities();
    }

    public componentWillUnmount(): void {
        if (this.props.context) {
            this.props.setContext(undefined);
        }
    }

    public filter(selected: Array<EntityWrapper<T>>) {
        const {addFilters, removeFilters, property, filterableIdGenerator} = this.props;
        if (selected && selected.length > 0) {
            const idGenerator = filterableIdGenerator ? filterableIdGenerator : (entity: EntityWrapper<T>) => entity.id;
            addFilters(property, [new PropertyFilter(
                property,
                new FilterTerm(
                    FilterTerm.TYPE_STRING_LIST,
                    FilterTerm.OPERATION_IN,
                    selected.map(idGenerator))
            )]);
        } else {
            removeFilters([property]);
        }

    }

    public render() {
        const {entities, currentFilters, listRenderer, placeholder, filterableIdGenerator} = this.props;

        const idGenerator = filterableIdGenerator ? filterableIdGenerator : (entity: EntityWrapper<T>) => entity.id;

        const currentFilterValues = currentFilters && currentFilters.length ?
            entities.filter(c => (currentFilters[0] as PropertyFilter).filterTerm.value.indexOf(idGenerator(c)) !== -1) : null;

        return (
            <Select
                components={{ClearIndicator}}
                placeholder={placeholder}
                className="filter react-select"
                classNamePrefix="react-select"
                getOptionValue={opt => opt.id as any}
                isMulti={true}
                value={currentFilterValues as any}
                onChange={this.filter as any}
                getOptionLabel={listRenderer}
                options={entities}
            />
        );
    }
}

// tslint:disable-next-line:max-classes-per-file
export class EntitySelectFilter<T> extends Component<EntitySelectFilterProps<T>> {
    private InnerComponent = connect<StateProps<T>, DispatchProps, EntitySelectFilterProps<T>>(
        (state: any, ownProps: EntitySelectFilterProps<T>) => {
            return {
                currentFilters: selectFilter(ownProps.filteredEntityType, ownProps.property)(state),
                entities: selectEntities<T>(ownProps.fetchedEntityType)(state)
            };
        },
        (dispatch, ownProps: EntitySelectFilterProps<T>) => {
            return {
                reloadFetchedEntities: () =>
                    dispatch(reloadEntityListAction(ownProps.fetchedEntityType)()),
                setContext: (context: string) => dispatch(setContextAction(ownProps.fetchedEntityType)(context)),
                setPaginationQuery: (paginationQuery: PaginationQuery) => dispatch(setPaginationQueryAction(ownProps.fetchedEntityType)(paginationQuery)),
                addFilters: (key: string, filters: Filter[]) =>
                    dispatch(addFiltersAction(ownProps.filteredEntityType)(key, filters)),
                removeFilters: (keys: string[]) =>
                    dispatch(removeFiltersAction(ownProps.filteredEntityType)(keys))
            } as DispatchProps;
        }
    )(EntitySelectFilterComponent);

    public render() {
        const InnerComponent = this.InnerComponent;
        // @ts-ignore
        return <InnerComponent {...this.props}/>;
    }
}
