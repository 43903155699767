import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { CourseWithMeta } from "../../../model/course/course-with-meta";

export interface StatusDisplayProps extends WithTranslation {
    course: EntityWrapper<CourseWithMeta>;
}

class StatusDisplayComponent extends React.Component<StatusDisplayProps> {
    public render() {
        const {course, t} = this.props;

        const isInThePast = new Date(course.entity.dateTime) < new Date();

        let badgeClass;
        let statusText;
        if (course.entity.isCancelled) {
            statusText = t("status.cancelled");
            badgeClass = "badge-danger";
        } else if (course.entity.status === "finalized") {
            statusText = t("status.finalized");
            badgeClass = "badge-success";
        } else {
            if (isInThePast) {
                statusText = t("status.not-finalized");
                badgeClass = "badge-warning";
            } else {
                statusText = t("status.future");
                badgeClass = "badge-pending";
            }
        }

        return (
            <div className={"badge badge-pill " + badgeClass}>
                {statusText}
            </div>
        );
    }
}

export const StatusDisplay = withTranslation("courses")(StatusDisplayComponent);
