import {ApiCallState, EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import { selectCurrentUser, UserWithRoles } from "@thekeytechnology/framework-react";
import { selectInvitation } from "@thekeytechnology/framework-react";
import {
    acceptInvitationAttempt,
    API_ACCEPT_INVITATION
} from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";

interface OwnProps {
    token: string;
}

interface StateProps {
    apiCallState: ApiCallState;
    currentUser?: EntityWrapper<UserWithRoles>;
}

interface DispatchProps {
    acceptInvitation: (token: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps & WithTranslation;

class AcceptInvitationComponent extends React.Component<Props> {
    public render() {
        const {token, acceptInvitation, apiCallState, currentUser} = this.props;

        return (
            currentUser ?
                <Formik
                    initialValues={{}}
                    onSubmit={(values, {setSubmitting}) => {
                        acceptInvitation(token);
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting}) => (
                        <Form>
                            <div className="paragraph text-center mb-2">
                                You are logged in as {currentUser?.entity.name} ({currentUser?.entity.email}).
                            </div>
                            <button disabled={isSubmitting || apiCallState.inProgress}
                                    type="submit"
                                    className="btn btn-primary w-100">
                                Join account
                            </button>
                        </Form>
                    )}
                </Formik> : null
        );
    }
}

export const AcceptInvitation = connect<StateProps, DispatchProps, OwnProps>(
    (state: any) => ({
        currentUser: selectCurrentUser(state),
        invitation: selectInvitation(state),
        apiCallState: selectCombinedApiState(API_ACCEPT_INVITATION)(state)
    }),
    {acceptInvitation: acceptInvitationAttempt}
)(withTranslation("auth")(AcceptInvitationComponent));
