import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { EntityWrapper, selectCurrentUser, UserWithRoles } from "@thekeytechnology/framework-react";

export interface OnlyShowToRolesProps {
    children: any;
    requiredRoles: string[];
}

interface StateProps {
    user?: EntityWrapper<UserWithRoles>;
}

type Props = OnlyShowToRolesProps & StateProps;

const OnlyShowToRolesComponent = (outerProps: Props) => {
    const {children, user, requiredRoles, ...rest} = outerProps;

    return (
        <Route
            {...rest}
            render={() => {
                if (!user) {
                    return null;
                } else {
                    const requiredRolesSafe = requiredRoles ? requiredRoles : [];
                    const roleIntersection = requiredRolesSafe.filter(x => user.entity.roles.includes(x));

                    const hasRequiredRoles = requiredRoles == null ? true : roleIntersection.length > 0;

                    return hasRequiredRoles ? children : null;
                }
            }}
        />
    );
};

export const OnlyShowToRoles = connect<StateProps, {}, OnlyShowToRolesProps>(state => ({
    user: selectCurrentUser(state),
}))(OnlyShowToRolesComponent);
