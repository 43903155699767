import { faRedo, faUsers } from "@fortawesome/free-solid-svg-icons";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { withTranslation, WithTranslation } from "react-i18next";
import { SaveAndBackButtons } from "../../../../core/components/buttons/SaveAndBackButtons";
import { ContentContainer } from "../../../../core/components/containers/Container";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { SimpleEditHeader } from "../../../../core/components/header/LoadingEditHeader";
import { CourseGroupForSaving } from "../../../model/course-group/course-group-for-saving";
import { CourseGroupWithMeta } from "../../../model/course-group/course-group-with-meta";
import { EditHeaderWithBackground } from "../../course/common/edit/EditHeaderWithBackground";
import * as Yup from "yup";
import CourseDefinitionListSelectField from "./CourseDefinitionListSelectField";
import { CourseGroupTypeField } from "./CourseGroupTypeField";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";

type Props = WithSingleEntityFromPathProps<CourseGroupWithMeta, CourseGroupForSaving> & WithTranslation;

const EditCourseGroup = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return (
        <>
            <BreadcrumbsItem to={"/course-groups"}>{t("Course groups")}</BreadcrumbsItem>
            {entity ? <BreadcrumbsItem
                to={"/course-groups/" + entity.id + "/edit"}>
                {t("translation:Editing")} "{entity.entity.name ? entity.entity.name : t("New course group")}"
            </BreadcrumbsItem> : null}
            <SimpleEditHeader entity={entity} entityTypeName={t("course group")}/>

            {entity ?
                <Formik
                    initialValues={{
                        name: entity.entity.name,
                        groupType: entity.entity.groupType,
                        courseDefinitions: entity.entity.courseDefinitions
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required(t("Name") + " " + t("translation:is a required field")),
                        groupType: Yup.string().required(t("Group type") + " " + t("translation:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const courseGroupForSaving = new EntityWrapper(entity.id,
                            new CourseGroupForSaving(
                                values.name,
                                values.courseDefinitions ? values.courseDefinitions.map(cd => cd.id) : [],
                                values.groupType,
                            )
                        );
                        upsertEntity(courseGroupForSaving);
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={
                                    entity.id ?
                                        entity.entity.name :
                                        t("New course category")
                                }>
                                <SaveAndBackButtons isSubmitting={isSubmitting}
                                                    entity={entity}
                                                    backPath="/course-groups"/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                <div className="form-group row">
                                    <ValidatedField icon={faUsers} label={t("Name")} name="name" errors={errors}
                                                    touched={touched} type="text"
                                                    placeholder={t("Enter course category name")} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faRedo} label={t("Group type")} name="groupType"
                                                    errors={errors}
                                                    touched={touched} component={CourseGroupTypeField} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField label={t("Course definitions")} name="courseDefinitions"
                                                    errors={errors}
                                                    touched={touched} component={CourseDefinitionListSelectField}
                                    />
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik> : null}
        </>
    );
};

export default WithSingleEntityFromPath<{}, CourseGroupWithMeta, CourseGroupForSaving>(
    withTranslation(["courses", "translation"])(EditCourseGroup), CourseGroupWithMeta.TYPE, "courseGroupId", CourseGroupWithMeta.new);
