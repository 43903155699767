import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_CHANGE_PARTICIPANT_STATUS = "change-participant-status";

export interface ChangeParticipantStatusPayload {
    courseId: string;
    email: string;
    status: string;
}

export interface ParticipantStatusChangedPayload {
    courseId: string;
}

export const changeParticipantStatusAction = (courseId: string, email: string, status: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_CHANGE_PARTICIPANT_STATUS,
            callType: API_CHANGE_PARTICIPANT_STATUS
        },
        payload: {
            courseId,
            email,
            status
        }
    } as ApiCallAction<ChangeParticipantStatusPayload>;
};
