import { UserWithRoles } from "@thekeytechnology/framework-react";
import React from "react";
import { FilesOverview } from "./overview/FilesOverview";
import {PrivateRoute} from "../../core/components/navigation/PrivateRoute";

export const FileModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/files"
                          component={FilesOverview}/>
        </>
    );
};
