import { combineEpics } from "redux-observable";
import { bookEpics$ } from "./book";
import { redirectEpics$ } from "./redirects";
import { reloadEpics$ } from "./reloads";

export const coachingModuleEpics = combineEpics(
    reloadEpics$,
    redirectEpics$,
    bookEpics$
);
