import { EntityWrapper } from "@thekeytechnology/framework-react";
import { FieldProps } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Select  from "react-select";
import { ClearIndicator } from "../../../../entity/components/ClearIndicator";
import { Participant } from "../../../model/participant";

interface RecipientSelectFieldProps extends WithTranslation {
    participants: Array<EntityWrapper<Participant>>;
}

class RecipientSelectFieldComponent extends Component<RecipientSelectFieldProps & FieldProps> {
    public constructor(props: RecipientSelectFieldProps & FieldProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    public onChange(data: any) {
        this.props.form.setFieldValue(this.props.field.name, data);
    }

    public render() {
        const {participants, field, t} = this.props;
        return (
            <Select
                className="react-select recipient-select"
                classNamePrefix="react-select"
                placeholder={t("All participants")}
                components={{ClearIndicator}}
                isClearable={true}
                isMulti={true}
                options={participants}
                name={field.name}
                value={participants ?
                    participants.find(option => field.value && (field.value.id === option.id)) : null}
                onChange={this.onChange}
                getOptionValue={opt => opt.id as any}
                getOptionLabel={(item: EntityWrapper<Participant>) => item.entity.name}
                onBlur={field.onBlur}
            />
        );
    }
}

export const RecipientSelectField = withTranslation("courses")(RecipientSelectFieldComponent);
