import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_MY_COURSES = "my-courses";

export const fetchMyCoursesAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_MY_COURSES,
            callType: API_MY_COURSES
        },
        payload: null
    } as ApiCallAction<null>;
};
