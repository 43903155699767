import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { Participant } from "../../../model/participant";

interface ParticipantDisplayProps {
    participants: Array<EntityWrapper<Participant>>;
}

export const ParticipantDisplay: React.FunctionComponent<ParticipantDisplayProps> =
    (props: ParticipantDisplayProps) => {

        const notOnWaitingList = props.participants.filter(p => !p.entity.onWaitingList);
        const onWaitingList = props.participants.filter(p => p.entity.onWaitingList);

        const waitingListDisplay = onWaitingList.length > 0 ? (<> ({onWaitingList.length})</>) : (<></>);

        return (
            <div className="participant-display">
                {notOnWaitingList.length}
                {waitingListDisplay}
            </div>
        );
    };
