import React from "react";

interface OwnProps {
    className?: string
    children: any
}
export const PaddedContainer = ({children, className}: OwnProps) => {
    return (
        <div className={"padded-container pl-2 pl-xs-3 pl-lg-3 pl-xl-5 pr-2 " + className}>
            {children}
        </div>
    );
};
