import {faCalendar, faClock, faGraduationCap, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {BlueBgContainer, ContentContainer, HeadingContainer} from "../../../../core/components/containers/Container";
import {DateTimeDisplay} from "../../../../core/components/display/DateTimeDisplay";
import {CourseForSaving} from "../../../model/course/course-for-saving";
import {CourseWithMeta} from "../../../model/course/course-with-meta";
import {CourseCategoryDisplay} from "../../common/displays/CourseCategoryDisplay";
import {ParticipantDisplay} from "../../common/displays/ParticipantDisplay";
import {TrainerCard} from "../../trainers/cards/TrainerCard";
import {SignupButton} from "../common/SignupButton";
import "./course-detail.scss";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";

type Props = WithSingleEntityFromPathProps<CourseWithMeta, CourseForSaving> & WithTranslation;

const CourseDetailComponent = (props: Props) => {
    const {entity, t} = props;

    return (
        <>
            <BreadcrumbsItem to={"/enroll/"}>{t("Courses")}</BreadcrumbsItem>
            {entity ?
                <BreadcrumbsItem to={"/enroll/" + entity.id}>{entity.entity.name}&nbsp;-&nbsp;
                    {<DateTimeDisplay dateTime={entity.entity.dateTime}/>}</BreadcrumbsItem> : null}

            <HeadingContainer>
                <h1 className="mb-0">
                    {entity ? entity.entity.name : ""}
                </h1>
            </HeadingContainer>

            {entity ?
                <div className="course-detail">
                    <BlueBgContainer>
                        <div className="course-detail-header row">
                            <div className="detail col-12 col-xl-3 d-flex align-items-center mb-3 mb-xl-0">
                                <FontAwesomeIcon className="mr-2" icon={faCalendar}/>
                                <DateTimeDisplay dateTime={entity.entity.dateTime}/>
                            </div>
                            <div className="detail col-12 col-xl-3 d-flex align-items-center mb-3 mb-xl-0">
                                <FontAwesomeIcon className="mr-2" icon={faUsers}/>
                                <ParticipantDisplay participants={entity.entity.participants}/>
                            </div>
                            <div className="detail col-12 col-xl-3 d-flex align-items-center mb-3 mb-xl-0">
                                <FontAwesomeIcon className="mr-2" icon={faClock}/>
                                {entity.entity.durationInMinutes} min
                            </div>
                            <div className="detail col-12 col-xl-3 d-flex align-items-center mb-3 mb-xl-0">
                                <SignupButton className="ml-auto" course={entity}/>
                            </div>
                        </div>
                    </BlueBgContainer>
                    <ContentContainer>
                        <h3 className="course-category mb-5">
                            <CourseCategoryDisplay
                                courseCategory={entity.entity.category}/>
                        </h3>
                        <div className="description mb-5"
                             dangerouslySetInnerHTML={{__html: entity.entity.courseDefinition.entity.description}}>
                        </div>

                        {entity.entity.trainer ? <div className="mb-5">
                            <h3 className="mb-5">
                                <FontAwesomeIcon className="mr-2" icon={faGraduationCap}/>
                                Trainer
                            </h3>
                            <div className="row">
                                <div className="col-12 col-xl-3 mb-3 mb-xl-0">
                                    <TrainerCard trainer={entity.entity.trainer}/>
                                </div>
                                <div className="col-12 col-xl-9"
                                     dangerouslySetInnerHTML={{__html: entity.entity.trainer.entity.shortDescription}}>
                                </div>
                            </div>
                        </div> : null}
                    </ContentContainer>
                </div> : null}
        </>
    );
};

export const CourseDetail = WithSingleEntityFromPath<{}, CourseWithMeta, CourseForSaving>(
    withTranslation(["courses"])(CourseDetailComponent), CourseWithMeta.TYPE, "courseId");
