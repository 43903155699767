import { WithEntityList, WithSelections } from "@thekeytechnology/framework-react";
import { Invitation } from "@thekeytechnology/framework-react";
import { WithEntityListProps } from "@thekeytechnology/framework-react";
import { WithSelectionsProps } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import EntityDeletionButton from "../../../../core/components/buttons/EntityDeletionButton";
import { AboveTableContainer } from "../../../../core/components/containers/Container";
import { DateTimeDisplay } from "../../../../core/components/display/DateTimeDisplay";
import { EntityTableHeader } from "../../../../core/components/table/EntityTableHeader";
import { EntityTableRow } from "../../../../core/components/table/EntityTableRow";
import { Table, TableBody, TableColumn } from "../../../../core/components/table/Table";
import { AsyncEntityTableBody } from "../../../../entity/components/AsyncEntityTableBody";
import "./invitations-table.scss";

type Props = WithEntityListProps<Invitation> & WithSelectionsProps<string> & WithTranslation;

const InvitationsTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    if (entities && entities.length === 0) {
        return <></>;
    }

    return (
        <>
            <h3>{t("Pending Invitations")}</h3>
            <AboveTableContainer>
                <EntityDeletionButton entityType={Invitation.TYPE}
                                      selectedEntities={selectionModel.selections}
                                      label={t("invitation")}
                                      labelPlural={t("invitations")}
                />
            </AboveTableContainer>
            <Table itemCount={entities.length} className="invitations-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("translation:Email")}</TableColumn>
                    <TableColumn>{t("Invited by")}</TableColumn>
                    <TableColumn>{t("Expires")}</TableColumn>
                </EntityTableHeader>
                <TableBody>
                    <AsyncEntityTableBody apiCallState={entityApiState}
                                          items={entities}
                                          renderItem={invitation =>
                                              <EntityTableRow entity={invitation} selectionModel={selectionModel}>
                                                  <TableColumn className="bold title-column">
                                                      {invitation.entity.email}
                                                  </TableColumn>
                                                  <TableColumn>
                                                      {invitation.entity.invitingUserName}
                                                  </TableColumn>
                                                  <TableColumn>
                                                      <DateTimeDisplay dateTime={invitation.entity.validUntil}/>
                                                  </TableColumn>
                                              </EntityTableRow>
                                          }/>
                </TableBody>
            </Table>
        </>
    );
};

export const InvitationsTable = WithSelections<{}, string>(
    WithEntityList<WithSelectionsProps<string>, Invitation>(
        withTranslation("auth")(InvitationsTableComponent),
        Invitation.TYPE
    ) as any
);
