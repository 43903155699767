import { WithEntityList, WithSelections } from "@thekeytechnology/framework-react";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { TkFile } from "@thekeytechnology/framework-react";
import { WithEntityListProps } from "@thekeytechnology/framework-react";
import { WithSelectionsProps } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ModalHeader } from "../../../courses/components/course/common/modal/ModalHeader";
import { FileFilters } from "../overview/filter/FileFilters";
import { FilesTableWithExternalEntitiesAndSelections } from "../overview/table/FilesTable";
import { Uploader } from "../overview/Uploader";

import "./browse-files-modal.scss";

interface OwnProps {
    onSelection: (selections: Array<EntityWrapper<TkFile>> | undefined) => void;
    isSingleSelect: boolean;
    onClose: () => void;
}

type Props = WithSelectionsProps<string> & WithEntityListProps<TkFile> & WithTranslation & OwnProps;

const BrowseFilesModalComponent = (props: Props) => {
    const {entities, entityApiState, selectionModel, onSelection, onClose, t} = props;
    return (
        <div className="modal-content edit-participants-modal">
            <div className="modal-header d-flex align-items-center p-4">
                <ModalHeader title={selectionModel.isSingleSelect ? t("Attach file") : t("Attach files")}
                             onClose={onClose}/>
            </div>
            <div className="modal-body">
                <div className="pl-4 pt-4 pr-4 pb-0 bg-white">
                    <FileFilters/>
                </div>
                <div className="pl-4 pt-4 pr-4 pb-0 bg-white">
                    <Uploader/>
                    <FilesTableWithExternalEntitiesAndSelections
                        entities={entities}
                        entityApiState={entityApiState}
                        selectionModel={selectionModel}/>
                </div>
                <div className="d-flex justify-content-end pl-4 pr-4 pb-4">
                    <button type="button" className="btn btn-primary" onClick={
                        () => onSelection(
                            entities.filter(file => selectionModel.selections.indexOf(file.id as string) > -1)
                        )
                    }>
                        {t("Attach")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export const BrowseFilesModal = WithSelections<OwnProps, string>(
    WithEntityList<OwnProps & WithSelectionsProps<string>, TkFile>(
        withTranslation("files")(BrowseFilesModalComponent),
        TkFile.TYPE
    ) as any
);
