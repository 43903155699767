import { WithEntityListProps } from "@thekeytechnology/framework-react";
import React from "react";
import { Table, TableBody } from "../../../../../core/components/table/Table";
import { AsyncEntityTableBody } from "../../../../../entity/components/AsyncEntityTableBody";
import { CourseWithMeta } from "../../../../model/course/course-with-meta";

import "./course-table.scss";
import { EnrollTableHeader } from "./EnrollTableHeader";
import { EnrollTableRow } from "./EnrollTableRow";

type Props = WithEntityListProps<CourseWithMeta>

const EnrollTableComponent = (props: Props) => {
    const {
        entities,
        entityApiState,
    } = props;

    return (
        <>
            <Table className="enroll-table" itemCount={entities.length}>
                <EnrollTableHeader/>
                <TableBody>
                    <AsyncEntityTableBody apiCallState={entityApiState} items={entities} renderItem={course => {
                        return (
                            <EnrollTableRow key={course.id}
                                            course={course}/>
                        );
                    }}/>
                </TableBody>
            </Table>
        </>
    );
};

export const EnrollTable = EnrollTableComponent;
