import { EntityWrapper} from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Filters } from "../../../../../core/components/filters/Filters";
import { TrainerData } from "../../../../../courses/model/trainer/trainer-data";
import { EntitySelectFilter } from "../../../../../entity/components/EntitySelectFilter";
import TextFilter from "../../../../../entity/components/TextFilter";

import "./coaching-offers-filters.scss";
import { CoachingOffer } from "../../../../model/coaching-offer";

const CoachingOffersFilterComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <Filters entityType={CoachingOffer.TYPE}
                 filterKeys={["entity.title"]}>
            <TextFilter
                placeholder={t("coaching-offers.filters.title")}
                entityType={CoachingOffer.TYPE}
                property="entity.title"/>
            <EntitySelectFilter<TrainerData>
                placeholder={t("coaching-offers.filters.trainer")}
                fetchedEntityType={TrainerData.TYPE}
                listRenderer={(item: EntityWrapper<TrainerData>) => item.entity.name}
                filteredEntityType={CoachingOffer.TYPE}
                property="entity.trainerUserIdRef"
                context="enroll"
                filterableIdGenerator={(selected: EntityWrapper<TrainerData>) => selected.entity.userIdRef}
            />
        </Filters>
    );
};

export const CoachingOffersFilter = withTranslation("coaching")(CoachingOffersFilterComponent);
