import {
    EntityWrapper,
    reloadEntityListAction,
    selectEntities,
    setPaginationQueryAction
} from "@thekeytechnology/framework-react";
import {FieldProps} from "formik";
import React, {Component} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import Select from "react-select";
import {ClearIndicator} from "../../../../entity/components/ClearIndicator";
import {CourseCategory} from "../../../model/course-category";
import {connect} from "react-redux";

interface StateProps {
    categories: Array<EntityWrapper<CourseCategory>>;
}

interface DispatchProps {
    fetchCategories: ReturnType<typeof reloadEntityListAction>
    setPaginationQuery: ReturnType<typeof setPaginationQueryAction>
}

type Props = StateProps & DispatchProps & WithTranslation & FieldProps;

const dot = (color = "#ccc") => ({
    "alignItems": "center",
    "display": "flex",

    ":before": {
        backgroundColor: color,
        borderRadius: 10,
        content: "\" \"",
        display: "block",
        marginRight: 10,
        height: 12,
        width: 12,
    },
});

// noinspection JSUnusedGlobalSymbols
const colourStyles = {
    singleValue: (styles: any, {data}: any) => ({...styles, ...dot(data.entity.color)}),
};

class CategorySelectField extends Component<Props> {

    public constructor(props: Props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    public componentDidMount(): void {
        this.props.setPaginationQuery({docsPerPage: -1, page: 0});
        this.props.fetchCategories();
    }

    public onChange(category: EntityWrapper<CourseCategory>) {
        this.props.form.setFieldValue(this.props.field.name, category);
    }

    public render() {
        const {categories, field, t} = this.props;
        return (
            <Select
                className="react-select category-select"
                classNamePrefix="react-select"
                placeholder={t("Select category")}
                components={{ClearIndicator}}
                isClearable={true}
                options={categories}
                name={field.name}
                value={categories ?
                    categories.find(option => field.value && (field.value.id === option.id)) : null}
                onChange={this.onChange as any}
                getOptionValue={opt => opt.id as any}
                getOptionLabel={(item: EntityWrapper<CourseCategory>) => item.entity.name}
                onBlur={field.onBlur}
                styles={colourStyles}
            />
        );
    }
}

export default connect<StateProps, DispatchProps, FieldProps>(
    (state: any) => {
        return {
            categories: selectEntities<CourseCategory>(CourseCategory.TYPE)(state)
        };
    },
    {
        fetchCategories: reloadEntityListAction(CourseCategory.TYPE),
        setPaginationQuery: setPaginationQueryAction(CourseCategory.TYPE)
    }
)(withTranslation("courses")(CategorySelectField));
