import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DialogResult, DialogSettings, showDialogAction } from "@thekeytechnology/framework-react";
import { WithTranslation, withTranslation } from "react-i18next";
import { cancelCoursesAction } from "../../../../actions/cancel-course";
import React from "react";
import { connect } from "react-redux";

interface CourseCancellationButtonProps {
    courseIds: string[];
}

interface DispatchProps {
    cancelCourses: (ids: string[]) => void;
    showDialog: (dialogSettings: DialogSettings,
                 callback: (result: DialogResult) => void) => void;
}

type Props = CourseCancellationButtonProps & DispatchProps & WithTranslation;

const CourseCancellationButtonComponent = (props: Props) => {
    const {courseIds, showDialog, cancelCourses, t} = props;

    return (
        <button type="button" onClick={() => {
            showDialog({
                    title: t("Cancel courses"),
                    question: t("Do you really want delete these courses?"),
                    explanation: t("You selected ") + courseIds.length + t(" course(s)&#46; This can not be reverted&#46;"),
                    affirmativeText: t("Cancel these courses"),
                    negativeText: t("Cancel"),
                    affirmativeButtonClass: "btn btn-danger"
                },
                (r: DialogResult) => {
                    if (r === DialogResult.ACCEPT) {
                        cancelCourses(courseIds);
                    }
                });
        }} className="btn btn-danger" disabled={courseIds.length === 0}>
            <FontAwesomeIcon className="mr-2" icon={faTimes}/>{t("Cancel courses")}
        </button>
    );
};
export const CourseCancellationButton = connect<{}, DispatchProps, CourseCancellationButtonProps>(
    null,
    {
        showDialog: showDialogAction,
        cancelCourses: cancelCoursesAction
    }
)(withTranslation("courses")(CourseCancellationButtonComponent));
