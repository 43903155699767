import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DialogResult, DialogSettings, showDialogAction } from "@thekeytechnology/framework-react";
import { deleteEntitiesAction } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";

interface EntityDeletionButtonProps {
    selectedEntities: string[];
    entityType: string;
    label: string;
    labelPlural: string;

    relatedEntityId?: string;
    additionalExplanation?: string;
}

interface DispatchProps {
    deleteEntities: (ids: string[], relatedEntityid: string) => void;
    showDialog: (dialogSettings: DialogSettings,
                 callback: (result: DialogResult) => void) => void;
}

type Props = EntityDeletionButtonProps & DispatchProps & WithTranslation;

const EntityDeletionButton = (props: Props) => {
    const {selectedEntities, showDialog, deleteEntities, label, labelPlural, relatedEntityId, additionalExplanation, t} = props;

    const countTerm = selectedEntities.length === 1 ?
        t("one") + " " + label :
        selectedEntities.length + " " + labelPlural;

    return (
        <button type="button" onClick={() => {
            showDialog({
                title: t("Deleting") + " " + labelPlural,
                question: t("Do you really want to delete") + " " + countTerm + "?",
                explanation: (additionalExplanation ? additionalExplanation + " " : "") + t("This can't be reverted") + ".",
                affirmativeText: t("Delete"),
                negativeText: t("Cancel"),
                affirmativeButtonClass: "btn btn-danger"
            }, (r: DialogResult) => {
                if (r === DialogResult.ACCEPT) {
                    deleteEntities(selectedEntities, relatedEntityId as string);
                }
            });
        }} className="btn btn-danger" disabled={selectedEntities.length === 0}>
            <FontAwesomeIcon className="mr-2" icon={faTimes}/>{t("Delete")}
        </button>
    );
};
export default connect<{}, DispatchProps, EntityDeletionButtonProps>(
    null,
    (dispatch, ownProps: EntityDeletionButtonProps) => {
        // noinspection JSUnusedGlobalSymbols
        return {
            showDialog: (dialogSettings: DialogSettings,
                         callback: (result: DialogResult) => void) =>
                dispatch(showDialogAction(dialogSettings, callback)),
            deleteEntities: (ids: string[], relatedEntityid: string) => dispatch(deleteEntitiesAction(ownProps.entityType)(ids, relatedEntityid)),
        };
    }
)(withTranslation()(EntityDeletionButton));
