import { faCalendar, faGraduationCap, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { TableColumn, TableHeader } from "../../../../../core/components/table/Table";

const EnrollTableHeaderComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <TableHeader>
            <TableColumn>{t("Name")}</TableColumn>
            <TableColumn>{t("Category")}</TableColumn>
            <TableColumn>
                <FontAwesomeIcon icon={faCalendar}/> {t("Date / Time")}
            </TableColumn>
            <TableColumn>
                <FontAwesomeIcon icon={faGraduationCap}/> {t("Trainer")}
            </TableColumn>
            <TableColumn>
                <FontAwesomeIcon icon={faUser}/> {t("Participants")}
            </TableColumn>
            <TableColumn>{t("Actions")}</TableColumn>
        </TableHeader>
    );
};

export const EnrollTableHeader = withTranslation("courses")(EnrollTableHeaderComponent);
