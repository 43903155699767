import { CourseGroupWithMeta } from "./course-group-with-meta";

export class CourseGroupForSaving {
    public static TYPE = CourseGroupWithMeta.TYPE;

    constructor(public name: string,
                public courseDefinitionsRef: string[],
                public groupType: string) {
    }
}
