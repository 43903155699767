import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_ACCEPT_DECLINE_SESSION = "accept-decline-coaching-session";

export interface AcceptDeclineSessionPayload {
    sessionId: string;
    status: string;
}

export const acceptDeclineCoachingSessionAction = (sessionId: string, status: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_ACCEPT_DECLINE_SESSION,
            callType: API_ACCEPT_DECLINE_SESSION
        },
        payload: {
            sessionId,
            status
        }
    } as ApiCallAction<AcceptDeclineSessionPayload>;
};
