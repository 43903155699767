import {
    EntityWrapper,
    reloadEntityListAction,
    selectEntities, setPaginationQueryAction,
    UserWithRoles
} from "@thekeytechnology/framework-react";
import { FieldProps } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ClearIndicator } from "../../../../entity/components/ClearIndicator";
import Select from "react-select";
import "./user-field.scss";

interface DispatchProps {
    fetchUsers: ReturnType<typeof reloadEntityListAction>;
    setPaginationQuery: ReturnType<typeof setPaginationQueryAction>;
}

interface StateProps {
    users: Array<EntityWrapper<UserWithRoles>>;
}

type Props = DispatchProps & StateProps & FieldProps & WithTranslation;

class UserFieldComponent extends Component<Props> {
    public constructor(props: Props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    public componentDidMount(): void {
        this.props.setPaginationQuery({page: 0, docsPerPage: -1});
        this.props.fetchUsers();
    }

    public onChange(users: Array<EntityWrapper<UserWithRoles>>) {
        if (!users) {
            this.props.form.setFieldValue(this.props.field.name, []);
        } else {
            this.props.form.setFieldValue(this.props.field.name, users);
        }
    }

    public render() {
        const {users, field, t} = this.props;

        const selectedUserIds = field.value ? field.value.map((f: any) => f.id) : [];

        return (
            <Select
                className="react-select user-select-field"
                classNamePrefix="react-select"
                placeholder={t("teams.edit.select-users")}
                components={{ClearIndicator}}
                isClearable={true}
                isMulti={true}
                options={users}
                name={field.name}
                value={users.filter(u => selectedUserIds.indexOf(u.id) > -1)}
                onChange={this.onChange as any}
                getOptionValue={opt => opt.id as any}
                getOptionLabel={(item: EntityWrapper<UserWithRoles>) => item.entity.name}
                onBlur={field.onBlur}
            />
        );
    }
}

export const UserField = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            users: selectEntities<UserWithRoles>(UserWithRoles.TYPE)(state)
        };
    },
    {
        fetchUsers: reloadEntityListAction(UserWithRoles.TYPE),
        setPaginationQuery: setPaginationQueryAction(UserWithRoles.TYPE)
    }
)(withTranslation("users")(UserFieldComponent));
