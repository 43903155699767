import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./core/i18n/translation.en.json";
import translationDE from "./core/i18n/translation.de.json";

import emailsEN from "./emails/i18n/emails.en.json";
import emailsDE from "./emails/i18n/emails.de.json";

import coursesEN from "./courses/i18n/courses.en.json";
import coursesDE from "./courses/i18n/courses.de.json";

import filesDE from "./files/i18n/files.de.json";
import filesEN from "./files/i18n/files.en.json";

import authDE from "./auth/i18n/auth.de.json";
import authEN from "./auth/i18n/auth.en.json";

import settingsDE from "./settings/i18n/settings.de.json";
import settingsEN from "./settings/i18n/settings.en.json";

import integrationsDE from "./integrations/i18n/integrations.de.json";
import integrationsEN from "./integrations/i18n/integrations.en.json";

import billingDE from "./billing/i18n/billing.de.json";
import billingEN from "./billing/i18n/billing.en.json";

import budgetDE from "./budget/i18n/budget.de.json";
import budgetEN from "./budget/i18n/budget.en.json";

import usersDE from "./users/i18n/users.de.json";
import usersEN from "./users/i18n/users.en.json";

import coachingDE from "./coaching/i18n/coaching.de.json";
import coachingEN from "./coaching/i18n/coaching.en.json";

import menuDE from "./menu/i18n/menu.de.json";
import menuEN from "./menu/i18n/menu.en.json";

import transactionLogDE from "./transactionlog/i18n/transactionlog.de.json";
import transactionLogEN from "./transactionlog/i18n/transactionlog.en.json";

import learningItemsDE from "./learning-items/i18n/learningItems.de.json";
import learningItemsEN from "./learning-items/i18n/learningItems.en.json";

// the translations
const resources = {
    en: {
        translation: translationEN,
        emails: emailsEN,
        courses: coursesEN,
        files: filesEN,
        auth: authEN,
        settings: settingsEN,
        integrations: integrationsEN,
        billing: billingEN,
        budget: budgetEN,
        users: usersEN,
        coaching: coachingEN,
        menu: menuEN,
        transactionlog: transactionLogEN,
        learningItems: learningItemsEN
    },
    de: {
        translation: translationDE,
        emails: emailsDE,
        courses: coursesDE,
        files: filesDE,
        auth: authDE,
        settings: settingsDE,
        integrations: integrationsDE,
        billing: billingDE,
        budget: budgetDE,
        users: usersDE,
        coaching: coachingDE,
        menu: menuDE,
        transactionlog: transactionLogDE,
        learningItems: learningItemsDE
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            checkWhitelist: true
        }
    });
