import { FieldProps } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Select from "react-select";
import { ClearIndicator } from "../../../entity/components/ClearIndicator";

const RolesSelectFieldComponent = (props: FieldProps & WithTranslation) => {
    const {field, form, t} = props;
    const rolesOptions = [
        {
            value: "admin",
            label: t("user-role.admin")
        },
        {
            value: "trainer",
            label: t("user-role.trainer")
        },
        {
            value: "email-receiver",
            label: t("user-role.email-receiver")
        },
        {
            value: "user",
            label: t("user-role.user")
        }
    ];
    return (<Select
        className="react-select roles-select"
        classNamePrefix="react-select"
        placeholder={t("Select roles")}
        components={{ClearIndicator}}
        options={rolesOptions}
        name={field.name}
        isMulti={true}
        value={
            field.value ?
                field.value.map((fieldValue: any) => rolesOptions.find(o => o.value === fieldValue)) :
                []
        }
        onChange={item => {
            const values = item ? item.map((i: any) => i.value) : [];
            form.setFieldValue(field.name, values);
        }}
        getOptionValue={opt => opt.value}
        getOptionLabel={item => item.label}
        onBlur={field.onBlur}
    />);
};

export const RolesSelectField = withTranslation("auth")(RolesSelectFieldComponent);
