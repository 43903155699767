import { faCalendarAlt, faRedo, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import DateTimePicker from "react-datetime-picker";
import { WithTranslation, withTranslation } from "react-i18next";

interface EveryXScheduleFieldProps extends WithTranslation {
    onChange: (startDate: string | undefined, endDate: string | undefined, every: number) => void;

    typeName: string;
    startDate?: string;
    endDate?: string;
    every: number;
}

class EveryXScheduleFieldComponent extends Component<EveryXScheduleFieldProps> {
    public render() {
        const {startDate, endDate, every, onChange, typeName, t} = this.props;

        return (
            <div className="every-x-schedule">
                <div className="form-group row ">
                    <label htmlFor="start-date" className="col-sm-2 col-form-label">
                        {t("Start date")}</label>
                    <div className="col-sm-10">
                        <DateTimePicker
                            name="start-date"
                            showLeadingZeros={false}
                            clearIcon={<FontAwesomeIcon icon={faTimes}/>}
                            calendarIcon={<FontAwesomeIcon icon={faCalendarAlt}/>}
                            value={startDate ? new Date(startDate) : undefined}
                            onChange={(date: Date) => {
                                onChange(date ? date.toISOString() : undefined, endDate, every);
                            }}
                        />
                    </div>
                </div>
                <div className="form-group row ">
                    <label htmlFor="start-date" className="col-sm-2 col-form-label">
                        {t("End date")}</label>
                    <div className="col-sm-10">
                        <DateTimePicker
                            name="end-date"
                            showLeadingZeros={false}
                            clearIcon={<FontAwesomeIcon icon={faTimes}/>}
                            calendarIcon={<FontAwesomeIcon icon={faCalendarAlt}/>}
                            value={endDate ? new Date(endDate) : undefined}
                            minDate={startDate ? new Date(startDate) : new Date()}
                            onChange={(date: Date) => {
                                onChange(startDate, date ? date.toISOString() : undefined, every);
                            }}
                        />
                    </div>
                </div>
                <div className="form-group row ">
                    <label htmlFor="start-date" className="col-sm-2 col-form-label">
                        {t("Every X")} {t(typeName)}</label>
                    <div className="col-sm-10">
                        <div className="input-field-wrapper">
                            <FontAwesomeIcon className="input-icon" icon={faRedo}/>
                            <input className="default-input" min={1} type="number" name="every" value={every}
                                   onChange={newValue => {
                                       onChange(startDate, endDate, Number(newValue.target.value).valueOf());
                                   }}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const EveryXScheduleField = withTranslation("courses")(EveryXScheduleFieldComponent);
