import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DialogResult, DialogSettings, generateString, showDialogAction } from "@thekeytechnology/framework-react";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { WithTranslation, withTranslation } from "react-i18next";
import React from "react";
import { connect } from "react-redux";
import { changeCourseStatusAction } from "../../../../actions/change-course-status";
import { CourseWithMeta } from "../../../../model/course/course-with-meta";
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";

interface FinalizeCourseButtonProps {
    course: EntityWrapper<CourseWithMeta>;
}

interface DispatchProps {
    changeCourseStatus: (courseId: string, status: string) => void;
    showDialog: (dialogSettings: DialogSettings,
                 callback: (result: DialogResult) => void) => void;
}

type Props = DispatchProps & FinalizeCourseButtonProps & WithTranslation;

const FinalizeCourseButtonComponent = (props: Props) => {
    const {course, showDialog, changeCourseStatus, t} = props;
    const id = "finalize" + generateString(10);
    return (
        <>
            <a href="/" className="action" id={id} onClick={e => {
                showDialog({
                        title: t("Finalizing course"),
                        question: t("Do you want finalize this course - this can not be reverted?"),
                        explanation: course.entity.name + t(" on ") +
                            moment(course.entity.dateTime).format("DD.MM.YYYY HH:mm a"),
                        affirmativeText: t("Finalize"),
                        negativeText: t("Cancel"),
                    },
                    (r: DialogResult) => {
                        if (r === DialogResult.ACCEPT) {
                            changeCourseStatus(course.id as string, "finalized");
                        }
                    });
                e.preventDefault();
                return false;
            }}>
                <FontAwesomeIcon icon={faCheck}/>
                <div className="lg-and-above-hide">{t("Finalize course")}</div>
            </a>
            <UncontrolledTooltip placement="top" target={id}>
                {t("Finalize course and prevent further editing")}.
            </UncontrolledTooltip>
        </>
    );
};

export const FinalizeCourseButton = connect<{}, DispatchProps, FinalizeCourseButtonProps>(
    null,
    {
        showDialog: showDialogAction,
        changeCourseStatus: changeCourseStatusAction
    }
)(withTranslation("courses")(FinalizeCourseButtonComponent));
