import { UserWithRoles } from "@thekeytechnology/framework-react";
import React from "react";
import { TrainerData } from "../courses/model/trainer/trainer-data";
import { AvailableOffersOverview } from "./components/booking/available-offers/AvailableOffersOverview";
import { BookedSessionsOverview } from "./components/coaching-offers/booked-sessions/BookedSessionsOverview";
import { CoachingOffersOverview } from "./components/coaching-offers/overview/CoachingOffersOverview";
import { EditCoachingOffer } from "./components/coaching-offers/single/EditCoachingOffer";
import {PrivateRoute} from "../core/components/navigation/PrivateRoute";

export const CoachingModuleRoutes = () => {
    return (
        <>
            <PrivateRoute
                exact
                path="/available-offers" component={AvailableOffersOverview}/>
            <PrivateRoute
                exact
                requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                path="/coaching-offers" component={CoachingOffersOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/coaching-offers/:coachingOfferId/edit" component={EditCoachingOffer}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}
                          path="/coaching-offers/:coachingOfferId/booked-sessions" component={BookedSessionsOverview}/>
        </>
    );
};
