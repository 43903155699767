import {ApiCallAction, selectApiBase} from "@thekeytechnology/framework-react";
import { apiCallSucceededAction, matchesApiCall } from "@thekeytechnology/framework-react";
import {
    defaultApiErrorHandling,
    selectAuthState,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import {
    Filter
} from "@thekeytechnology/framework-react";
import { selectFilters, selectPagination } from "@thekeytechnology/framework-react";
import { AxiosResponse } from "axios";
import { Action } from "redux";
import { combineEpics, StateObservable } from "redux-observable";
import { from, Observable } from "rxjs";
import { map, mergeMap, withLatestFrom } from "rxjs/operators";
import { API_DOWNLOAD_LOG } from "../actions/download-log";
import { Transaction } from "../model/Transaction";

export const downloadLogEpics$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    matchesApiCall(API_DOWNLOAD_LOG),
    withLatestFrom(state$),
    mergeMap(([action, state]: [ApiCallAction<null>, any]) => {
            const pagination = selectPagination(Transaction.TYPE)(state);

            const currentFiltersForAllTypes: Filter[][] = Object.values(selectFilters(Transaction.TYPE)(state));
            const filters: Filter[] = ([] as Filter[]).concat(...currentFiltersForAllTypes);

            return from(withAuthedAxios(selectApiBase(state), selectAuthState(state)).post(`/entities/read`, {
                entityType: Transaction.TYPE,
                paginationRequest: {
                    page: pagination.currentPage,
                    docsPerPage: pagination.docsPerPage
                },
                filters
            }, {headers: {Accept: "text/csv"}}))
                .pipe(
                    map((response: AxiosResponse) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `transactions.csv`);
                        document.body.appendChild(link);
                        link.click();
                        if (link.parentNode) {
                            link.parentNode.removeChild(link);
                        }

                        return apiCallSucceededAction(action.apiQualifier, null);
                    }),
                    defaultApiErrorHandling(action.apiQualifier)
                );
        }
    )
);

export const transactionLogModuleEpics = combineEpics(
    downloadLogEpics$ as any,
);
