import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_GET_BUDGET_SETTINGS = "get-budget-settings";

export const getBudgetSettingsAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_BUDGET_SETTINGS,
            callType: API_GET_BUDGET_SETTINGS
        },
        payload: null
    } as ApiCallAction<null>;
};
