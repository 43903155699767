import {
    ApiCallAction,
    matchesApiSuccess,
    reloadEntityListAction
} from "@thekeytechnology/framework-react";
import { apiPost } from "@thekeytechnology/framework-react";
import { selectSingleEntity } from "@thekeytechnology/framework-react";
import {
    EntityWrapper,
    fetchSingleEntityAction
} from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { combineEpics, StateObservable } from "redux-observable";
import { Observable } from "rxjs";
import { filter, map, withLatestFrom } from "rxjs/operators";
import { AppState } from "../../reducers";
import { API_ENROLL, CALL_TYPE_ENROLL, CALL_TYPE_UNENROLL, EnrollPayload } from "../actions/enroll";
import { fetchMyCoursesAction } from "../actions/fetch-my-courses";
import { CourseWithMeta } from "../model/course/course-with-meta";

const enroll$ = apiPost<EnrollPayload, any>(
    {apiType: API_ENROLL, callType: CALL_TYPE_ENROLL},
    "participants/enroll",
    (action: ApiCallAction<EnrollPayload>) => {
        return {
            courseId: action.payload.courseId,
            onWaitingList: action.payload.onWaitingList
        };
    }
);

const unenroll$ = apiPost<string, any>(
    {apiType: API_ENROLL, callType: CALL_TYPE_UNENROLL},
    "participants/unenroll",
    (action: ApiCallAction<string>) => {
        return {
            courseId: action.payload,
        };
    }
);

const reloadSingleCourseOnEnroll$ = (action$: Observable<Action>, state$: StateObservable<AppState>) => action$.pipe(
    matchesApiSuccess(API_ENROLL),
    withLatestFrom(state$),
    filter(([, state]: [ApiCallAction<void>, AppState]) => {
        const currentSelectedCourse = selectSingleEntity<EntityWrapper<CourseWithMeta>>(CourseWithMeta.TYPE)(state);
        return currentSelectedCourse !== undefined;
    }),
    map(([, state]: [ApiCallAction<void>, AppState]) => {
        const currentSelectedCourse = selectSingleEntity<CourseWithMeta>(CourseWithMeta.TYPE)(state);
        return fetchSingleEntityAction(CourseWithMeta.TYPE)((currentSelectedCourse as EntityWrapper<CourseWithMeta>).id as string);
    })
);

const reloadCoursesOnEnroll$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_ENROLL),
    map(() => reloadEntityListAction(CourseWithMeta.TYPE)())
);

const reloadMyCoursesOnEnroll$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_ENROLL),
    map(() => fetchMyCoursesAction())
);
export const enrollEpics$ = combineEpics(
    enroll$ as any,
    unenroll$ as any,
    reloadCoursesOnEnroll$ as any,
    reloadSingleCourseOnEnroll$ as any,
    reloadMyCoursesOnEnroll$ as any,
);
