import { Participant } from "../../../../model/participant";

export class ParticipantParser {
    public static fromText(participantText: string, waitingList: boolean): Participant[] {
        return participantText.split("\n").map(line => {
            let lineComponents = line.split(",");
            if (lineComponents.length !== 2) {
                lineComponents = line.split(" ");
            }
            if (lineComponents.length === 2) {
                const email = lineComponents[lineComponents.length - 1].trim();
                const name = lineComponents.slice(0, lineComponents.length - 1).join(" ").trim();
                if (email && name && email.length > 0 && name.length > 0) {
                    return new Participant(name, email, "not-attended", waitingList);
                } else {
                    return undefined;
                }
            } else {
                return undefined;
            }
        }).filter(x => x !== undefined) as Participant[];
    }
}
