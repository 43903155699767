import {
    matchesApiSuccess, reloadEntityListAction
} from "@thekeytechnology/framework-react";
import {
    API_DELETE_ENTITIES
} from "@thekeytechnology/framework-react";
import { reloadSingleEntityAction } from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { API_ACCEPT_DECLINE_SESSION } from "../actions/accept-decline-session";
import { API_BOOK_COACHING } from "../actions/book-coaching";
import { CoachingOffer } from "../model/coaching-offer";

const reloadOnCoachingOfferDeletion$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_DELETE_ENTITIES, CoachingOffer.TYPE),
    map(() => reloadEntityListAction(CoachingOffer.TYPE)())
);

const reloadOnBookingCoaching$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_BOOK_COACHING),
    map(() => reloadEntityListAction(CoachingOffer.TYPE)())
);

const reloadOnBookedSessionStatusChange$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_ACCEPT_DECLINE_SESSION),
    map(() => reloadSingleEntityAction(CoachingOffer.TYPE)())
);

export const reloadEpics$ = combineEpics(
    reloadOnCoachingOfferDeletion$,
    reloadOnBookingCoaching$,
    reloadOnBookedSessionStatusChange$ as any
);
