import { ApiCallAction } from "@thekeytechnology/framework-react";
import { Action } from "redux";

export const API_SEND_MAIL = "send-mail";

export interface SendMailPayload {
    courseId: string;
    targetParticipantIds: string[];
    subject: string;
    message: string;
}

export interface EmailSentPayload extends Action {
    courseId: string;
}

export const sendMailAction = (courseId: string,
                               targetParticipantIds: string[],
                               subject: string,
                               message: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            callType: API_SEND_MAIL,
            apiType: API_SEND_MAIL
        },
        payload: {
            courseId,
            targetParticipantIds,
            subject,
            message
        }
    } as ApiCallAction<SendMailPayload>;
};
