import React from "react";
import { Profile } from "./components/Profile";
import {PrivateRoute} from "../core/components/navigation/PrivateRoute";

export const ProfileModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact path="/profile" component={Profile}/>
        </>
    );
};
