import { FieldProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Select from "react-select";
import { ClearIndicator } from "../../../../../entity/components/ClearIndicator";

const RecipientModeFieldComponent = (props: FieldProps & WithTranslation) => {
    const {field, form, t} = props;
    const accountingTypeOptions = [
        {
            value: "admin",
            label: t("recipient-mode.admin")
        },
        {
            value: "trainer",
            label: t("recipient-mode.trainer")
        },
        {
            value: "participants",
            label: t("recipient-mode.participants")
        }
    ];
    return (<Select
        className="react-select category-select"
        classNamePrefix="react-select"
        components={{ClearIndicator}}
        options={accountingTypeOptions}
        name={field.name}
        value={accountingTypeOptions.find(o => o.value === field.value)}
        onChange={(item: any) => form.setFieldValue(field.name, item?.value)}
        getOptionValue={opt => opt.value}
        getOptionLabel={item => item.label}
        onBlur={field.onBlur}
    />);
};

export const RecipientModeField = withTranslation("courses")(RecipientModeFieldComponent);
