import { EntityWrapper } from "@thekeytechnology/framework-react";
import { TkFile } from "@thekeytechnology/framework-react";

export class TrainerData {
    public static TYPE = "trainer-data";
    public static ROLE_TRAINER = "trainer";

    constructor(public name: string,
                public userIdRef: string,
                public shortDescription: string,
                public trainerType: string,
                public avatarFile: EntityWrapper<TkFile>) {
    }
}
