import { EntityWrapper, UserWithRoles } from "@thekeytechnology/framework-react";

export class Team {
    public static TYPE = "team";

    public static new() {
        return new EntityWrapper(undefined, new Team(
            "",
            [],
            []
        ));
    }

    public constructor(public name: string,
                       public leaders: Array<EntityWrapper<UserWithRoles>>,
                       public members: Array<EntityWrapper<UserWithRoles>>) {
    }
}
