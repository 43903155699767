import {faHome} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import React from "react";
import {CoachingModuleRoutes} from "../_modules/coaching/CoachingModuleRoutes";
import {LearningItemsModuleRoutes} from "../_modules/learning-items/LearningItemsModuleRoutes";
import {ProfileModuleRoutes} from "../_modules/profile/ProfileModuleRoutes";
import {BillingModuleRoutes} from "../_modules/billing/BillingModuleRoutes";
import {SubscriptionWarning} from "../_modules/billing/components/SubscriptionWarning";
import {CourseModuleRoutes} from "../_modules/courses/components/CourseModuleRoutes";
import DialogPortal from "../_modules/dialog/components/DialogPortal";
import {EmailModuleRoutes} from "../_modules/emails/components/EmailModuleRoutes";
import {FileModuleRoutes} from "../_modules/files/components/FilesModuleRoutes";
import {SettingsModuleRoutes} from "../_modules/settings/SettingsRouter";
import {TransactionLogModuleRoutes} from "../_modules/transactionlog/TransactionLogModuleRoutes";
import {UsersModuleRoutes} from "../_modules/users/UsersModuleRoutes";
import {Dashboard} from "./dashboard/Dashboard";
import {Header} from "./header/Header";
import {Sidebar} from "./sidebar/Sidebar";
import {PrivateRoute} from "../_modules/core/components/navigation/PrivateRoute";

export const App = () => {
    return (
        <>
            <BreadcrumbsItem to="/"><FontAwesomeIcon icon={faHome}/></BreadcrumbsItem>

            <div className="container-fluid h-100">
                <div className="row h-100">
                    <Sidebar/>
                    <div className="col-12 col-lg-9 col-xl-10 h-100 container-fluid p-0 ">
                        <Header/>

                        <main role="main" className="bg-light h-100 pl-0 pl-xs-2 pl-lg-2 pl-xl-5">
                            <SubscriptionWarning/>
                            <div className="bg-white mobile-bg-light shadow">
                                <PrivateRoute exact path="/" component={Dashboard}/>
                                <CourseModuleRoutes/>
                                <FileModuleRoutes/>
                                <EmailModuleRoutes/>
                                <SettingsModuleRoutes/>
                                <BillingModuleRoutes/>
                                <ProfileModuleRoutes/>
                                <UsersModuleRoutes/>
                                <CoachingModuleRoutes/>
                                <TransactionLogModuleRoutes/>
                                <LearningItemsModuleRoutes/>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
            <DialogPortal/>
        </>
    );
}
