import {EntityWrapper, selectCurrentUser, UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {bookCoachingAction} from "../../../actions/book-coaching";
import {BookedSession} from "../../../model/booked-session";
import {CoachingOffer} from "../../../model/coaching-offer";
import {connect} from "react-redux";

export interface OwnProps {
    coachingOffer: EntityWrapper<CoachingOffer>;
    className?: string;
}

interface StateProps {
    user?: EntityWrapper<UserWithRoles>;
}

interface DispatchProps {
    bookCoaching: (offerId: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps & WithTranslation;

const RequestCoachingButtonComponent = (props: Props) => {
    const {coachingOffer, bookCoaching, user, className, t} = props;

    if (!user) {
        return null;
    }

    const thereIsAnOpenRequest = coachingOffer.entity.sessions
        .filter(s => s.entity.status === BookedSession.STATUS_REQUESTED)
        .length > 0;

    if (thereIsAnOpenRequest) {
        return <button disabled className={className + " btn btn-secondary ml-auto"}>
            {t("book-button.already-booked")}
        </button>;
    } else {
        return <button onClick={() => bookCoaching(coachingOffer.id as string)}
                       className={className + " btn btn-success ml-auto"}>
            {t("book-button.book")}
        </button>;
    }
};

export const BookCoachingButton = connect<StateProps, DispatchProps, OwnProps>(
    state => ({
        user: selectCurrentUser(state),
    }),
    {
        bookCoaching: bookCoachingAction,
    }
)(withTranslation("coaching")(RequestCoachingButtonComponent));
