import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_CHANGE_COURSE_STATUS = "change-course-status";

export interface ChangeCourseStatusPayload {
    courseId: string;
    status: string;
}

export const changeCourseStatusAction = (courseId: string, status: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_CHANGE_COURSE_STATUS,
            callType: API_CHANGE_COURSE_STATUS
        },
        payload: {
            courseId,
            status
        }
    } as ApiCallAction<ChangeCourseStatusPayload>;
};
