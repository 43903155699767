import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_PURCHASE_LEARNING_ITEMS = "purchase-learning-items";

export interface PurchaseLearningItemPayload {
    url: string;
    cost: number;
    category: string;
    comment: string;
}

export const purchaseLearningItemAction = (url: string, cost: number, category: string, comment: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_PURCHASE_LEARNING_ITEMS,
            callType: API_PURCHASE_LEARNING_ITEMS
        },
        payload: {
            url, cost, category, comment
        }
    } as ApiCallAction<PurchaseLearningItemPayload>;
};
