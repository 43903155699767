import {
    apiModuleReducer,
    ApiModuleState,
    billingModuleReducer,
    BillingModuleState
} from "@thekeytechnology/framework-react";
import { authModuleReducer, AuthModuleState } from "@thekeytechnology/framework-react";
import { dialogModuleReducer, DialogState } from "@thekeytechnology/framework-react";
import { entityModuleReducer, EntityModuleState } from "@thekeytechnology/framework-react";
import { filesModuleReducer, UploadModuleState } from "@thekeytechnology/framework-react";
import { i18nModuleReducer, I18nModuleState } from "@thekeytechnology/framework-react";
import {
    integrationsModuleReducer,
    IntegrationsModuleState
} from "@thekeytechnology/framework-react";
import { messagesModuleReducer, MessagesState } from "@thekeytechnology/framework-react";
import { settingsModuleReducer, SettingsModuleState } from "@thekeytechnology/framework-react";
import { usersModuleReducer } from "@thekeytechnology/framework-react";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { budgetModuleReducer, BudgetModuleState } from "./budget/reducers";
import { coursesModuleReducer, CoursesModuleState } from "./courses/reducers";

export interface AppState {
    router: any;
    auth: AuthModuleState;
    entities: EntityModuleState;
    dialog: DialogState;
    upload: UploadModuleState;
    messages: MessagesState;
    api: ApiModuleState;
    i18n: I18nModuleState;
    settings: SettingsModuleState;
    integrations: IntegrationsModuleState;
    billing: BillingModuleState;
    courses: CoursesModuleState;
    budget: BudgetModuleState;
}

export const appReducers = (history: any, apiBase: string) => combineReducers({
    router: connectRouter(history),
    auth: authModuleReducer,
    entities: entityModuleReducer,
    dialog: dialogModuleReducer,
    upload: filesModuleReducer,
    messages: messagesModuleReducer,
    api: apiModuleReducer(apiBase),
    i18n: i18nModuleReducer,
    settings: settingsModuleReducer,
    integrations: integrationsModuleReducer,
    billing: billingModuleReducer,
    courses: coursesModuleReducer,
    users: usersModuleReducer,
    budget: budgetModuleReducer
});
