import {faPencilAlt, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {EntityCreateAndDeleteButtons} from "../../../../core/components/buttons/EntityCreateAndDeleteButtons";
import {AboveTableContainer} from "../../../../core/components/containers/Container";
import {ActionLink} from "../../../../core/components/icon-link-tooltip/ActionLink";
import {ActionTableColumn} from "../../../../core/components/table/ActionTableColumn";
import {EntityTableHeader} from "../../../../core/components/table/EntityTableHeader";
import {EntityTableRow} from "../../../../core/components/table/EntityTableRow";
import {Table, TableBody, TableColumn} from "../../../../core/components/table/Table";
import {AsyncEntityTableBody} from "../../../../entity/components/AsyncEntityTableBody";
import {Team} from "../../../model/Team";
import "./teams-table.scss";

type Props = WithTranslation & WithEntityListProps<Team> & WithSelectionsProps<string>;

const TeamsTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <EntityCreateAndDeleteButtons
                    selectedEntities={selectionModel.selections}
                    entityType={Team.TYPE}
                    createPath="/users/teams/new/edit"
                    label={t("team")}
                    labelPlural={t("teams")}
                />
            </AboveTableContainer>
            <Table itemCount={entities.length} className="users-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("translation:Name")}</TableColumn>
                    <TableColumn>{t("teams.table.member-column")}</TableColumn>
                    <TableColumn>{t("translation:Actions")}</TableColumn>
                </EntityTableHeader>

                <TableBody>
                    <AsyncEntityTableBody
                        apiCallState={entityApiState}
                        items={entities}
                        renderItem={team =>
                            <EntityTableRow key={team.id} entity={team}
                                            selectionModel={selectionModel}>
                                <TableColumn className="bold title-column">
                                    {team.entity.name}
                                </TableColumn>
                                <TableColumn>
                                    <FontAwesomeIcon icon={faUsers}
                                                     className="mobile-only-icon lg-and-above-hide mr-2"/>
                                    {team.entity.leaders.length + team.entity.members.length}
                                </TableColumn>
                                <ActionTableColumn>
                                    <ActionLink
                                        to={"/users/teams/" + team.id + "/edit"}
                                        tooltip={t("teams.table.edit")}
                                        icon={faPencilAlt}/>
                                </ActionTableColumn>
                            </EntityTableRow>
                        }/>
                </TableBody>
            </Table>
        </>
    );
};

export const TeamsTable = WithSelections<{}, string>(
    WithEntityList<WithSelectionsProps<string>, Team>(
        withTranslation("users")(TeamsTableComponent),
        Team.TYPE
    ) as any
);
