import {useEffect} from "react";
import {connect} from "react-redux";
import {getBudgetSettingsAction} from "../actions/get-budget-settings";
import {BudgetSettings} from "../model/BudgetSettings";
import {selectBudgetSettings} from "../selectors";

interface OwnProps {
    children: any;
}

interface StateProps {
    budgetSettings?: BudgetSettings;
}

interface DispatchProps {
    getBudgetSettings: () => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const ShowOnlyWhenBudgetEnabledComponent = (props: Props) => {
    useEffect(() => {
        props.getBudgetSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {budgetSettings, children} = props;

    if (!budgetSettings || !budgetSettings.enabled) {
        return null;
    }

    return children;
};

export const ShowOnlyWhenBudgetEnabled = connect<StateProps, DispatchProps, OwnProps>(
    (state: any) => ({
        budgetSettings: selectBudgetSettings(state as never)
    }),
    {
        getBudgetSettings: getBudgetSettingsAction
    }
)(ShowOnlyWhenBudgetEnabledComponent);
