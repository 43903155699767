import { ApiCallSucceededAction, isApiSuccess } from "@thekeytechnology/framework-react";
import { API_GET_BUDGET_SETTINGS } from "../actions/get-budget-settings";
import { API_GET_CURRENT_USER_BUDGET } from "../actions/get-current-user-budget";
import { API_GET_USER_BUDGETS } from "../actions/get-user-budgets";
import { BudgetSettings } from "../model/BudgetSettings";
import { UserBudget } from "../model/UserBudget";
import { UserBudgets } from "../model/UserBudgets";

export interface BudgetState {
    currentUserBudget?: UserBudget;
    budgets: { [userId: string]: UserBudget };
    budgetSettings?: BudgetSettings;
}

export const initialState: BudgetState = {
    currentUserBudget: undefined,
    budgets: {},
    budgetSettings: undefined
};

export function budgetReducer(state = initialState, action: any): BudgetState {
    if (isApiSuccess(API_GET_CURRENT_USER_BUDGET)(action)) {
        const userBudetsResponse = action as ApiCallSucceededAction<UserBudgets>;
        return {
            ...state,
            currentUserBudget: userBudetsResponse.payload.budgets[0]
        };
    }
    if (isApiSuccess(API_GET_USER_BUDGETS)(action)) {
        const userBudetsResponse = action as ApiCallSucceededAction<UserBudgets>;

        return {
            ...state,
            budgets: {
                ...state.budgets,
                ...userBudetsResponse.payload.budgets
                    .reduce((o, key) => Object.assign(o, {[key.userId]: key}), {})
            }
        };
    }
    if (isApiSuccess(API_GET_BUDGET_SETTINGS)(action)) {
        const budgetSettingsReponse = action as ApiCallSucceededAction<BudgetSettings>;
        return {
            ...state,
            budgetSettings: budgetSettingsReponse.payload
        };
    }

    return state;
}
