import {faHeading} from "@fortawesome/free-solid-svg-icons";
import {EntityWrapper, WithSwitchableLanguage, WithSwitchableLanguageProps} from "@thekeytechnology/framework-react";
import {Form, Formik} from "formik";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import React from "react";
import {SaveAndBackButtons} from "../../../../core/components/buttons/SaveAndBackButtons";
import {ContentContainer} from "../../../../core/components/containers/Container";
import {ValidatedField} from "../../../../core/components/form/ValidatedField";
import {SimpleEditHeader} from "../../../../core/components/header/LoadingEditHeader";
import {EditHeaderWithBackground} from "../../../../courses/components/course/common/edit/EditHeaderWithBackground";
import {EmailConfiguration} from "../../../model/email-configuration.model";
import * as Yup from "yup";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";
import {WysiwygField} from "../../../../core/components/wysiwyg/WysiwygField";

type Props =
    WithSingleEntityFromPathProps<EmailConfiguration, EmailConfiguration>
    & WithTranslation
    & WithSwitchableLanguageProps;

const EditEmailComponent = (props: Props) => {
    const {entity, upsertEntity, t, currentLanguage} = props;

    return (
        <>
            <BreadcrumbsItem to={"/emails"}>{t("Emails")}</BreadcrumbsItem>
            {entity ? <BreadcrumbsItem
                to={"/emails/" + entity.id + "/edit"}>
                {t("Editing email")} "{t(entity.entity.key)}"
            </BreadcrumbsItem> : null}
            <SimpleEditHeader entity={entity} entityTypeName={t("Email")}/>

            {entity ?
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        subject: entity.entity.subjects[currentLanguage],
                        body: entity.entity.bodies[currentLanguage]
                    }}
                    validationSchema={Yup.object().shape({
                        subject: Yup.string().required(t("Subject") + " " + t("translation:is a required field")),
                        body: Yup.string().required(t("Body") + " " + t("translation:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const emailForSaving = new EntityWrapper(entity.id,
                            new EmailConfiguration(
                                entity.entity.key,
                                {
                                    ...entity.entity.subjects,
                                    [currentLanguage]: values.subject
                                },
                                {
                                    ...entity.entity.bodies,
                                    [currentLanguage]: values.body
                                },
                                {},
                                entity.entity.variables
                            )
                        );
                        upsertEntity(emailForSaving);
                        setSubmitting(false);
                    }}
                >
                    {({values, isSubmitting, errors, touched}) => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={t(entity.entity.key)}
                                leadText={<>{values.subject}</>}>
                                <SaveAndBackButtons isSubmitting={isSubmitting} entity={entity} backPath="/emails"/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                {/*<div className="form-group row">*/}
                                {/*    <label htmlFor="editing-language"*/}
                                {/*           className="col-sm-2 col-form-label">{t("Choose translation")}</label>*/}
                                {/*    <div className="col-sm-10">*/}
                                {/*        <LanguageSwitcher availableLanguages={props.availableLanguages}*/}
                                {/*                          selectedLanguage={props.editingLanguage}*/}
                                {/*                          selectLanguage={language => props.setEditingLanguage(language)}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="form-group row">
                                    <ValidatedField icon={faHeading} label={t("Subject")} name="subject"
                                                    errors={errors}
                                                    touched={touched} type="text"
                                                    placeholder={t("Enter email subject")} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField label={t("Body")} name="body" errors={errors}
                                                    touched={touched}
                                                    component={WysiwygField}
                                                    required
                                    />
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="html"
                                           className="col-sm-2 col-form-label">{t("Available Variables")}</label>
                                    <div className="col-sm-10">
                                        {entity.entity.variables.length ? <ul>
                                            {entity.entity.variables.map(v => {
                                                return <li key={v}>%%{v}%%
                                                    - {t(v)}</li>;
                                            })}
                                        </ul> : null}
                                    </div>
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik> : null}
        </>
    );
};

export const EditEmail = WithSingleEntityFromPath<{}, EmailConfiguration, EmailConfiguration>(WithSwitchableLanguage<any>(
    withTranslation("emails")(EditEmailComponent)), EmailConfiguration.TYPE, "emailId"
);
