import { ComponentProps } from "react";
import * as React from "react";
import { createPortal } from "react-dom";

import "./modal-portal.scss";

interface ModalProps {
    show: boolean;
}

export const ModalPortal = (props: ModalProps & ComponentProps<any>) => {
    const modal = (
        <div className={"modal " + (props.show ? "show" : "")}>
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                {props.children}
            </div>
        </div>
    );
    return createPortal(
        modal,
        document.querySelector("#modal")!
    );
};
