import { FieldProps } from "formik";
import React from "react";
import { SliderPicker } from "react-color";

export const ColorSelectField = (props: FieldProps) => {
    const {field, form} = props;
    return (
        <SliderPicker
            color={field.value}
            onChangeComplete={(newColor) => form.setFieldValue(field.name, newColor.hex)}
        />
    );
};
