import {Account, changeAccountAction, selectAccounts, selectCurrentAccount} from "@thekeytechnology/framework-react";
import React from "react";
import Select from "react-select";
import {connect} from "react-redux";
import {AccountInfo} from "@thekeytechnology/framework-react/dist/auth/model/account-info";

interface StateProps {
    availableAccounts?: AccountInfo[],
    currentAccount?: AccountInfo
}

interface DispatchProps {
    changeAccount: typeof changeAccountAction
}

type Props = StateProps & DispatchProps;

const AccountsSwitcherComponent = (props: Props) => {
    if (!props.currentAccount || !props.availableAccounts || (props.availableAccounts && props.availableAccounts.length <= 1)) {
        return null;
    }

    return <Select
        className="react-select ml-auto col-md-3 mb-1-sm mb-0-md"
        classNamePrefix="react-select"
        options={props.availableAccounts}
        // @ts-ignore
        value={props.availableAccounts.find(acc => acc.accountId === props.currentAccount.accountId)}
        getOptionValue={(opt: Account) => opt.accountId}
        getOptionLabel={(item: Account) => item.name}
        onChange={(a: any) => props.changeAccount(a)}
    />;

};

export const AccountSwitcher = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            availableAccounts: selectAccounts(state),
            currentAccount: selectCurrentAccount(state)
        };
    },
    {
        changeAccount: changeAccountAction
    }
)(AccountsSwitcherComponent);
