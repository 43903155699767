import { FieldProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Select from "react-select";

import { ClearIndicator } from "../../../../entity/components/ClearIndicator";
import { CourseGroupWithMeta } from "../../../model/course-group/course-group-with-meta";

const CourseGroupTypeFieldComponent = (props: FieldProps & WithTranslation) => {
    const {field, form, t} = props;
    const courseGroupTypeOptions = [
        {
            value: CourseGroupWithMeta.GROUP_TYPE_RECURRING,
            label: t("Recurring - courses in this group happen multiple times")
        },
        {
            value: CourseGroupWithMeta.GROUP_TYPE_ANY_ORDER,
            label: t("Any Order - courses in this group can be taken in any order")
        },
        {
            value: CourseGroupWithMeta.GROUP_TYPE_CONSECUTIVE,
            label: t("Consecutive Order - courses in this group have to be taken in consecutive order")
        }
    ];
    return (<Select
        className="react-select"
        classNamePrefix="react-select"
        components={{ClearIndicator}}
        options={courseGroupTypeOptions}
        name={field.name}
        value={field.value ? courseGroupTypeOptions.find(o => o.value === field.value) : null}
        onChange={(item: any) => form.setFieldValue(field.name, item ? item.value : null)}
        getOptionValue={opt => opt.value}
        getOptionLabel={item => item.label}
        onBlur={field.onBlur}
    />);
};

export const CourseGroupTypeField = withTranslation("courses")(CourseGroupTypeFieldComponent);
