import { CourseWithMeta } from "../course/course-with-meta";
import { Schedule } from "../schedule/schedule";

export class CourseDefinitionForSaving {
    public static TYPE = CourseWithMeta.TYPE;

    constructor(public name: string,
                public description: string,
                public capacity: number,
                public durationInMinutes: number,
                public schedule: Schedule,
                public category: string | undefined,
                public trainer: string | undefined,
                public cost: number,
                public accountingType: string | undefined,
                public attachments: string[]) {
    }
}
