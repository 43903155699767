import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {HeadingContainer} from "../../core/components/containers/Container";
import {SimpleHeader} from "../../core/components/header/SimpleHeader";

class BillingComponent extends React.Component<WithTranslation> {
    public render() {
        const {t} = this.props;

        return (<>
                <BreadcrumbsItem to={"/profile"}>{t("billing-settings.heading")}</BreadcrumbsItem>
                <HeadingContainer>
                    <SimpleHeader heading={t("billing-settings.heading")}/>
                </HeadingContainer>
            </>
        );
    }
}

export const Billing = withTranslation(["billing", "translation"])(BillingComponent);
