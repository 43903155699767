import { ApiCallAction } from "@thekeytechnology/framework-react";
import { Participant } from "../model/participant";

export const API_ADD_PARTICIPANTS = "add-participants";

export interface AddParticipantsPayload {
    parentEntityType: string;
    parentEntityId: string;
    participants: Participant[];
}

export interface ParticipantsAddedPayload {
    parentEntityType: string;
    parentEntityId: string;
}

export const addParticipantsAction = (parentEntityType: string,
                                      parentEntityId: string,
                                      participants: Participant[]) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_ADD_PARTICIPANTS,
            callType: API_ADD_PARTICIPANTS
        },
        payload: {
            parentEntityType,
            parentEntityId,
            participants
        }
    } as ApiCallAction<AddParticipantsPayload>;
};
