import { faCalendar, faGraduationCap, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DateTimeDisplay } from "../../../../../core/components/display/DateTimeDisplay";
import { TableColumn, TableRow } from "../../../../../core/components/table/Table";
import { CourseWithMeta } from "../../../../model/course/course-with-meta";
import { CourseCategoryDisplay } from "../../../common/displays/CourseCategoryDisplay";
import { ParticipantDisplay } from "../../../common/displays/ParticipantDisplay";
import { SignupButton } from "../../common/SignupButton";
import { NavLink } from "react-router-dom";

interface EnrollTableRowProps extends WithTranslation {
    course: EntityWrapper<CourseWithMeta>;
}

const EnrollTableRowComponent = (props: EnrollTableRowProps) => {
    const {course, t} = props;

    return (
        <TableRow key={course.id}>
            <TableColumn className="bold name-column title-column">
                <NavLink to={"/enroll/" + course.id}>
                    {course.entity.name}
                </NavLink>
            </TableColumn>
            <TableColumn className="lg-and-below-hide">
                <CourseCategoryDisplay courseCategory={course.entity.category}/>
            </TableColumn>
            <TableColumn>
                <FontAwesomeIcon icon={faCalendar} className="mobile-only-icon lg-and-above-hide mr-2"/>
                <DateTimeDisplay dateTime={course.entity.dateTime}/>
            </TableColumn>
            <TableColumn>
                <FontAwesomeIcon icon={faGraduationCap} className="mobile-only-icon lg-and-above-hide mr-2"/>
                {course.entity.trainer ?
                    course.entity.trainer.entity.name : t("no trainer")}
            </TableColumn>
            <TableColumn>
                <FontAwesomeIcon icon={faUser} className="mobile-only-icon lg-and-above-hide mr-2"/>
                <ParticipantDisplay participants={course.entity.participants}/>
            </TableColumn>
            <TableColumn className="footer-column">
                <SignupButton course={course}/>
            </TableColumn>
        </TableRow>
    );
};

export const EnrollTableRow = withTranslation("courses")(EnrollTableRowComponent);
