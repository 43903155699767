import { faCalendarAlt, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldProps } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { DateTimeDisplay } from "../../../../../core/components/display/DateTimeDisplay";
import { FixedDatesSchedule } from "../../../../model/schedule/fixed-dates-schedule";
import DateTimePicker from "react-datetime-picker";

interface FixedDatesScheduleFieldState {
    selectedDate?: Date;
}

class FixedDatesScheduleFieldComponent extends Component<FieldProps & WithTranslation, FixedDatesScheduleFieldState> {
    public constructor(props: FieldProps & WithTranslation) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            selectedDate: undefined
        };
    }

    public onChange(add: boolean, newDate?: Date) {
        if (newDate) {
            const schedule = this.resolveSchedule();

            const newDateTimes = add ? Array.from(new Set([...schedule.dateTimes, newDate.toISOString()])) :
                schedule.dateTimes.filter(d => d !== newDate.toISOString());

            const newSchedule = new FixedDatesSchedule(
                newDateTimes
            );
            this.props.form.setFieldValue(this.props.field.name, newSchedule);
        }
    }

    public render() {
        const {t} = this.props;
        const schedule = this.resolveSchedule();

        return (
            <div className="fixed-dates-schedule">
                <div className="alert alert-warning" role="alert">
                    {t("fixed-dates-schedule-advisory")}
                </div>

                <div className="form-group row">
                    <label htmlFor="start-date" className="col-sm-2 col-form-label">
                        {t("Selected dates")}</label>
                    <div className="col-sm-10 pt-3">
                        {schedule.dateTimes.length === 0 ?
                            <p>{t("To start, please add dates and times below")}</p> :
                            <ul className="schedule-dates">
                                {schedule.dateTimes.map(d =>
                                    <li key={d}>
                                        <DateTimeDisplay dateTime={d}/>
                                        <a href="/" onClick={e => {
                                            this.onChange(false, new Date(d))
                                            e.preventDefault();
                                            return false;
                                        }}>
                                            <FontAwesomeIcon className="ml-1" icon={faTimes}/>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        }
                    </div>
                </div>
                <div className="form-group row mb-4">
                    <label htmlFor="start-date" className="col-sm-2 col-form-label">
                        {t("Add date")}</label>
                    <div className="col-sm-10">
                        <div className="controls d-flex">
                            <DateTimePicker
                                className="mr-3"
                                showLeadingZeros={false}
                                clearIcon={<FontAwesomeIcon icon={faTimes}/>}
                                calendarIcon={<FontAwesomeIcon icon={faCalendarAlt}/>}
                                value={this.state.selectedDate}
                                onChange={newDate => this.setState({selectedDate: newDate})}
                            />
                            <button type="button" className="btn btn-success"
                                    onClick={() => this.onChange(true, this.state.selectedDate)}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private resolveSchedule() {
        const {field} = this.props;
        return field.value && field.value.scheduleType === FixedDatesSchedule.TYPE ?
            field.value as FixedDatesSchedule : new FixedDatesSchedule([]);
    }
}

export const FixedDatesScheduleField = withTranslation("courses")(FixedDatesScheduleFieldComponent);
