import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "formik";
import React from "react";

interface ValidatedFieldProps {
    label?: string;
    name: string;
    errors: any;
    touched: any;

    icon?: IconDefinition;

    // 'Indexer'
    [x: string]: any
}

export const ValidatedField = (props: ValidatedFieldProps) => {
    const {icon, name, errors, required, label, touched} = props;
    const hasError = errors[name] && touched[name];
    const columnClass = label ? "col-sm-10" : null;

    return (
        <>
            {label ?
                <label htmlFor={name} className="col-sm-2 col-form-label">{label} {required ? "*" : ""}</label> : null}

            <div className={columnClass ? columnClass : ""}>
                {icon ? <div className="input-field-wrapper">
                        <FontAwesomeIcon className="input-icon" icon={icon}/>
                        <Field {...props} name={name} className="form-control default-input"/>
                    </div> :
                    <Field {...props} name={name} className="form-control default-input"/>
                }

                {hasError ? (
                    <div className="validation-error">{errors[name]}</div>
                ) : null}
            </div>
        </>);
};
