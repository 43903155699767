import { UserWithRoles } from "@thekeytechnology/framework-react";
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    HeadingContainer,
    ContentContainer,
    PaginationContainer
} from "../../../core/components/containers/Container";
import { SimpleHeader } from "../../../core/components/header/SimpleHeader";
import Pagination from "../../../core/components/pagination/Pagination";
import { InvitationsTable } from "./pending-invites/InvitationsTable";
import { UsersTable } from "./table/UsersTable";

const UsersInAccountOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/users"}>{t("Users in account")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("Users in account")}/>
            </HeadingContainer>
            <ContentContainer>
                <InvitationsTable/>
            </ContentContainer>
            <ContentContainer>
                <UsersTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={UserWithRoles.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const UsersInAccountOverview = withTranslation("auth")(UsersInAccountOverviewComponent);
