import { EntityWrapper } from "@thekeytechnology/framework-react";

export class CourseCategory {
    public static TYPE = "course-category";

    public static new() {
        return new EntityWrapper(undefined, new CourseCategory(
            "",
            ""
        ));
    }

    constructor(public name: string, public color: string) {
    }
}
