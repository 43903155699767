import { faLock } from "@fortawesome/free-solid-svg-icons";
import { ApiCallState, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { API_RESET_PASSWORD, resetPasswordAction } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ValidatedField } from "../../../core/components/form/ValidatedField";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";

import { BaseAccountScreen } from "../common/BaseAccountScreen";

interface ResetPasswordState {
    token: string;
}

interface StateProps {
    apiCallState: ApiCallState;
}

interface DispatchProps {
    resetPassword: (token: string, password: string) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class ResetPasswordComponent extends React.Component<Props, ResetPasswordState> {

    public constructor(props: Props, state: ResetPasswordState) {
        super(props, state);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get("token");
        if (token) {
            this.state = {token};
        }
    }

    public render() {
        const {t, apiCallState, resetPassword} = this.props;
        const {token} = this.state;

        return (
            <BaseAccountScreen>
                <h1 className="text-white text-center mb-3">{t("Reset your password.")}</h1>

                {apiCallState.succeeded ?
                    <div className="paragraph text-center mb-2">
                        Your password has been successfully reset. <NavLink
                        className=" mb-5"
                        to="/login">{t("Login here!")}</NavLink>
                    </div> :
                    <Formik
                        initialValues={{
                            password: "",
                        }}
                        validationSchema={Yup.object().shape({
                            password: Yup.string().min(8).required(),
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            resetPassword(token, values.password);
                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting, errors, touched}) => (
                            <Form>
                                <div className="paragraph text-center mb-2">
                                    Please type your new password.
                                </div>
                                <div className="form-group">
                                    <ValidatedField errors={errors} touched={touched} icon={faLock}
                                                    type="password" placeholder={t("Password")} name="password"
                                                    className="form-control default-input" required/>
                                </div>
                                <button disabled={isSubmitting || apiCallState.inProgress}
                                        type="submit"
                                        className="btn btn-primary w-100">
                                    Reset password
                                </button>
                            </Form>
                        )}
                    </Formik>}
            </BaseAccountScreen>
        );
    }
}

export const ResetPassword = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        apiCallState: selectCombinedApiState(API_RESET_PASSWORD)(state)
    }),
    {resetPassword: resetPasswordAction}
)(withTranslation()(ResetPasswordComponent));
