import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {ModalPortal} from "../core/components/modal/ModalPortal";
import {TrainerData} from "../courses/model/trainer/trainer-data";
import {TransctionOverview} from "../transactionlog/components/overview/TransactionOverview";
import {RequestLearningItemModal} from "./components/RequestLearningItemModal";
import {PrivateRoute} from "../core/components/navigation/PrivateRoute";

export const LearningItemsModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER, UserWithRoles.ROLE_USER]}
                          path="/transactions/request-learning-item"
                          component={(props: any) => (
                              <>
                                  <TransctionOverview {...props}/>
                                  <ModalPortal show={true}>
                                      <RequestLearningItemModal {...props} />
                                  </ModalPortal>,
                              </>
                          )}
            />
        </>
    );
};
