import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reloadEntityListAction, removeFiltersAction } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";
import PaginationInfo from "../pagination/PaginationInfo";

import "./filters.scss";

interface OwnProps {
    entityType: string;
    filterKeys: string[];

    children: any;
}

interface DispatchProps {
    removeFilters: ReturnType<typeof removeFiltersAction>
    reloadEntities: ReturnType<typeof reloadEntityListAction>
}

type Props = OwnProps & DispatchProps & WithTranslation;

interface FiltersState {
    isOpen: boolean;
}

class FiltersComponent extends React.Component<Props, FiltersState> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.toggleOpen = this.toggleOpen.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    public toggleOpen() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    public render() {
        const {t, entityType} = this.props;
        const {isOpen} = this.state;
        return (
            <div className="filters">
                <button type="button" className="filters-menu-toggle" onClick={this.toggleOpen}>
                    <FontAwesomeIcon className="filter-icon" icon={faFilter}/>
                </button>

                <div className={"filters-menu" + (isOpen ? " open" : "")}>
                    {this.props.children}

                    <button type="button" onClick={() => {
                        if (isOpen) {
                            this.toggleOpen();
                        }
                        this.resetFilters();
                    }} className="clear-filters">
                        <FontAwesomeIcon icon={faTimes}/> {t("translation:Clear filters")}
                    </button>
                </div>

                <PaginationInfo className="ml-auto" entityType={entityType}/>
            </div>
        );
    }

    public resetFilters() {
        this.props.removeFilters(this.props.filterKeys);
        this.props.reloadEntities();
    }
}

export const Filters = connect<{}, DispatchProps, OwnProps>(
    null,
    (dispatch: Dispatch<Action>, ownProps: OwnProps) => {
        return {
            removeFilters: (filterKeys: string[]) => dispatch(removeFiltersAction(ownProps.entityType)(filterKeys)),
            reloadEntities: () => dispatch(reloadEntityListAction(ownProps.entityType)())
        };
    }
)(withTranslation("translation")(FiltersComponent));
