import {
    API_CREATE_ENTITIES, API_DELETE_ENTITIES, API_INVITE_USERS,
    API_UPDATE_ENTITIES, matchesAnyOfTheseApiSuccesses, matchesApiSuccess, reloadEntityListAction
} from "@thekeytechnology/framework-react";
import { push } from "connected-react-router";
import { Action } from "redux";
import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Team } from "../model/Team";

const redirectAfterTeamWrite$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesAnyOfTheseApiSuccesses({
            apiType: API_CREATE_ENTITIES, callType: Team.TYPE
        }, {
            apiType: API_UPDATE_ENTITIES, callType: Team.TYPE
        }),
        map(() => push("/users/teams"))
    );

const reloadOnTeamDeletion$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_DELETE_ENTITIES, Team.TYPE),
    map(() => reloadEntityListAction(Team.TYPE)())
);

const redirectAfterInvitation$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_INVITE_USERS),
        map(() => push("/users"))
    );

export const additionalUserEpics$ = combineEpics(
    redirectAfterTeamWrite$,
    reloadOnTeamDeletion$ as any,
    redirectAfterInvitation$
);
