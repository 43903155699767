import {
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { removeParticipantAction } from "../../../../actions/remove-participants";
import { CourseGroupWithMeta } from "../../../../model/course-group/course-group-with-meta";

import { Participant } from "../../../../model/participant";
import { connect } from "react-redux";

import "./participants-table.scss";

interface EditParticipantsTableProps {
    courseGroupId: string;
    participants: Array<EntityWrapper<Participant>>;
}

interface DispatchProps {
    removeParticipant: (parentEntityType: string,
                        parentEntityId: string,
                        participantEmails: string[]) => void;
}

type Props = EditParticipantsTableProps & DispatchProps & WithTranslation;

class EditParticipantsTable extends Component<Props> {
    public render() {
        const {participants, courseGroupId, removeParticipant, t} = this.props;
        return (
            <div
                className={"table participants-table "
                + (participants.length === 0 ? " empty" : "")
                }>
                <div className="table-header">
                    <div className="table-row pt-3 pb-3">
                        <div className="table-column">{t("Name")}</div>
                        <div className="table-column">{t("Email")}</div>
                        <div className="table-column">{t("Actions")}</div>

                    </div>
                </div>
                <div className="table-body">
                    {participants.map(participant => {
                        return (
                            <div key={participant.id} className="table-row pt-3 pb-3">
                                <div className="table-column bold">
                                    {participant.entity.name}
                                </div>
                                <div className="table-column">
                                    {participant.entity.email}
                                </div>
                                <div className="table-column actions-column">
                                    <a href="/" onClick={e =>{
                                        removeParticipant(CourseGroupWithMeta.TYPE, courseGroupId, [participant.entity.email])
                                        e.preventDefault();
                                        return false;
                                    }}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default connect<{}, DispatchProps, EditParticipantsTableProps>(
    null,
    {
        removeParticipant: removeParticipantAction,
    }
)(withTranslation("courses")(EditParticipantsTable));
