import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Filters } from "../../../../../core/components/filters/Filters";
import DateTimeRangeFilter from "../../../../../entity/components/DateTimeRangeFilter";
import { EntitySelectFilter } from "../../../../../entity/components/EntitySelectFilter";
import TextFilter from "../../../../../entity/components/TextFilter";
import { CourseCategory } from "../../../../model/course-category";
import { CourseWithMeta } from "../../../../model/course/course-with-meta";
import { TrainerData } from "../../../../model/trainer/trainer-data";

import StatusFilter from "./StatusFilter";

const CourseFilters = (props: WithTranslation) => {
    const {t} = props;
    return (
        <Filters
            entityType={CourseWithMeta.TYPE}
            filterKeys={["entity.name", "entity.dateTime", "entity.courseCategoryRef", "entity.trainerUserIdRef", "course-status"]}>
            <TextFilter
                placeholder={t("Filter by name")}
                entityType={CourseWithMeta.TYPE}
                property="entity.name"/>
            <EntitySelectFilter
                placeholder={t("Filter by categories")}
                fetchedEntityType={CourseCategory.TYPE}
                listRenderer={(item: EntityWrapper<CourseCategory>) => item.entity.name}
                filteredEntityType={CourseWithMeta.TYPE}
                property="entity.categoryRef"
            />
            <DateTimeRangeFilter
                entityType={CourseWithMeta.TYPE}
                property="entity.dateTime"/>
            <EntitySelectFilter
                placeholder={t("Filter by trainer")}
                fetchedEntityType={TrainerData.TYPE}
                listRenderer={(item: EntityWrapper<TrainerData>) => item.entity.name}
                filteredEntityType={CourseWithMeta.TYPE}
                property="entity.trainerUserIdRef"
                filterableIdGenerator={(selected: EntityWrapper<TrainerData>) => selected.entity.userIdRef}
            />
            <StatusFilter/>
        </Filters>
    );
};

export default withTranslation("courses")(CourseFilters);
