import {faCalendar, faEnvelope, faGraduationCap, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    ShowOnlyWithActiveSubscription,
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {EntityCreateAndDeleteButtons} from "../../../../../core/components/buttons/EntityCreateAndDeleteButtons";
import {AboveTableContainer} from "../../../../../core/components/containers/Container";
import {ActionLink} from "../../../../../core/components/icon-link-tooltip/ActionLink";
import {ActionTableColumn} from "../../../../../core/components/table/ActionTableColumn";
import {EntityTableHeader} from "../../../../../core/components/table/EntityTableHeader";
import {EntityTableRow} from "../../../../../core/components/table/EntityTableRow";
import {Table, TableColumn} from "../../../../../core/components/table/Table";
import {AsyncEntityTableBody} from "../../../../../entity/components/AsyncEntityTableBody";
import {CourseDefinition} from "../../../../model/course-definition/course-definition.model";
import PaginationInfo from "../../../../../core/components/pagination/PaginationInfo";
import {CourseCategoryColorBubble} from "../../../common/displays/CourseCategoryColorBubble";
import {CourseCategoryDisplay} from "../../../common/displays/CourseCategoryDisplay";
import {ScheduleDisplay} from "../../single/schedule/ScheduleDisplay";
import "./course-definitions-table.scss";

type Props = WithEntityListProps<CourseDefinition> & WithTranslation & WithSelectionsProps<string>;

const CourseDefinitionTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={CourseDefinition.TYPE} className="mr-auto"/>

                <ShowOnlyWithActiveSubscription exceptAccounts={["babbel"]}>
                    <EntityCreateAndDeleteButtons
                        selectedEntities={selectionModel.selections}
                        entityType={CourseDefinition.TYPE}
                        createPath="/course-definitions/new/edit"
                        label={t("course definition")}
                        labelPlural={t("course definitions")}
                        additionalInformation={t("The related courses will be deleted too") + "."}
                    />
                </ShowOnlyWithActiveSubscription>
            </AboveTableContainer>
            <Table itemCount={entities.length} className="course-definitions-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("Name")}</TableColumn>
                    <TableColumn>
                        {t("Schedule")}
                    </TableColumn>
                    <TableColumn>{t("Category")}</TableColumn>
                    <TableColumn>
                        <FontAwesomeIcon icon={faGraduationCap}/> {t("Trainer")}
                    </TableColumn>
                    <TableColumn>{t("Actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={courseDefinition =>
                        <EntityTableRow
                            key={courseDefinition.id}
                            entity={courseDefinition} selectionModel={selectionModel}>
                            <TableColumn className="bold title-column">
                                <CourseCategoryColorBubble
                                    className="mr-2 color-bubble lg-and-above-hide"
                                    color={courseDefinition.entity.courseCategory ? courseDefinition.entity.courseCategory.entity.color : ""}/>
                                {courseDefinition.entity.name}
                            </TableColumn>
                            <TableColumn>
                                <FontAwesomeIcon icon={faCalendar}
                                                 className="mobile-only-icon lg-and-above-hide mr-2"/>
                                <ScheduleDisplay schedule={courseDefinition.entity.schedule}/>
                            </TableColumn>
                            <TableColumn className="lg-and-below-hide">
                                <CourseCategoryDisplay
                                    courseCategory={courseDefinition.entity.courseCategory}/>
                            </TableColumn>
                            <TableColumn>
                                <FontAwesomeIcon icon={faGraduationCap}
                                                 className="mobile-only-icon lg-and-above-hide mr-2"/>
                                {courseDefinition.entity.trainer ?
                                    courseDefinition.entity.trainer.entity.name : t("no trainer")}
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink
                                    to={"/course-definitions/" + courseDefinition.id + "/edit"}
                                    tooltip={t("Edit course definition")}
                                    icon={faPencilAlt}/>
                                <ActionLink
                                    to={"/course-definitions/" + courseDefinition.id + "/scheduled-emails"}
                                    tooltip={t("Edit scheduled emails")}
                                    icon={faEnvelope}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const CourseDefinitionTable = WithSelections<{}, string>(
    WithEntityList(
        withTranslation("courses")(CourseDefinitionTableComponent),
        CourseDefinition.TYPE
    ) as any
);
