import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Participant } from "../participant";
import { CourseDefinitionForGroupDto } from "./course-definition-for-group";

export class CourseGroupWithMeta {
    public static TYPE = "course-group";

    public static GROUP_TYPE_RECURRING = "recurring";
    public static GROUP_TYPE_CONSECUTIVE = "consecutive";
    public static GROUP_TYPE_ANY_ORDER = "any-order";

    public static new() {
        return new EntityWrapper(undefined, new CourseGroupWithMeta(
            "",
            [],
            CourseGroupWithMeta.GROUP_TYPE_RECURRING,
            []
        ));
    }

    constructor(public name: string,
                public courseDefinitions: CourseDefinitionForGroupDto[],
                public groupType: string,
                public participants: Array<EntityWrapper<Participant>>) {
    }

}
