import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    BlueBgContainer,
    PaginationContainer,
    ContentContainer
} from "../../../../core/components/containers/Container";
import { PaddedContainer } from "../../../../core/components/containers/PaddedContainer";
import Pagination from "../../../../core/components/pagination/Pagination";
import { CourseWithMeta } from "../../../model/course/course-with-meta";

import CourseFilters from "./filter/CourseFilters";
import CourseOverviewHeader from "./header/CourseOverviewHeader";
import { CourseTable } from "./table/CourseTable";

const CourseOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to="/courses">{t("Courses")}</BreadcrumbsItem>

            <PaddedContainer className="bg-white pt-4 pb-4">
                <CourseOverviewHeader/>
            </PaddedContainer>
            <BlueBgContainer>
                <CourseFilters/>
            </BlueBgContainer>
            <ContentContainer>
                <CourseTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={CourseWithMeta.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const CourseOverview = withTranslation()(CourseOverviewComponent);
