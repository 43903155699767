import { faClock, faEnvelope, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    ApiCallState,
    WithCurrentLanguage,
    WithCurrentLanguageProps,
    WithSelections
} from "@thekeytechnology/framework-react";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { WithSelectionsProps } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { EntityCreateAndDeleteButtons } from "../../../../../core/components/buttons/EntityCreateAndDeleteButtons";
import { AboveTableContainer } from "../../../../../core/components/containers/Container";
import { ActionLink } from "../../../../../core/components/icon-link-tooltip/ActionLink";
import PaginationInfo from "../../../../../core/components/pagination/PaginationInfo";
import { ActionTableColumn } from "../../../../../core/components/table/ActionTableColumn";
import { EntityTableHeader } from "../../../../../core/components/table/EntityTableHeader";
import { EntityTableRow } from "../../../../../core/components/table/EntityTableRow";
import { Table, TableColumn } from "../../../../../core/components/table/Table";
import { AsyncEntityTableBody } from "../../../../../entity/components/AsyncEntityTableBody";
import { ScheduledEmail } from "../../../../model/scheduled-email/scheduled-email";

import "./scheduled-emails-table.scss";

interface OwnProps {
    courseDefinitionId: string;
    scheduledEmails: Array<EntityWrapper<ScheduledEmail>>;
    scheduledEmailsApiState: ApiCallState;
}

type Props = OwnProps & WithSelectionsProps<string> & WithTranslation & WithCurrentLanguageProps;

const ScheduledEmailsTableComponent = ({
                                           courseDefinitionId,
                                           scheduledEmails,
                                           scheduledEmailsApiState,
                                           selectionModel,
                                           t,
    currentLanguage
                                       }: Props) => {

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ScheduledEmail.TYPE} className="mr-auto"/>
                <EntityCreateAndDeleteButtons
                    entityType={ScheduledEmail.TYPE}
                    createPath={"/course-definitions/" + courseDefinitionId + "/scheduled-emails/new"}
                    selectedEntities={selectionModel.selections}
                    label={t("delete-scheduled-email")}
                    labelPlural={t("delete-scheduled-emails")}
                    relatedEntityId={courseDefinitionId}
                />
            </AboveTableContainer>
            <Table itemCount={scheduledEmails.length} className="scheduled-emails-table">
                <EntityTableHeader entities={scheduledEmails} selectionModel={selectionModel}>
                    <TableColumn>{t("Subject")}</TableColumn>
                    <TableColumn>{t("Recipient(s)")}</TableColumn>
                    <TableColumn>{t("Schedule")}</TableColumn>
                    <TableColumn>{t("Actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={scheduledEmailsApiState}
                    items={scheduledEmails}
                    renderItem={scheduledEmail =>
                        <EntityTableRow entity={scheduledEmail} selectionModel={selectionModel}>
                            <TableColumn className="bold title-column">
                                {scheduledEmail.entity.subjects[currentLanguage] ?
                                    scheduledEmail.entity.subjects[currentLanguage] :
                                    "---" + t("Subject is not translated") + "---"}
                            </TableColumn>
                            <TableColumn>
                                <FontAwesomeIcon icon={faEnvelope}
                                                 className="mobile-only-icon lg-and-above-hide mr-2"/>
                                {t("recipient-mode." + scheduledEmail.entity.recipientMode)}
                            </TableColumn>
                            <TableColumn>
                                <FontAwesomeIcon icon={faClock}
                                                 className="mobile-only-icon lg-and-above-hide mr-2"/>

                                {scheduledEmail.entity.scheduleRelation.hours} {t("hour(s)")}&nbsp;
                                {t("relation-type." + scheduledEmail.entity.scheduleRelation.relationType)}
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink to={"/course-definitions/" + courseDefinitionId +
                                "/scheduled-emails/" + scheduledEmail.id}
                                            tooltip={t("Edit scheduled email")}
                                            icon={faPencilAlt}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
        </>
    );
};

export const ScheduledEmailsTable = WithSelections<OwnProps, string>(
    WithCurrentLanguage<any>(withTranslation("courses")(ScheduledEmailsTableComponent)),
);
