import {EntityWrapper, selectCurrentUser, UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { LinkCard } from "../../_modules/core/components/link-card/link-card";
import { LinkCards } from "../../_modules/core/components/link-card/link-cards";
import { TrainerData } from "../../_modules/courses/model/trainer/trainer-data";
import { connect } from "react-redux";
import { faEnvelope, faList, faUsers } from "@fortawesome/free-solid-svg-icons";
import {OnlyShowToRoles} from "../../_modules/core/components/navigation/OnlyShowToRoles";

interface StateProps {
    user?: EntityWrapper<UserWithRoles>;
}

type Props = StateProps & WithTranslation;

class DashboardComponent extends React.Component<Props> {
    public render() {
        const {user, t} = this.props;

        if (!user) {
            return null;
        }

        return (
            <div className="p-5">
                <h1> {t("dashboard.welcome-user", {userName: user.entity.name})}</h1>
                <p className="lead mb-5">{t("dashboard.welcome-message")}</p>

                <LinkCards>
                    <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}>
                        <LinkCard className="green" icon={faList} title={t("Courses")} link="/courses"/>
                        <LinkCard className="blue" icon={faEnvelope} title={t("Emails")} link="/emails"/>
                        <LinkCard className="purple" icon={faUsers} title={t("Trainers")} link="/trainers"/>
                        <LinkCard className="teal" icon={faList} title={t("Enrollment")} link="/enroll"/>
                    </OnlyShowToRoles>
                </LinkCards>
            </div>
        );
    }
}

export const Dashboard = withTranslation()(connect<StateProps, {}, {}>(
    (state: any) => {
        return {
            user: selectCurrentUser(state)
        };
    }
)(DashboardComponent));
