import {faEuroSign, faHeading, faUsers} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-solid-svg-icons/faClock";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    EntityWrapper,
} from "@thekeytechnology/framework-react";
import {Field, Form, Formik} from "formik";
import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {SaveAndBackButtons} from "../../../../core/components/buttons/SaveAndBackButtons";
import {ContentContainer} from "../../../../core/components/containers/Container";
import {ValidatedField} from "../../../../core/components/form/ValidatedField";
import {SimpleEditHeader} from "../../../../core/components/header/LoadingEditHeader";
import {CourseDefinitionForSaving} from "../../../model/course-definition/course-definition-for-saving";
import {CourseDefinition} from "../../../model/course-definition/course-definition.model";
import CategorySelectField from "../../common/fields/CategorySelectField";
import TrainerSelectField from "../../common/fields/TrainerSelectField";
import {EditHeaderWithBackground} from "../../course/common/edit/EditHeaderWithBackground";
import {AccountingTypeField} from "./AccountingTypeField";
import {AttachmentField} from "../../../../files/components/attachment/AttachmentField";
import {ScheduleDisplay} from "./schedule/ScheduleDisplay";
import {ScheduleEditor} from "./schedule/ScheduleEditor";
import * as Yup from "yup";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";
import {WysiwygField} from "../../../../core/components/wysiwyg/WysiwygField";

type Props = WithSingleEntityFromPathProps<CourseDefinition, CourseDefinitionForSaving> & WithTranslation;

const EditCourseDefinition = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return (
        <>
            <BreadcrumbsItem to={"/course-definitions/"}>{t("Course definitions")}</BreadcrumbsItem>
            {entity ?
                <BreadcrumbsItem
                    to={"/course-defintions/" + entity.id}>
                    {entity.entity.name ? entity.entity.name : "New course definition"}
                </BreadcrumbsItem> : null}

            <SimpleEditHeader entity={entity} entityTypeName={t("course definition")}/>
            {entity ?
                <Formik
                    initialValues={{
                        name: entity.entity.name,
                        description: entity.entity.description,
                        capacity: entity.entity.capacity,
                        durationInMinutes: entity.entity.durationInMinutes,
                        category: entity.entity.courseCategory,
                        trainer: entity.entity.trainer,
                        cost: entity.entity.cost,
                        schedule: entity.entity.schedule,
                        accountingType: entity.entity.accountingType,
                        attachments: entity.entity.attachments
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required(t("Name") + " " + t("translation:is a required field")),
                        durationInMinutes: Yup.number().positive().required(t("Duration") + " " + t("translation:is a required field")),
                        capacity: Yup.number().positive().required(t("Capacity") + " " + t("translation:is a required field")),
                        cost: Yup.number().min(0)
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const saving = new EntityWrapper(entity.id,
                            new CourseDefinitionForSaving(
                                values.name,
                                values.description,
                                values.capacity,
                                values.durationInMinutes,
                                values.schedule,
                                values.category ? values.category.id as string : undefined,
                                values.trainer ? values.trainer.entity.userIdRef as string : undefined,
                                values.cost,
                                values.accountingType,
                                values.attachments.map((a: any) => a.id as string)
                            )
                        );
                        upsertEntity(saving);
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={
                                    entity.id ?
                                        entity.entity.name :
                                        t("translation:New") + " " + t("course definition")
                                }
                                leadText={
                                    <ScheduleDisplay schedule={entity.entity.schedule}/>
                                }>
                                <SaveAndBackButtons isSubmitting={isSubmitting} entity={entity}
                                                    backPath="/course-definitions"/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                <h3>{t("translation:General")}</h3>
                                <div className="form-group row">
                                    <ValidatedField icon={faHeading} label={t("Name")} name="name" errors={errors}
                                                    touched={touched} type="text" placeholder={t("Enter name")}
                                                    required
                                    />
                                </div>
                                <div className="form-group row ">
                                    <ValidatedField icon={faUsers} label={t("Capacity")}
                                                    name="capacity"
                                                    errors={errors} touched={touched} type="number" min="0"
                                                    required
                                    />
                                </div>
                                <div className="form-group row ">
                                    <label htmlFor="category"
                                           className="col-sm-2 col-form-label">{t("Category")}</label>
                                    <div className="col-sm-10">
                                        <Field name="category" component={CategorySelectField}/>
                                    </div>
                                </div>
                                <div className="form-group row ">
                                    <label htmlFor="trainer" className="col-sm-2 col-form-label">{t("Trainer")}</label>
                                    <div className="col-sm-10">
                                        <Field name="trainer" component={TrainerSelectField}/>
                                    </div>
                                </div>
                                <div className="form-group row ">
                                    <label htmlFor="description"
                                           className="col-sm-2 col-form-label">{t("Description")}</label>
                                    <div className="col-sm-10">
                                        <Field name="description"
                                               component={WysiwygField}/>
                                    </div>
                                </div>
                                <div className="form-group row ">
                                    <label htmlFor="attachments"
                                           className="col-sm-2 col-form-label">{t("Attachments")}</label>
                                    <div className="col-sm-10">
                                        <Field name="attachments" component={AttachmentField}/>
                                    </div>
                                </div>
                                <h3>{t("Schedule")}</h3>
                                <div className="form-group row ">
                                    <ValidatedField icon={faClock} label={t("Duration")}
                                                    name="durationInMinutes"
                                                    errors={errors} touched={touched} type="number" min="0"
                                                    placeholder={t("Enter duration in minutes")} required

                                    />
                                </div>
                                <Field name="schedule"
                                       className="form-control"
                                       component={ScheduleEditor}
                                       required/>

                                <h3>{t("Accounting")}</h3>
                                <div className="form-group row ">
                                    <label htmlFor="accountingType" className="col-sm-2 col-form-label">
                                        {t("Accounting type")}</label>
                                    <div className="col-sm-10">
                                        <div className="input-field-wrapper">
                                            <FontAwesomeIcon className="input-icon" icon={faEuroSign}/>
                                            <Field name="accountingType"
                                                   component={AccountingTypeField}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row ">
                                    <ValidatedField icon={faEuroSign} label={t("Cost")} name="cost"
                                                    errors={errors} touched={touched} type="number" min="0"
                                                    placeholder={t("Enter cost")}

                                    />
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik> : null}
        </>
    );
};

export default WithSingleEntityFromPath<{}, CourseDefinition, CourseDefinitionForSaving>(
    withTranslation("courses")(EditCourseDefinition), CourseDefinition.TYPE,
    "courseDefinitionId", CourseDefinition.new);
