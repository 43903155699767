import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";

import React from "react";
import { Link } from "react-router-dom";

interface LinkCardProps {
    link: string;
    icon: IconDefinition;
    title: string;
    className?: string;
}

export class LinkCard extends React.Component<LinkCardProps> {
    public render() {
        const {link, icon, title, className} = this.props;
        return (
            <div className="col-12 col-lg-6 col-xl-4 mb-4">
                <Link to={link}
                      className={(className ? className : "") +
                      " link-card p-5 d-flex align-items-center justify-content-center flex-column"}>
                    <div className="card-icon d-flex justify-content-center align-items-center mb-3">
                        <FontAwesomeIcon icon={icon}/>
                    </div>
                    <h2 className="card-title m-0">{title}</h2>
                </Link>
            </div>
        );
    }
}
