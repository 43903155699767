import { faBox, faComment, faEuroSign, faLink } from "@fortawesome/free-solid-svg-icons";
import { Form, Formik } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "./request-learning-item-modal.scss";
import * as Yup from "yup";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { ModalHeader } from "../../courses/components/course/common/modal/ModalHeader";
import { purchaseLearningItemAction } from "../actions/purchase-learning-items";
import { LearningItemCategorySelectField } from "./LearningItemCategorySelectField";

interface DispatchProps {
    purchaseLearningItem: typeof purchaseLearningItemAction,
}

type Props = DispatchProps & WithTranslation;

function RequestLearningItemModalComponent(props: Props) {
    const {t, purchaseLearningItem} = props;

    return (
        <div className="modal-content invite-users-modal">
            <div className="modal-header p-4">
                <ModalHeader title={t("request-modal.title")}
                             returnPath="/transactions"/>
            </div>
            <div className="modal-body p-4 d-flex flex-column">
                <Formik
                    initialValues={{
                        url: "",
                        cost: 1,
                        category: "",
                        comment: ""
                    }}
                    validationSchema={Yup.object().shape({
                        url: Yup.string().required(t("request-modal.link") + " " + t("translation:is a required field")),
                        cost: Yup.number().positive().required(t("request-modal.cost") + " " + t("translation:is a required field")),
                        category: Yup.string().required(t("request-modal.category") + " " + t("translation:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        purchaseLearningItem(values.url, values.cost, values.category, values.comment);
                        setSubmitting(false);
                    }}>
                    {({isSubmitting, touched, errors}) => (
                        <Form className="d-flex flex-column">
                            <div className="form-group row">
                                <ValidatedField icon={faLink} label={t("request-modal.link")} name="url" errors={errors}
                                                touched={touched} type="text"
                                                placeholder={t("request-modal.link-placeholder")} required
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField icon={faEuroSign} label={t("request-modal.cost")} name="cost"
                                                errors={errors}
                                                touched={touched} type="number"
                                                min={1}
                                                step={0.01}
                                                placeholder={t("request-modal.cost-placeholder")} required
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField icon={faBox} label={t("request-modal.category")} name="category"
                                                errors={errors}
                                                touched={touched}
                                                component={LearningItemCategorySelectField}
                                                required
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField icon={faComment} label={t("request-modal.comment")} name="comment"
                                                errors={errors}
                                                touched={touched} type="text"
                                                placeholder={t("request-modal.comment-placeholder")}
                                />
                            </div>

                            <button type="submit" className="btn btn-primary ml-auto"
                                    disabled={isSubmitting}>
                                {t("request-modal.submit")}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export const RequestLearningItemModal = connect<{}, DispatchProps, {}>(
    null,
    {
        purchaseLearningItem: purchaseLearningItemAction,
    }
)(withTranslation(["learningItems", "translation"])(RequestLearningItemModalComponent));
