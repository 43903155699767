import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Field, Form, Formik} from "formik";
import React, {Component} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {addParticipantsAction} from "../../../../actions/add-participant";
import {Participant} from "../../../../model/participant";
import {ParticipantParser} from "./ParticipantParser";
import * as Yup from "yup";
import "./addition-form.scss";

interface BulkAdditionFormProps {
    parentEntityType: string;
    parentEntityId: string;
    addToWaitingList: boolean;
    doSwitchForms: () => void;
}

interface DispatchProps {
    addParticipants: (parentEntityType: string,
                      parentEntityId: string,
                      participants: Participant[]) => void;
}

type Props = DispatchProps & BulkAdditionFormProps & WithTranslation;

class BulkAdditionForm extends Component<Props> {

    public render() {
        const {parentEntityType, parentEntityId, addParticipants, doSwitchForms, addToWaitingList, t} = this.props;
        return (
            <div className="addition-form bulk">
                <a href="/" onClick={e => {
                    doSwitchForms()
                    e.preventDefault()
                    return false;
                }} className="mode-toggle">{t("Bulk")} <FontAwesomeIcon icon={faChevronUp}/></a>
                <Formik
                    initialValues={{
                        bulk: "",
                    }}
                    validationSchema={Yup.object().shape({
                        bulk: Yup.string().required(t("At least one entry is required")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const participants = ParticipantParser.fromText(values.bulk, addToWaitingList);
                        if (participants) {
                            addParticipants(parentEntityType, parentEntityId, participants);
                        }
                        setSubmitting(false);
                    }}>
                    {({isSubmitting}) => (
                        <Form>
                            <Field className="default-input mr-2 w-100"
                                   placeholder={t("bulk-add-format")}
                                   component="textarea"
                                   rows="5"
                                   required
                                   name="bulk"/>
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                {t("Add participants")}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default connect<{}, DispatchProps, BulkAdditionFormProps>(
    null,
    {
        addParticipants: addParticipantsAction
    }
)(withTranslation("courses")(BulkAdditionForm));
