import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_MY_COURSE_GROUPS = "my-course-groups";

export const fetchMyCourseGroupsAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_MY_COURSE_GROUPS,
            callType: API_MY_COURSE_GROUPS
        },
        payload: null
    } as ApiCallAction<null>;
};
