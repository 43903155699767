import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Filters } from "../../../../../core/components/filters/Filters";
import TextFilter from "../../../../../entity/components/TextFilter";
import { CourseCategory } from "../../../../model/course-category";

import "./course-categories-filters.scss";

const CourseCategoriesFilterComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <Filters entityType={CourseCategory.TYPE}
                 filterKeys={["entity.name", "entity.courseCategoryRef", "entity.trainerUserIdRef"]}>
            <TextFilter
                placeholder={t("Filter by name")}
                entityType={CourseCategory.TYPE}
                property="entity.name"/>
        </Filters>
    );
};

export const CourseCategoriesFilter = withTranslation("courses")(CourseCategoriesFilterComponent);
