import { faCalendar, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
    API_FETCH_SETTINGS,
    API_SET_SETTING,
    fetchSettingsAction,
    selectJsonSetting, setSettingAction, ShowWhenApiSucceeded
} from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import * as Yup from "yup";
import { connect } from "react-redux";
import { ApiStateSubmitButton } from "../../core/components/form/ApiStateSubmitButton";
import { ValidatedField } from "../../core/components/form/ValidatedField";
import { ApiStatusButton } from "../../integrations/components/ApiStatusButton";

interface StateProps {
    emailSettings: any;
}

interface DispatchProps {
    setSetting: (key: string, value: string) => void;
    fetchSettings: (keys: string[]) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class SmtpIntegrationFormComponent extends Component<Props> {
    public componentDidMount(): void {
        this.props.fetchSettings(["email-settings"]);
    }

    public render() {
        const {t, setSetting, emailSettings} = this.props;

        return (
            <ShowWhenApiSucceeded apiQualifier={API_FETCH_SETTINGS}
                                  callType="email-settings"
                                  duringLoading={<h3 className="mb-3">{t("email-integration.loading")}</h3>}>
                <Formik
                    initialValues={{
                        hostname: emailSettings.hostname ? emailSettings.hostname : "",
                        port: emailSettings.port ? emailSettings.port : "",
                        user: emailSettings.user ? emailSettings.user : "",
                        password: emailSettings.password ? emailSettings.password : "",
                        fromEmail: emailSettings.fromEmail ? emailSettings.fromEmail : "",
                    }}
                    validationSchema={Yup.object().shape({
                        hostname: Yup.string().required(t("email-integration.hostname") + " " + t("translation:is a required field")),
                        port: Yup.string().required(t("email-integration.port") + " " + t("translation:is a required field")),
                        user: Yup.string().required(t("email-integration.user") + " " + t("translation:is a required field")),
                        password: Yup.string().required(t("email-integration.password") + " " + t("translation:is a required field")),
                        fromEmail: Yup.string().required(t("email-integration.fromEmail") + " " + t("translation:is a required field")),

                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        setSetting("email-settings", JSON.stringify({
                            hostname: values.hostname,
                            port: values.port,
                            user: values.user,
                            password: values.password,
                            fromEmail: values.fromEmail,
                        }));
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => {
                        const hasError = !!(errors.hostname && touched.hostname) || !!(errors.port && touched.port) || !!(errors.user && touched.user) || !!(errors.password && touched.password) || !!(errors.fromEmail && touched.fromEmail);

                        return (
                            <Form>
                                <h3 className="mb-3 mt-5">{t("email-integration.heading")}</h3>
                                <div className="form-group row">
                                    <label htmlFor={"name"}
                                           className="col-sm-2 col-form-label">{t("email-integration.api-status")}</label>
                                    <div className="col-sm-10 d-flex align-items-center">
                                        <ApiStatusButton apiIdentifier="email-sending"/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faCalendar}
                                                    label={t("email-integration.hostname")}
                                                    name="hostname" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faEnvelope}
                                                    label={t("email-integration.port")}
                                                    name="port" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faEnvelope}
                                                    label={t("email-integration.user")}
                                                    name="user" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faEnvelope}
                                                    label={t("email-integration.password")}
                                                    name="password" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faEnvelope}
                                                    label={t("email-integration.fromEmail")}
                                                    name="fromEmail" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <ApiStateSubmitButton
                                    apiQualifier={API_SET_SETTING}
                                    callType="email-settings"
                                    submitText={t("email-integration.submit")}
                                    hasError={hasError}
                                    isSubmitting={isSubmitting}/>
                            </Form>
                        );
                    }}
                </Formik>
            </ShowWhenApiSucceeded>);
    }
}

export const SmtpIntegrationForm = connect<StateProps, DispatchProps, {}>(
    state => {
        return {
            emailSettings: selectJsonSetting("email-settings")(state)
        };
    },
    {
        fetchSettings: fetchSettingsAction("email-settings"),
        setSetting: setSettingAction,
    }
)(withTranslation(["settings", "translation"])(SmtpIntegrationFormComponent));
