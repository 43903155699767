import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldProps } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ClearIndicator } from "../../../../../entity/components/ClearIndicator";
import { DailySchedule } from "../../../../model/schedule/daily-schedule";
import { FixedDatesSchedule } from "../../../../model/schedule/fixed-dates-schedule";
import { MonthlySchedule } from "../../../../model/schedule/monthly-schedule";
import { WeeklySchedule } from "../../../../model/schedule/weekly-schedule";
import { YearlySchedule } from "../../../../model/schedule/yearly-schedule";
import { DailyScheduleField } from "./DailyScheduleField";
import { FixedDatesScheduleField } from "./FixedDatesScheduleField";
import Select from "react-select";
import { MonthlyScheduleField } from "./MonthlyScheduleField";
import { WeeklyScheduleField } from "./WeeklyScheduleField";
import { YearlyScheduleField } from "./YearlyScheduleField";

interface ScheduleEditorState {
    scheduleType: any;
}

class ScheduleEditorComponent extends Component<FieldProps & WithTranslation, ScheduleEditorState> {
    public constructor(props: FieldProps & WithTranslation) {
        super(props);
        const options = this.getOptions(props);
        this.state = {
            scheduleType: props.field.value ?
                options.find(o => o.value === props.field.value.scheduleType) :
                options[0]
        };
    }

    public render() {
        const {field, form, t} = this.props;

        const {scheduleType} = this.state;

        let ScheduleComponent = <></>;
        switch (scheduleType.value) {
            case FixedDatesSchedule.TYPE:
                ScheduleComponent = <FixedDatesScheduleField field={field} form={form}/>;
                break;
            case DailySchedule.TYPE:
                ScheduleComponent = <DailyScheduleField field={field} form={form}/>;
                break;
            case WeeklySchedule.TYPE:
                ScheduleComponent = <WeeklyScheduleField field={field} form={form}/>;
                break;
            case MonthlySchedule.TYPE:
                ScheduleComponent = <MonthlyScheduleField field={field} form={form}/>;
                break;
            case YearlySchedule.TYPE:
                ScheduleComponent = <YearlyScheduleField field={field} form={form}/>;
        }

        return (
            <>
                <div className="form-group row">
                    <label htmlFor="schedule" className="col-sm-2 col-form-label">
                        {t("Schedule type")}</label>
                    <div className="col-sm-10">
                        <div className="input-field-wrapper">
                            <FontAwesomeIcon className="input-icon" icon={faCalendar}/>
                            <Select
                                className="react-select category-select"
                                classNamePrefix="react-select"
                                components={{ClearIndicator}}
                                options={this.getOptions()}
                                name="scheduleType"
                                value={scheduleType}
                                onChange={newType => {
                                    this.setState({scheduleType: newType});
                                }}
                            />
                        </div>
                    </div>
                </div>
                {ScheduleComponent}
            </>
        );
    }

    private getOptions(props?: FieldProps & WithTranslation) {
        const {t} = props ? props : this.props;
        return [
            {
                value: FixedDatesSchedule.TYPE,
                label: t("schedule-type.fixed")
            },
            {
                value: DailySchedule.TYPE,
                label: t("schedule-type.daily")
            },
            {
                value: WeeklySchedule.TYPE,
                label: t("schedule-type.weekly")
            },
            {
                value: MonthlySchedule.TYPE,
                label: t("schedule-type.monthly")
            },
            {
                value: YearlySchedule.TYPE,
                label: t("schedule-type.yearly")
            }
        ];
    }
}

export const ScheduleEditor = withTranslation("courses")(ScheduleEditorComponent);
