import {FieldProps} from "formik";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import Select from "react-select";
import {ClearIndicator} from "../../entity/components/ClearIndicator";

type Props = FieldProps & WithTranslation;

const LearningItemCategorySelectFieldComponent = (props: Props) => {
    const {field, form, t} = props;

    const categories = [
        "academic", "accomodation-transport", "agile-scrum", "coaching",
        "computer-science", "creative-inspiration", "creative-writing",
        "customer-support", "data-engineering", "decision-making",
        "design", "food-nutrition", "happiness",
        "health-fitness", "hr", "improvisation", "language",
        "leadership", "marketing", "mindfulness",
        "music", "other", "painting-drawing-crafting",
        "product-management", "productivity",
        "psychology", "qa", "ux"
    ];

    const categoryOptions = categories.map(category => ({
        value: category,
        label: t("category-options." + category)
    }));

    return (
        <Select
            className="react-select category-select"
            classNamePrefix="react-select"
            components={{ClearIndicator}}
            options={categoryOptions}
            name={field.name}
            value={categoryOptions.find(option => option.value === field.value)}
            onChange={(item: any) => form.setFieldValue(field.name, item.value)}
            getOptionValue={opt => opt as any}
            getOptionLabel={item => item.label}
            onBlur={field.onBlur}
        />
    );
};

export const LearningItemCategorySelectField = withTranslation("learningItems")(LearningItemCategorySelectFieldComponent);
