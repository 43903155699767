import {
    EntityWrapper,
    reloadEntityListAction,
    selectEntities,
    setPaginationQueryAction
} from "@thekeytechnology/framework-react";
import {FieldProps} from "formik";
import React, {Component} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import Select from "react-select";
import {TrainerData} from "../../../../courses/model/trainer/trainer-data";
import {ClearIndicator} from "../../../../entity/components/ClearIndicator";
import {connect} from "react-redux";
import "./trainer-select-field.scss";

interface StateProps {
    trainers: Array<EntityWrapper<TrainerData>>;
}

interface DispatchProps {
    fetchTrainers: ReturnType<typeof reloadEntityListAction>
    setPaginationQuery: ReturnType<typeof setPaginationQueryAction>
}

type OwnProps = FieldProps;

type Props = StateProps & OwnProps & DispatchProps & WithTranslation;

class TrainerSelectFieldComponent extends Component<Props> {

    public constructor(props: Props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    public componentDidMount(): void {
        this.props.fetchTrainers();
    }

    public onChange(trainer: EntityWrapper<TrainerData>) {
        this.props.form.setFieldValue(this.props.field.name, trainer);
    }

    public render() {
        const {trainers, field, t} = this.props;

        return (
            <>
                <Select
                    className="react-select trainer-select-field"
                    classNamePrefix="react-select"
                    placeholder={t("coaching-offers.edit.fields.trainer-placeholder")}
                    components={{ClearIndicator}}
                    isClearable={true}
                    options={trainers as any}
                    name={field.name}
                    value={trainers ?
                        trainers.find((option: any) => field.value && (field.value.id === option.id)) as any : null}
                    onChange={this.onChange as any}
                    getOptionValue={opt => opt.id as any}
                    getOptionLabel={(item: EntityWrapper<TrainerData>) => item.entity.name}
                    onBlur={field.onBlur}
                />
            </>
        );
    }
}

export const TrainerSelectField = connect<StateProps, DispatchProps, OwnProps>(
    (state: any) => {
        return {
            trainers: selectEntities<TrainerData>(TrainerData.TYPE)(state)
        };
    },
    {
        fetchTrainers: reloadEntityListAction(TrainerData.TYPE),
        setPaginationQuery: setPaginationQueryAction(TrainerData.TYPE)
    }
)(withTranslation("coaching")(TrainerSelectFieldComponent as any));
