import { ApiCallState, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { activationAttemptAction, API_ACTIVATION } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { BaseAccountScreen } from "../common/BaseAccountScreen";

interface StateProps {
    apiCallState?: ApiCallState;
}

interface DispatchProps {
    activate: (token: string) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class ActivationScreenComponent extends React.Component<Props> {

    public componentDidMount(): void {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get("token");
        if (token) {
            this.props.activate(token);
        }
    }

    public render() {
        const {t, apiCallState} = this.props;

        return (
            <BaseAccountScreen>
                <h1 className="text-white text-center mb-5">{t("Activating your account")}</h1>

                {apiCallState && apiCallState.inProgress ?
                    <div className="d-flex justify-content-center">
                        <i className="fa fa-spinner fa-spin fa-3x fa-fw"/>
                    </div> : null
                }

                {apiCallState && apiCallState.succeeded ?
                    <div className="text-center paragraph mb-3">
                        Your account has been successfully activated. <NavLink
                        className="mb-5"
                        to="/login">{t("Log in here!")}</NavLink>
                    </div> : null
                }
            </BaseAccountScreen>
        );
    }
}

export const AccountActivation = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        apiCallState: selectCombinedApiState(API_ACTIVATION)(state)
    }),
    {activate: activationAttemptAction}
)(withTranslation()(ActivationScreenComponent));
