import { FieldProps } from "formik";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Select from "react-select";

import { ClearIndicator } from "../../../../entity/components/ClearIndicator";

const TrainerTypeFieldComponent = (props: FieldProps & WithTranslation) => {
    const {field, form, t} = props;
    const trainerTypeOptions = [
        {
            value: "internal",
            label: t("trainer-type.internal")
        },
        {
            value: "external",
            label: t("trainer-type.external")
        }
    ];
    return (<Select
        className="react-select"
        classNamePrefix="react-select"
        components={{ClearIndicator}}
        options={trainerTypeOptions}
        name={field.name}
        value={field.value ? trainerTypeOptions.find(o => o.value === field.value) : null}
        onChange={(item: any) => form.setFieldValue(field.name, item ? item.value : null)}
        getOptionValue={opt => opt.value}
        getOptionLabel={item => item.label}
        onBlur={field.onBlur}
    />);
};

export const TrainerTypeField = withTranslation("courses")(TrainerTypeFieldComponent);
