import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    Pagination as PG,
    PaginationQuery,
    selectPagination,
    setPaginationQueryAction,
} from "@thekeytechnology/framework-react";
import React from "react";
import {connect} from "react-redux";
import "./pagination.scss";

interface PaginationProps {
    entityType: string;
}

interface StateProps {
    pagination: PG;
}

interface DispatchProps {
    setPage: ReturnType<typeof setPaginationQueryAction>
}

type Props = PaginationProps & StateProps & DispatchProps;

const Pagination = (props: Props) => {
    const {pagination, setPage} = props;
    if (!pagination || pagination.totalPages <= 1) {
        return <></>;
    }

    const BackArrow = pagination.currentPage > 0 ?
        (<li key="back" onClick={() => setPage({
            page: pagination.currentPage - 1,
            docsPerPage: pagination.docsPerPage
        })}>
            <FontAwesomeIcon icon={faChevronLeft}/>
        </li>)
        : (<li key="back" className="disabled">
            <FontAwesomeIcon icon={faChevronLeft}/>
        </li>);

    const ForwardArrow = pagination.currentPage + 1 < pagination.totalPages ?
        (<li key="forward" onClick={() => setPage({
            page: pagination.currentPage + 1,
            docsPerPage: pagination.docsPerPage
        })}>
            <FontAwesomeIcon icon={faChevronRight}/>
        </li>)
        : (<li key="forward" className="disabled">
            <FontAwesomeIcon icon={faChevronRight}/>
        </li>);

    return (
        <ul className="pagination mb-0 d-flex justify-content-center">
            {BackArrow}
            {[...Array(pagination.totalPages).fill("")].map((x, i) =>
                <li key={i}
                    onClick={() => setPage({
                        page: i,
                        docsPerPage: pagination.docsPerPage
                    })}
                    className={i === pagination.currentPage ? "active" : ""}>
                    {i + 1}
                </li>
            )}
            {ForwardArrow}
        </ul>
    );
};

export default connect<StateProps, DispatchProps, PaginationProps>(
    (state: any, ownProps: PaginationProps) => {
        return {
            pagination: selectPagination(ownProps.entityType)(state)
        };
    },
    (dispatch, ownProps: PaginationProps) => {
        // noinspection JSUnusedGlobalSymbols
        return {
            setPage: (paginationQuery: PaginationQuery) => dispatch(setPaginationQueryAction(ownProps.entityType)(paginationQuery)),
        };
    }
)(Pagination);
