import {ApiCallAction, apiPost, FilterTerm, PropertyFilter, selectCurrentUser} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import {AppState} from "../../reducers";
import {API_MY_COURSE_GROUPS} from "../actions/fetch-my-course-groups";
import {API_MY_COURSES} from "../actions/fetch-my-courses";

const myCourses$ = apiPost<string, any>(
    {apiType: API_MY_COURSES},
    "/courses/by-participants",
    (action: ApiCallAction<void>, state: AppState) => {
        return {
            filters: [
                new PropertyFilter("entity.dateTime", new FilterTerm(FilterTerm.TYPE_DATE_TIME, FilterTerm.OPERATION_GTE, new Date().toISOString()))
            ],
            participantEmails: [(selectCurrentUser(state))?.entity.email]
        };
    }
);

const myCourseGroups$ = apiPost<string, any>(
    {apiType: API_MY_COURSE_GROUPS},
    "/course-groups/by-participant",
    (action: ApiCallAction<void>, state: AppState) => {
        return {
            participantEmail: selectCurrentUser(state)?.entity.email
        };
    }
);

export const myCoursesEpic$ = combineEpics(
    myCourses$ as any,
    myCourseGroups$ as any
);
