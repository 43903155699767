import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";

import "./attachment-display.scss";

interface AttachmentDisplayProps extends WithTranslation {
    item: EntityWrapper<TkFile>
    remove: (item: EntityWrapper<TkFile>) => void;
}

export const AttachmentDisplayComponent = (props: AttachmentDisplayProps) => {
    const {item, remove, t} = props;
    return <li className="d-flex list-group-item attachment-display">
        <div className="mr-2">
            {item.entity.thumbnailUrl ? <img alt="" src={item.entity.thumbnailUrl}/> : <>{t("No preview available")}</>}
        </div>
        <div className="title">{item.entity.name}&nbsp;</div>
        <div className="cross ml-auto"><a href="/" onClick={e => {
            remove(props.item);
            e.preventDefault();
            return false;
        }}><FontAwesomeIcon icon={faTimes}/></a></div>
    </li>;
};

export const AttachmentDisplay = withTranslation()(AttachmentDisplayComponent);
