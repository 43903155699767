import {
    Account,
    API_FETCH_SUBSCRIPTION_STATUS,
    DontShowToCertainAccounts,
    fetchSubscriptionStatusAction,
    selectCurrentAccount,
    selectSubscriptionStatus,
    ShowWhenApiSucceeded,
    SubscriptionStatus,
    UserWithRoles
} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router";
import {OnlyShowToRoles} from "../../core/components/navigation/OnlyShowToRoles";
import {NavLink} from "react-router-dom"

interface StateProps {
    activeSubscription?: SubscriptionStatus;
    account?: Account;
}

interface DispatchProps {
    fetchActiveSubscription: () => void;
}

type Props = StateProps & DispatchProps & WithTranslation & RouteComponentProps

class SubscriptionWarningComponent extends React.Component<Props> {
    public componentDidMount(): void {
        if (this.props.account) {
            this.props.fetchActiveSubscription();
        }
    }

    public render() {
        const {activeSubscription, t, location} = this.props;

        const {pathname} = location;

        if (pathname === "/billing") {
            return null;
        }

        return (
            <DontShowToCertainAccounts dontShowToAccountNames={["babbel"]}>
                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN]}>
                    <ShowWhenApiSucceeded apiQualifier={API_FETCH_SUBSCRIPTION_STATUS}>
                        {(!activeSubscription || activeSubscription.status !== SubscriptionStatus.STATUS_ACTIVE) ?
                            <div className="alert alert-warning d-flex align-items-center">
                                <div className="mr-auto">{t("no-valid-subscription")}</div>
                                <div className="pl-3">
                                    <NavLink to="/billing">
                                        <button className="btn btn-primary" type="button">{t("to-billing")}</button>
                                    </NavLink>
                                </div>
                            </div>
                            : null}
                    </ShowWhenApiSucceeded>
                </OnlyShowToRoles>
            </DontShowToCertainAccounts>
        );
    }
}

export const SubscriptionWarning = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            activeSubscription: selectSubscriptionStatus(state),
            account: selectCurrentAccount(state)
        };
    },
    {
        fetchActiveSubscription: fetchSubscriptionStatusAction,
    }
)(withTranslation("billing")(withRouter(SubscriptionWarningComponent)));
