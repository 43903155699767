import { generateString } from "@thekeytechnology/framework-react";
import React from "react";
import { NavLink } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

interface TooltipLinkProps {
    to: string;
    tooltip: string;
    children: React.ReactNode
}

export const TooltipLink = (props: TooltipLinkProps) => {
    const id = "tooltip-link-" + generateString(10);
    return <>
        <NavLink className="action" id={id} to={props.to}>
            {props.children}
            <div className="lg-and-above-hide">{props.tooltip}</div>
        </NavLink>
        <UncontrolledTooltip placement="top" target={id}>
            {props.tooltip}
        </UncontrolledTooltip>
    </>;
};
