import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    HeadingContainer,
    ContentContainer,
    PaginationContainer
} from "../../../core/components/containers/Container";
import { SimpleHeader } from "../../../core/components/header/SimpleHeader";
import Pagination from "../../../core/components/pagination/Pagination";
import { Team } from "../../model/Team";
import { TeamsTable } from "./table/TeamsTable";

const TeamsOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/users/teams"}>{t("teams.breadcrumb")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("teams.heading")}/>
            </HeadingContainer>
            <ContentContainer>
                <TeamsTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={Team.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const TeamsOverview = withTranslation("users")(TeamsOverviewComponent);
