import { EntityWrapper } from "@thekeytechnology/framework-react";
import { TrainerData } from "../trainer/trainer-data";
import { Participant } from "../participant";
import { CourseDefinition } from "../course-definition/course-definition.model";
import { CourseCategory } from "../course-category";

export class CourseWithMeta {
    public static TYPE = "course";

    constructor(public name: string,
                public courseDefinition: EntityWrapper<CourseDefinition>,
                public dateTime: string,
                public durationInMinutes: number,
                public participants: Array<EntityWrapper<Participant>>,
                public capacity: number,
                public category: EntityWrapper<CourseCategory>,
                public trainer: EntityWrapper<TrainerData>,
                public isCancelled: boolean,
                public status: string) {
    }
}
