import React from "react";
import {withTranslation} from "react-i18next";
import {Filters} from "../../../core/components/filters/Filters";
import DateTimeRangeFilter from "../../../entity/components/DateTimeRangeFilter";
import {Transaction} from "../../model/Transaction";

const TransactionFiltersComponent = () => {
    return (
        <Filters
            entityType={Transaction.TYPE}
            filterKeys={["entity.dateTime"]}>
            <DateTimeRangeFilter
                entityType={Transaction.TYPE}
                property="entity.dateTime"/>
        </Filters>
    );
};

export const TransactionFilters = withTranslation("transactionlog")(TransactionFiltersComponent);
