import {ApiCallState, EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import React, {Component} from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {ContentContainer, HeadingContainer} from "../../../../core/components/containers/Container";
import {API_MY_COURSES, fetchMyCoursesAction} from "../../../actions/fetch-my-courses";
import {CourseWithMeta} from "../../../model/course/course-with-meta";
import {selectMyCourses} from "../../../selectors";
import {EnrollTable} from "./table/EnrollTable";
import {connect} from "react-redux";
import "./course-overview.scss";

interface DispatchProps {
    fetchMyCourses: () => void;
}

interface StateProps {
    entities: Array<EntityWrapper<CourseWithMeta>>;
    entityApiState: ApiCallState;
}

type Props = StateProps & DispatchProps & WithTranslation;

class MyCoursesComponent extends Component<Props> {
    public componentDidMount(): void {
        this.props.fetchMyCourses();
    }

    public render() {
        const {t, entities, entityApiState} = this.props;

        return (
            <>
                <BreadcrumbsItem to="/my-courses">{t("my-courses")}</BreadcrumbsItem>
                <HeadingContainer>
                    <h1>{t("my-courses")}</h1>
                </HeadingContainer>
                <ContentContainer>
                    <EnrollTable entities={entities} entityApiState={entityApiState}/>
                </ContentContainer>
            </>
        );
    }
}

export const MyCourses = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            entities: selectMyCourses(state as never),
            entityApiState: selectCombinedApiState(API_MY_COURSES)(state),
        };
    },
    {
        fetchMyCourses: fetchMyCoursesAction
    }
)(withTranslation("courses")(MyCoursesComponent));
