import { faEnvelope, faHeading, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { ApiCallState, selectCombinedApiState } from "@thekeytechnology/framework-react";
import { API_REGISTRATION, registrationAttemptAction } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ValidatedField } from "../../../core/components/form/ValidatedField";
import { NavLink } from "react-router-dom";
import * as Yup from "yup";
import { BaseAccountScreen } from "../common/BaseAccountScreen";

interface StateProps {
    apiCallState: ApiCallState;
}

interface DispatchProps {
    register: (name: string, email: string, password: string, accountName: string) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class RegistrationScreen extends React.Component<Props> {
    public render() {
        const {t, apiCallState, register} = this.props;

        return (
            <BaseAccountScreen>
                <h1 className="text-white text-center mb-5">Create new account</h1>

                {apiCallState.succeeded ?
                    <>
                        <div className="text-center paragraph mb-3">
                            Thank you for your registration! Check your email for a confirmation link to activate
                            your account.<br/>
                            <NavLink
                                className="mb-5"
                                to="/login">{t("To login")}</NavLink>
                        </div>
                    </> :
                    <Formik
                        initialValues={{email: "", password: "", name: "", accountName: ""}}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required(),
                            email: Yup.string().email().required(),
                            password: Yup.string().min(8).required(),
                            accountName: Yup.string().matches(new RegExp("^[a-zA-Z ]{6,}$")).required(),
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            register(values.name, values.email, values.password, values.accountName);
                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting, errors, touched}) => (
                            <Form>
                                <div className="form-group">
                                    <ValidatedField errors={errors} touched={touched} icon={faUser} type="name"
                                                    placeholder={t("Your name")} name="name"
                                                    className="form-control default-input" required/>
                                </div>
                                <div className="form-group">
                                    <ValidatedField errors={errors} touched={touched} icon={faEnvelope} type="email"
                                                    placeholder={t("E-Mail")} name="email"
                                                    className="form-control default-input" required/>
                                </div>
                                <div className="form-group">
                                    <ValidatedField errors={errors} touched={touched} icon={faLock}
                                                    type="password" placeholder={t("Password")} name="password"
                                                    className="form-control default-input" required/>
                                </div>
                                <div className="form-group">
                                    <ValidatedField errors={errors} touched={touched} type="name" icon={faHeading}
                                                    placeholder={t("Account name, e.g. company name")}
                                                    name="accountName"
                                                    className="form-control default-input" required/>
                                </div>
                                <div className="form-group d-flex mb-3">
                                    <button type="submit" disabled={isSubmitting || apiCallState.inProgress}
                                            className="btn btn-primary align-self-end w-100">
                                        {t("Register")}
                                    </button>
                                </div>
                                <div className="text-center paragraph mb-3">
                                    {t("Already have an account?")} <NavLink
                                    className=" mb-5"
                                    to="/login">{t("Login here!")}</NavLink>
                                </div>
                                <div>
                                    {apiCallState.inProgress &&
                                    <div className="d-flex justify-content-center">
                                        <i className="fa fa-spinner fa-spin fa-3x fa-fw"/>
                                    </div>
                                    }
                                </div>
                            </Form>
                        )}
                    </Formik>
                }
            </BaseAccountScreen>
        );
    }
}

export default connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        apiCallState: selectCombinedApiState(API_REGISTRATION)(state)
    }),
    {
        register: registrationAttemptAction
    }
)(withTranslation()(RegistrationScreen));
