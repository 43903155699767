import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { EntityWrapper} from "@thekeytechnology/framework-react";
import { Field, Form, Formik } from "formik";
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { SaveAndBackButtons } from "../../../../core/components/buttons/SaveAndBackButtons";
import { ContentContainer } from "../../../../core/components/containers/Container";
import { DateTimeDisplay } from "../../../../core/components/display/DateTimeDisplay";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { SimpleEditHeader } from "../../../../core/components/header/LoadingEditHeader";
import { CourseForSaving } from "../../../model/course/course-for-saving";
import { CourseWithMeta } from "../../../model/course/course-with-meta";
import { NavLink } from "react-router-dom";
import CategorySelectField from "../../common/fields/CategorySelectField";
import { EditHeaderWithBackground } from "../common/edit/EditHeaderWithBackground";
import { DateTimeField } from "../../../../core/components/datetime/DateTimeField";
import TrainerSelectField from "../../common/fields/TrainerSelectField";
import * as Yup from "yup";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";

type Props = WithSingleEntityFromPathProps<CourseWithMeta, CourseForSaving> & WithTranslation;

const EditCourse = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return (
        <>
            <BreadcrumbsItem to={"/courses/"}>{t("Courses")}</BreadcrumbsItem>
            {entity ?
                <BreadcrumbsItem to={"/courses/" + entity.id}>{entity.entity.name}&nbsp;-&nbsp;
                    {<DateTimeDisplay dateTime={entity.entity.dateTime}/>}</BreadcrumbsItem> : null}

            <SimpleEditHeader entity={entity} entityTypeName={t("course")}/>

            {entity ?
                <Formik
                    initialValues={{
                        dateTime: entity.entity.dateTime,
                        durationInMinutes: entity.entity.durationInMinutes,
                        capacity: entity.entity.capacity,
                        category: entity.entity.category,
                        trainer: entity.entity.trainer
                    }}
                    validationSchema={Yup.object().shape({
                        dateTime: Yup.string().nullable().required(t("Date / Time") + " " + t("translation:is a required field")),
                        durationInMinutes: Yup.number().positive().required(t("Duration") + " " + t("translation:is a required field")),
                        capacity: Yup.number().positive().required(t("Capacity") + " " + t("translation:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const courseForSaving = new EntityWrapper(entity.id,
                            new CourseForSaving(
                                entity.entity.name,
                                entity.entity.courseDefinition.id as string,
                                values.dateTime,
                                values.durationInMinutes,
                                values.capacity,
                                values.category ? values.category.id : undefined,
                                values.trainer ? values.trainer.entity.userIdRef : undefined,
                                entity.entity.isCancelled,
                                entity.entity.status
                            )
                        );
                        upsertEntity(courseForSaving);
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={entity.entity.name}
                                leadText={<>{t("Edit the definition of the course")}&nbsp;
                                    <NavLink
                                        to={"/course-definitions/" + entity.entity.courseDefinition.id + "/edit"}>
                                        {t("here")}
                                    </NavLink></>}>
                                <SaveAndBackButtons isSubmitting={isSubmitting} entity={entity} backPath="/courses"/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                <div className="form-group row">
                                    <ValidatedField icon={faUsers} label={t("Capacity")} name="capacity" errors={errors}
                                                    touched={touched} type="number"
                                                    placeholder={t("Enter course capacity")} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="capacity"
                                           className="col-sm-2 col-form-label">{t("Category")}</label>
                                    <div className="col-sm-10">
                                        <Field name="category" component={CategorySelectField}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="capacity" className="col-sm-2 col-form-label">{t("Trainer")}</label>
                                    <div className="col-sm-10">
                                        <Field name="trainer" component={TrainerSelectField}/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <ValidatedField label={t("Date / Time")} name="dateTime" errors={errors}
                                                    touched={touched} component={DateTimeField} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField label={t("Duration")} name="durationInMinutes" errors={errors}
                                                    touched={touched} type="number"
                                                    placeholder={t("Enter course duration in minutes")} required
                                    />
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik> : null}
        </>
    );
};

export default WithSingleEntityFromPath<{}, CourseWithMeta, CourseForSaving>(
    withTranslation(["courses"])(EditCourse), CourseWithMeta.TYPE, "courseId");
