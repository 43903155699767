import { selectPagination, Pagination } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./pagination.scss";
import "./pagination-info.scss";

interface PaginationInfoProps {
    entityType: string;
    className?: string;
}

interface StateProps {
    pagination: Pagination;
}

type Props = WithTranslation & StateProps & PaginationInfoProps;

const PaginationInfo = (props: Props) => {
    const {pagination, className, t} = props;
    if (!pagination || pagination.totalDocs === 0) {
        return <div className="pagination-info">&nbsp;</div>;
    }
    const currentRangeStart = pagination.docsPerPage * pagination.currentPage;
    const currentRangeEnd = pagination.currentPage + 1 === pagination.totalPages ?
        pagination.totalDocs : currentRangeStart + pagination.docsPerPage;
    return (
        <div className={"pagination-info " + (className ? className : "")}>
            {/* tslint:disable-next-line:max-line-length */}
            {t("Page")} {pagination.currentPage + 1} {t("of")} {pagination.totalPages} ({currentRangeStart + 1} - {currentRangeEnd} / {pagination.totalDocs})
        </div>
    );
};

export default connect<StateProps, {}, PaginationInfoProps>(
    (state: any, ownProps: PaginationInfoProps) => {
        return {
            pagination: selectPagination(ownProps.entityType)(state)
        };
    }
)(withTranslation()(PaginationInfo));
