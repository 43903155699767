import { faCrown, faHeading, faUsers } from "@fortawesome/free-solid-svg-icons";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { SaveAndBackButtons } from "../../../../core/components/buttons/SaveAndBackButtons";
import { ContentContainer } from "../../../../core/components/containers/Container";
import { ValidatedField } from "../../../../core/components/form/ValidatedField";
import { SimpleEditHeader } from "../../../../core/components/header/LoadingEditHeader";
import { EditHeaderWithBackground } from "../../../../courses/components/course/common/edit/EditHeaderWithBackground";
import { Team } from "../../../model/Team";
import { TeamForSaving } from "../../../model/TeamForSaving";
import * as Yup from "yup";
import { UserField } from "./UserField";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";

type Props = WithSingleEntityFromPathProps<Team, TeamForSaving> & WithTranslation;

const EditTeamComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return (
        <>
            <BreadcrumbsItem to={"/users/teams"}>{t("teams.breadcrumb")}</BreadcrumbsItem>
            {entity ?
                <BreadcrumbsItem
                    to={"/users/teams/" + entity.id}>
                    {entity.entity.name ? entity.entity.name : "New team"}
                </BreadcrumbsItem> : null}

            <SimpleEditHeader entity={entity} entityTypeName={t("team")}/>
            {entity ?
                <Formik
                    initialValues={{
                        name: entity.entity.name,
                        leaders: entity.entity.leaders,
                        members: entity.entity.members,
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required(t("Name") + " " + t("translation:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const saving = new EntityWrapper(entity.id,
                            new TeamForSaving(
                                values.name,
                                values.leaders.map(leader => leader.id as string),
                                values.members.map(member => member.id as string),
                            )
                        );
                        upsertEntity(saving);
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={
                                    entity.id ?
                                        entity.entity.name :
                                        t("translation:New") + " " + t("team")
                                }
                            >
                                <SaveAndBackButtons isSubmitting={isSubmitting} entity={entity}
                                                    backPath="/users/teams"/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                <div className="form-group row">
                                    <ValidatedField icon={faHeading} label={t("Name")} name="name" errors={errors}
                                                    touched={touched} type="text" placeholder={t("Enter name")}
                                                    required
                                    />
                                </div>

                                <h3>{t("teams.edit.members")}</h3>

                                <div className="form-group row ">
                                    <ValidatedField icon={faCrown}
                                                    label={t("teams.edit.leaders")}
                                                    component={UserField}
                                                    name="leaders"
                                                    errors={errors} touched={touched}
                                    />
                                </div>

                                <div className="form-group row ">
                                    <ValidatedField icon={faUsers}
                                                    label={t("teams.edit.members")}
                                                    component={UserField}
                                                    name="members"
                                                    errors={errors} touched={touched}
                                    />
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik> : null}
        </>
    );
};

export const EditTeam = WithSingleEntityFromPath<{}, Team, TeamForSaving>(
    withTranslation("users")(EditTeamComponent), Team.TYPE,
    "teamId", Team.new);
