import { ApiCallState, EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { LoadingRow } from "../../core/components/table/LoadingRow";
import { AsyncContent } from "../../core/components/table/LoadingTableContent";
import { NoDataRow } from "../../core/components/table/NoDataRow";
import { TableBody } from "../../core/components/table/Table";

export interface AsyncEntityTableContentProps<T> {
    apiCallState: ApiCallState;
    items: Array<EntityWrapper<T>>,
    renderItem: (item: EntityWrapper<T>) => any;
}

export function AsyncEntityTableBody<T>(props: AsyncEntityTableContentProps<T>) {
    const {apiCallState, items, renderItem} = props;
    return (
        <TableBody>
            <AsyncContent
                loadingComponent={() => <LoadingRow/>}
                successComponent={
                    () => items.length === 0 ? <NoDataRow/> :
                        items.map(item => {
                            return (
                                renderItem(item)
                            );
                        })
                }
                apiCallState={apiCallState}/>
        </TableBody>
    );
}
