import { EntityWrapper, UserWithRoles } from "@thekeytechnology/framework-react";

export class Transaction {
    public static TYPE = "transaction"
    public constructor(public dateTime: string,
                       public transactionType: string,
                       public user?: EntityWrapper<UserWithRoles>,
                       public cost?: number,
                       public entityType?: string,
                       public entityIdRef?: string,
                       public relatedEntityName?: string,
                       public comment?: string) {
    }
}
