import { selectCurrentUser, UserWithRoles } from "@thekeytechnology/framework-react";
import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { enrollAction, unEnrollAction } from "../../../actions/enroll";
import { CourseWithMeta } from "../../../model/course/course-with-meta";
import { connect } from "react-redux";

export interface OwnProps {
    course: EntityWrapper<CourseWithMeta>;
    className?: string;
}

interface StateProps {
    user?: EntityWrapper<UserWithRoles>;
}

interface DispatchProps {
    enroll: (courseId: string, onWaitingList: boolean) => void;
    unenroll: (courseId: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps & WithTranslation;

const SignupButtonComponent = (props: Props) => {
    const {course, enroll, unenroll, user, className, t} = props;

    if (!user) {
        return null;
    }

    const currentUserInCourse = course.entity.participants.find(p => p.entity.email === user.entity.email);
    if (currentUserInCourse) {
        if (currentUserInCourse.entity.onWaitingList) {
            return <button onClick={() => unenroll(course.id as string)}
                           className={className + " btn btn-secondary w-100"}>
                {t("signup-button.remove-from-waiting-list")}
            </button>;
        } else {
            return <button onClick={() => unenroll(course.id as string)}
                           className={className + " btn btn-secondary w-100"}>
                {t("signup-button.sign-out")}
            </button>;
        }
    } else {
        if (course.entity.capacity === 0 || course.entity.participants.filter(p => !p.entity.onWaitingList).length === course.entity.capacity) {
            return <button onClick={() => enroll(course.id as string, true)}
                           className={className + " btn btn-outline-success w-100"}>
                {t("signup-button.add-to-waiting-list")}
            </button>;
        } else {
            return <button onClick={() => enroll(course.id as string, false)}
                           className={className + " btn btn-success w-100"}>
                {t("signup-button.enroll")}
            </button>;
        }
    }
};

export const SignupButton = connect<StateProps, DispatchProps, OwnProps>(
    state => ({
        user: selectCurrentUser(state),
    }),
    {
        enroll: enrollAction,
        unenroll: unEnrollAction
    }
)(withTranslation("courses")(SignupButtonComponent) as any);
