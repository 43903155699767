import {
    ApiCallAction,
    ApiCallSucceededAction,
    matchesApiSuccess,
    fetchSingleEntityAction,
    apiCallSucceededAction,
    defaultApiErrorHandling,
    selectAuthState, withAuthedAxios, matchesApiCall, selectApiBase
} from "@thekeytechnology/framework-react";
import { Action } from "redux";
import { combineEpics, StateObservable } from "redux-observable";
import { from, Observable } from "rxjs";
import { filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import {
    AddParticipantsPayload,
    API_ADD_PARTICIPANTS,
    ParticipantsAddedPayload
} from "../actions/add-participant";
import {
    API_CHANGE_PARTICIPANT_STATUS,
    ChangeParticipantStatusPayload, ParticipantStatusChangedPayload
} from "../actions/change-participant-status";
import {
    API_REMOVE_PARTICIPANTS, ParticipantsRemovedPayload,
    RemoveParticipantPayload
} from "../actions/remove-participants";
import { CourseGroupWithMeta } from "../model/course-group/course-group-with-meta";
import { CourseWithMeta } from "../model/course/course-with-meta";

const addParticipants$ = (action$: Observable<Action>, state$: StateObservable<any>) =>
    action$.pipe(
        matchesApiCall(API_ADD_PARTICIPANTS),
        withLatestFrom(state$),
        mergeMap(([action, state]: [ApiCallAction<AddParticipantsPayload>, any]) =>
            from(withAuthedAxios(selectApiBase(state), selectAuthState(state)).post(`/participants/participant-sign-up`, {
                parentEntityId: action.payload.parentEntityId,
                parentEntityType: action.payload.parentEntityType,
                participants: action.payload.participants
            }))
                .pipe(
                    map(() => {
                        return apiCallSucceededAction(action.apiQualifier,
                            {
                                parentEntityType: action.payload.parentEntityType,
                                parentEntityId: action.payload.parentEntityId
                            } as ParticipantsAddedPayload);
                    }),
                    defaultApiErrorHandling(action.apiQualifier)
                )
        )
    );

const changeParticipantStatus$ = (action$: Observable<Action>, state$: StateObservable<any>) =>
    action$.pipe(
        matchesApiCall(API_CHANGE_PARTICIPANT_STATUS),
        withLatestFrom(state$),
        mergeMap(([action, state]: [ApiCallAction<ChangeParticipantStatusPayload>, any]) =>
            from(withAuthedAxios(selectApiBase(state), selectAuthState(state)).post(`/participants/participant-change-status`, {
                courseId: action.payload.courseId,
                participants: [action.payload.email],
                status: action.payload.status
            }))
                .pipe(
                    map(() => {
                        return apiCallSucceededAction(action.apiQualifier, {
                            courseId: action.payload.courseId
                        } as ParticipantStatusChangedPayload);
                    }),
                    defaultApiErrorHandling(action.apiQualifier)
                )
        )
    );

const removeParticipant$ = (action$: Observable<Action>, state$: StateObservable<any>) =>
    action$.pipe(
        matchesApiCall(API_REMOVE_PARTICIPANTS),
        withLatestFrom(state$),
        mergeMap(([action, state]: [ApiCallAction<RemoveParticipantPayload>, any]) =>
            from(withAuthedAxios(selectApiBase(state), selectAuthState(state)).post(`/participants/participant-sign-out`, {
                parentEntityId: action.payload.parentEntityId,
                parentEntityType: action.payload.parentEntityType,
                participantEmails: action.payload.participantEmails
            }))
                .pipe(
                    map(() => {
                        return apiCallSucceededAction(action.apiQualifier,
                            {
                                parentEntityType: action.payload.parentEntityType,
                                parentEntityId: action.payload.parentEntityId
                            } as ParticipantsRemovedPayload
                        );
                    }),
                    defaultApiErrorHandling(action.apiQualifier)
                )
        )
    );

const reloadCourseAfterParticipantUpdate$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_CHANGE_PARTICIPANT_STATUS),
    map((action: ApiCallSucceededAction<ParticipantStatusChangedPayload>) =>
        fetchSingleEntityAction(CourseWithMeta.TYPE)(action.payload.courseId))
);

const reloadCourseAfterParticipantRemoval$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_REMOVE_PARTICIPANTS),
    filter((action: ApiCallSucceededAction<ParticipantsRemovedPayload>) =>
        action.payload.parentEntityType === CourseWithMeta.TYPE),
    map((action: ApiCallSucceededAction<ParticipantsRemovedPayload>) =>
        fetchSingleEntityAction(CourseWithMeta.TYPE)(action.payload.parentEntityId))
);

const reloadCourseAfterParticipantAddition$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_ADD_PARTICIPANTS),
    filter((action: ApiCallSucceededAction<ParticipantsAddedPayload>) =>
        action.payload.parentEntityType === CourseWithMeta.TYPE),
    map((action: ApiCallSucceededAction<ParticipantsAddedPayload>) =>
        fetchSingleEntityAction(CourseWithMeta.TYPE)(action.payload.parentEntityId))
);

const reloadCourseGroupAfterParticipantRemoval$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_REMOVE_PARTICIPANTS),
    filter((action: ApiCallSucceededAction<ParticipantsRemovedPayload>) =>
        action.payload.parentEntityType === CourseGroupWithMeta.TYPE),
    map((action: ApiCallSucceededAction<ParticipantsRemovedPayload>) =>
        fetchSingleEntityAction(CourseGroupWithMeta.TYPE)(action.payload.parentEntityId))
);

const reloadCourseGroupAfterParticipantAddition$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_ADD_PARTICIPANTS),
    filter((action: ApiCallSucceededAction<ParticipantsAddedPayload>) =>
        action.payload.parentEntityType === CourseGroupWithMeta.TYPE),
    map((action: ApiCallSucceededAction<ParticipantsAddedPayload>) =>
        fetchSingleEntityAction(CourseGroupWithMeta.TYPE)(action.payload.parentEntityId))
);

export const participantEpics$ = combineEpics(
    changeParticipantStatus$ as any,
    removeParticipant$ as any,
    addParticipants$ as any,
    reloadCourseAfterParticipantUpdate$ as any,
    reloadCourseAfterParticipantRemoval$ as any,
    reloadCourseAfterParticipantAddition$ as any,
    reloadCourseGroupAfterParticipantAddition$ as any,
    reloadCourseGroupAfterParticipantRemoval$ as any,
);
