export class EmailConfiguration {
    public static TYPE = "email-configuration";

    constructor(public key: string,
                public subjects: { [lang: string]: string },
                public bodies: { [lang: string]: string },
                public previewTexts: { [lang: string]: string },
                public variables: string[]) {
    }
}
