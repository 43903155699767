import {
    faArchive,
    faBook,
    faChalkboardTeacher,
    faChevronLeft,
    faChevronRight,
    faEnvelope,
    faEuroSign,
    faFile,
    faGraduationCap,
    faHome,
    faLayerGroup,
    faList,
    faPaintBrush,
    faThLarge,
    faTools,
    faUser,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DontShowToCertainAccounts, UserWithRoles} from "@thekeytechnology/framework-react";
import React, {Component} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

import "./menu.scss";
import {TrainerData} from "../../../courses/model/trainer/trainer-data";
import { OnlyShowToRoles } from "../../../core/components/navigation/OnlyShowToRoles";

interface State {
    adminMenuOpen: boolean;
}

type Props = WithTranslation;

class MenuComponent extends Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props, state);
        this.state = {
            adminMenuOpen: false
        };
    }

    public render() {
        const {t} = this.props;
        const {adminMenuOpen} = this.state;

        if (adminMenuOpen) {
            return <ul className="menu">
                <li className="menu-link" onClick={() => this.setState({adminMenuOpen: false})}>
                    <span><FontAwesomeIcon className="separator" icon={faChevronLeft}/>{t("back")}</span>
                </li>
                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}>
                    <NavLink to="/courses" exact activeClassName="active">
                        <li>
                            <span><FontAwesomeIcon className="separator" icon={faList}/>{t("courses")}</span>
                        </li>
                    </NavLink>
                </OnlyShowToRoles>
                <ul className="submenu">
                    <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}>
                        <NavLink to="/course-definitions" exact activeClassName="active">
                            <li><span><FontAwesomeIcon className="separator"
                                                       icon={faPaintBrush}/>{t("course-definitions")}</span>
                            </li>
                        </NavLink>
                    </OnlyShowToRoles>
                    <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN]}>
                        <NavLink to="/course-categories" exact activeClassName="active">
                            <li><span><FontAwesomeIcon className="separator"
                                                       icon={faThLarge}/>{t("course-categories")}</span>
                            </li>
                        </NavLink>
                    </OnlyShowToRoles>
                    <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN]}>
                        <NavLink to="/course-groups" exact activeClassName="active">
                            <li><span><FontAwesomeIcon className="separator"
                                                       icon={faLayerGroup}/>{t("course-groups")}</span>
                            </li>
                        </NavLink>
                    </OnlyShowToRoles>
                </ul>
                <DontShowToCertainAccounts dontShowToAccountNames={["babbel"]}>
                    <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}>
                        <NavLink to="/coaching-offers" exact activeClassName="active">
                            <li>
                                <span><FontAwesomeIcon className="separator"
                                                       icon={faBook}/>{t("coaching-offers")}</span>
                            </li>
                        </NavLink>
                    </OnlyShowToRoles>
                </DontShowToCertainAccounts>
                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}>
                    <NavLink to="/trainers" exact activeClassName="active">
                        <li>
                                <span><FontAwesomeIcon className="separator"
                                                       icon={faGraduationCap}/>{t("trainers")}</span>
                        </li>
                    </NavLink>
                </OnlyShowToRoles>
                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN]}>
                    <NavLink to="/emails" exact activeClassName="active">
                        <li>
                            <span><FontAwesomeIcon className="separator" icon={faEnvelope}/>{t("emails")}</span>
                        </li>
                    </NavLink>
                </OnlyShowToRoles>
                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN]}>
                    <NavLink to="/users" exact activeClassName="active">
                        <li>
                            <span><FontAwesomeIcon className="separator" icon={faUser}/>{t("users")}</span>
                        </li>
                    </NavLink>
                </OnlyShowToRoles>
                <DontShowToCertainAccounts dontShowToAccountNames={["babbel"]}>
                    <ul className="submenu">
                        <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN]}>
                            <NavLink to="/users/teams" exact activeClassName="active">
                                <li><span><FontAwesomeIcon className="separator"
                                                           icon={faUsers}/>{t("teams")}</span>
                                </li>
                            </NavLink>
                        </OnlyShowToRoles>
                    </ul>
                </DontShowToCertainAccounts>
                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN]}>
                    <NavLink to="/files" exact activeClassName="active">
                        <li>
                            <span><FontAwesomeIcon className="separator" icon={faFile}/>{t("files")}</span>
                        </li>
                    </NavLink>
                </OnlyShowToRoles>
                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN]}>
                    <NavLink to="/settings" exact activeClassName="active">
                        <li>
                            <span><FontAwesomeIcon className="separator" icon={faTools}/>{t("settings")}</span>
                        </li>
                    </NavLink>
                </OnlyShowToRoles>
                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN]}>
                    <DontShowToCertainAccounts dontShowToAccountNames={["babbel", "blinkist"]}>
                        <NavLink to="/billing" exact activeClassName="active">
                            <li>
                                <span><FontAwesomeIcon className="separator" icon={faEuroSign}/>{t("billing")}</span>
                            </li>
                        </NavLink>
                    </DontShowToCertainAccounts>
                </OnlyShowToRoles>
            </ul>;
        }

        return (
            <ul className="menu">
                <NavLink to="/" exact activeClassName="active">
                    <li>
                        <span><FontAwesomeIcon className="separator" icon={faHome}/>{t("home")}</span>
                    </li>
                </NavLink>
                <NavLink to="/enroll" exact activeClassName="active">
                    <li>
                        <span><FontAwesomeIcon className="separator" icon={faList}/>{t("enroll")}</span>
                    </li>
                </NavLink>
                <ul className="submenu">
                    <NavLink to="/my-courses" exact activeClassName="active">
                        <li><span><FontAwesomeIcon className="separator"
                                                   icon={faPaintBrush}/>{t("my-courses")}</span>
                        </li>
                    </NavLink>
                </ul>
                <DontShowToCertainAccounts dontShowToAccountNames={["babbel"]}>
                    <NavLink to="/available-offers" exact activeClassName="active">
                        <li>
                            <span><FontAwesomeIcon className="separator"
                                                   icon={faChalkboardTeacher}/>{t("book-coaching")}</span>
                        </li>
                    </NavLink>
                    <NavLink to="/transactions/request-learning-item" exact activeClassName="active">
                        <li>
                            <span><FontAwesomeIcon className="separator" icon={faBook}/>{t("learning-item")}</span>
                        </li>
                    </NavLink>
                    <NavLink to="/transactions" exact activeClassName="active">
                        <li>
                            <span><FontAwesomeIcon className="separator" icon={faArchive}/>{t("transactions")}</span>
                        </li>
                    </NavLink>
                </DontShowToCertainAccounts>
                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN, TrainerData.ROLE_TRAINER]}>
                    <li className="menu-link" onClick={() => this.setState({adminMenuOpen: true})}>
                        <span><FontAwesomeIcon className="separator" icon={faChevronRight}/>{t("administration")}</span>
                    </li>
                </OnlyShowToRoles>
            </ul>
        );
    }
}

export const Menu = withTranslation("menu")(MenuComponent);
