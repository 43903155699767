import {
    fetchLanguageSettingsAction,
    LanguageSettings,
    selectLanguageSettings
} from "@thekeytechnology/framework-react";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { connect } from "react-redux";

interface StateProps {
    languageSettings?: LanguageSettings;
}

interface DispatchProps {
    fetchLanguageSettings: () => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class GlobalLanguageSwitcherComponent extends Component<Props> {
    public componentDidMount(): void {
        this.props.fetchLanguageSettings();
    }

    public render() {
        if (!this.props.languageSettings) {
            return null;
        }
        return (
            <div className="ml-auto">
                <LanguageSwitcher availableLanguages={this.props.languageSettings.availableLanguages}
                                  selectedLanguage={this.props.i18n.language}
                                  selectLanguage={language => this.props.i18n.changeLanguage(language)}/>
            </div>
        );
    }
}

export const GlobalLanguageSwitcher = connect<StateProps, DispatchProps, {}>(
    state => {
        return {
            languageSettings: selectLanguageSettings(state),
        };
    },
    {
        fetchLanguageSettings: fetchLanguageSettingsAction,
    }
)(withTranslation()(GlobalLanguageSwitcherComponent));
