import React from "react";

import "./link-cards.scss";

export class LinkCards extends React.Component {
    public render() {
        return (
            <div className="link-cards row">
                {this.props.children}
            </div>
        );
    }
}
