import {Component, ErrorInfo} from "react";
import * as Sentry from "@sentry/browser";

export class SentryErrorBoundary extends Component {
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo as any);
            Sentry.captureException(error);
        });
    }

    public render() {
        return this.props.children;
    }
}
