import { EntityWrapper } from "@thekeytechnology/framework-react";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { TrainerData } from "../../../model/trainer/trainer-data";
import "./trainer-card.scss";

interface TrainerCardsProps extends WithTranslation {
    trainer: EntityWrapper<TrainerData>;
}

const TrainerCardComponent = (props: TrainerCardsProps) => {
    const {
        t,
        trainer
    } = props;

    return <div className="card trainer-card">
        {trainer.entity.avatarFile ?
            <img src={trainer.entity.avatarFile.entity.url}
                 className="card-img-top"
                 alt={trainer.entity.name}/> : null}
        <div className="card-body">
            <h2 className="card-title">{trainer.entity.name}</h2>
            <p className="trainer-type">{t("trainer-type." + trainer.entity.trainerType)}</p>
        </div>
    </div>;
};

export const TrainerCard = withTranslation("courses")(TrainerCardComponent);
