import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import {
    WithEntityList,
    WithSelections
} from "@thekeytechnology/framework-react";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { EntityCreateAndDeleteButtons } from "../../../../../core/components/buttons/EntityCreateAndDeleteButtons";
import { AboveTableContainer } from "../../../../../core/components/containers/Container";
import { ActionLink } from "../../../../../core/components/icon-link-tooltip/ActionLink";
import { ActionTableColumn } from "../../../../../core/components/table/ActionTableColumn";
import { EntityTableHeader } from "../../../../../core/components/table/EntityTableHeader";
import { EntityTableRow } from "../../../../../core/components/table/EntityTableRow";
import { Table, TableBody, TableColumn } from "../../../../../core/components/table/Table";
import { AsyncEntityTableBody } from "../../../../../entity/components/AsyncEntityTableBody";
import { CourseCategory } from "../../../../model/course-category";
import PaginationInfo from "../../../../../core/components/pagination/PaginationInfo";
import { CourseCategoryColorBubble } from "../../../common/displays/CourseCategoryColorBubble";
import "./course-categories-table.scss";
import {WithSelectionsProps} from "@thekeytechnology/framework-react";
import {WithEntityListProps} from "@thekeytechnology/framework-react";

type Props = WithSelectionsProps<string> & WithEntityListProps<CourseCategory> & WithTranslation;

const CourseCategoriesTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={CourseCategory.TYPE} className="mr-auto"/>
                <EntityCreateAndDeleteButtons
                    entityType={CourseCategory.TYPE}
                    selectedEntities={selectionModel.selections}
                    createPath="/course-categories/new/edit"
                    label={t("course category")}
                    labelPlural={t("course categories")}
                />
            </AboveTableContainer>
            <Table itemCount={entities.length} className="course-categories-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("Name")}</TableColumn>
                    <TableColumn>{t("Color")}</TableColumn>
                    <TableColumn>{t("Id")}</TableColumn>
                    <TableColumn>{t("Actions")}</TableColumn>
                </EntityTableHeader>

                <TableBody>
                    <AsyncEntityTableBody
                        apiCallState={entityApiState}
                        items={entities}
                        renderItem={courseCategory =>
                            <EntityTableRow
                                key={courseCategory.id}
                                entity={courseCategory}
                                selectionModel={selectionModel}>
                                <TableColumn className="bold title-column">
                                    {courseCategory.entity.name}
                                </TableColumn>
                                <TableColumn>
                                    <CourseCategoryColorBubble color={courseCategory.entity.color}
                                                               className="mr-2"/> {courseCategory.entity.color}
                                </TableColumn>
                                <TableColumn>
                                    {courseCategory.id}
                                </TableColumn>
                                <ActionTableColumn>
                                    <ActionLink to={"/course-categories/" + courseCategory.id + "/edit"}
                                                tooltip={t("Edit course category")}
                                                icon={faPencilAlt}/>
                                </ActionTableColumn>
                            </EntityTableRow>
                        }/>
                </TableBody>
            </Table>
        </>
    );
};

export const CourseCategoriesTable = WithSelections<{}, string>(
    WithEntityList<WithSelectionsProps<string>, CourseCategory>(
        withTranslation("courses")(CourseCategoriesTableComponent),
        CourseCategory.TYPE
    ) as any
);
