import {faCalendar, faEnvelope, faGraduationCap, faPencilAlt, faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {AboveTableContainer} from "../../../../../core/components/containers/Container";
import {DateTimeDisplay} from "../../../../../core/components/display/DateTimeDisplay";
import {ActionLink} from "../../../../../core/components/icon-link-tooltip/ActionLink";
import {ActionTableColumn} from "../../../../../core/components/table/ActionTableColumn";
import {EntityTableHeader} from "../../../../../core/components/table/EntityTableHeader";
import {EntityTableRow} from "../../../../../core/components/table/EntityTableRow";
import {Table, TableColumn} from "../../../../../core/components/table/Table";
import {AsyncEntityTableBody} from "../../../../../entity/components/AsyncEntityTableBody";
import {CourseWithMeta} from "../../../../model/course/course-with-meta";

import "./course-table.scss";
import PaginationInfo from "../../../../../core/components/pagination/PaginationInfo";
import {CourseCategoryColorBubble} from "../../../common/displays/CourseCategoryColorBubble";
import {CourseCategoryDisplay} from "../../../common/displays/CourseCategoryDisplay";
import {ParticipantDisplay} from "../../../common/displays/ParticipantDisplay";
import {StatusDisplay} from "../../../common/displays/StatusDisplay";
import {CourseCancellationButton} from "./CourseCancellationButton";
import {FinalizeCourseButton} from "./FinalizeCourseButton";

type Props = WithEntityListProps<CourseWithMeta> & WithSelectionsProps<string> & WithTranslation;

const CourseTableComponent = (props: Props) => {
    const {
        entities,
        entityApiState,
        selectionModel,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={CourseWithMeta.TYPE} className="mr-auto"/>
                <CourseCancellationButton courseIds={selectionModel.selections}/>
            </AboveTableContainer>
            <Table className="course-table" itemCount={entities.length}>
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("Name")}</TableColumn>
                    <TableColumn>{t("Category")}</TableColumn>
                    <TableColumn>
                        <FontAwesomeIcon icon={faCalendar}/> {t("Date / Time")}
                    </TableColumn>
                    <TableColumn>
                        <FontAwesomeIcon icon={faGraduationCap}/> {t("Trainer")}
                    </TableColumn>
                    <TableColumn>
                        <FontAwesomeIcon icon={faUser}/> {t("Participants")}
                    </TableColumn>
                    <TableColumn> {t("Status")}</TableColumn>
                    <TableColumn> {t("Actions")}</TableColumn>
                </EntityTableHeader>

                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={course => {
                        return (
                            <EntityTableRow entity={course} selectionModel={selectionModel}>
                                <TableColumn className="bold name-column title-column">
                                    <CourseCategoryColorBubble
                                        className="mr-2 color-bubble"
                                        color={course.entity.category ? course.entity.category.entity.color : ""}/>
                                    {course.entity.name}
                                </TableColumn>
                                <TableColumn className="lg-and-below-hide">
                                    <CourseCategoryDisplay courseCategory={course.entity.category}/>
                                </TableColumn>
                                <TableColumn>
                                    <FontAwesomeIcon icon={faCalendar}
                                                     className="mobile-only-icon lg-and-above-hide mr-2"/>
                                    <DateTimeDisplay
                                        dateTime={course.entity.dateTime}/>
                                </TableColumn>
                                <TableColumn>
                                    <FontAwesomeIcon icon={faGraduationCap}
                                                     className="mobile-only-icon lg-and-above-hide mr-2"/> {course.entity.trainer ?
                                    course.entity.trainer.entity.name : t("no trainer")}
                                </TableColumn>
                                <TableColumn>
                                    <FontAwesomeIcon icon={faUser}
                                                     className="mobile-only-icon lg-and-above-hide mr-2"/>
                                    <ParticipantDisplay
                                        participants={course.entity.participants}/>
                                </TableColumn>
                                <TableColumn className="footer-column">
                                    <StatusDisplay course={course}/>
                                </TableColumn>
                                <ActionTableColumn isDisabled={course.entity.isCancelled}>
                                    <>
                                        <FinalizeCourseButton course={course}/>

                                        <ActionLink to={"/courses/" + course.id + "/edit"}
                                                    tooltip={t("Edit course")}
                                                    icon={faPencilAlt}/>

                                        <ActionLink
                                            to={"/courses/" + course.id + "/participants"}
                                            tooltip={t("Edit participants")}
                                            icon={faUsers}/>

                                        <ActionLink
                                            to={"/courses/" + course.id + "/email"}
                                            tooltip={t("Email participants")}
                                            icon={faEnvelope}/>
                                    </>
                                </ActionTableColumn>
                            </EntityTableRow>
                        );
                    }}/>
            </Table>
        </>
    );
};

export const CourseTable = WithSelections(
    WithEntityList<WithSelectionsProps<string>, CourseWithMeta>(
        withTranslation("courses")(CourseTableComponent),
        CourseWithMeta.TYPE
    )
);
