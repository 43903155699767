import { apiModuleEpics$, billingModuleEpics$, userModuleEpics$ } from "@thekeytechnology/framework-react";
import { dialogModuleEpics$ } from "@thekeytechnology/framework-react";
import { entityModuleEpics$ } from "@thekeytechnology/framework-react";
import { i18nModuleEpics$ } from "@thekeytechnology/framework-react";
import { integrationsModuleEpics$ } from "@thekeytechnology/framework-react";
import { settingsModuleEpics$ } from "@thekeytechnology/framework-react";
import { filesModuleEpics$ } from "@thekeytechnology/framework-react";

import { combineEpics } from "redux-observable";
import { courseAuthModuleEpics$ } from "./auth/epics";
import { budgetModuleEpics } from "./budget/epics";
import { coachingModuleEpics } from "./coaching/epics";
import { courseModuleEpics$ } from "./courses/epics";
import { emailModuleEpic$ } from "./emails/epics/email";
import { learningItemsModuleEpics$ } from "./learning-items/epics";
import { transactionLogModuleEpics } from "./transactionlog/epics";
import { additionalUserEpics$ } from "./users/epics/additionalUserEpics";

export const appEpics = combineEpics(
    courseAuthModuleEpics$,
    entityModuleEpics$,
    courseModuleEpics$,
    dialogModuleEpics$,
    filesModuleEpics$ as any,
    emailModuleEpic$,
    apiModuleEpics$,
    i18nModuleEpics$ as any,
    settingsModuleEpics$ as any,
    integrationsModuleEpics$ as any,
    userModuleEpics$ as any,
    billingModuleEpics$ as any,
    budgetModuleEpics,
    additionalUserEpics$,
    coachingModuleEpics,
    transactionLogModuleEpics,
    learningItemsModuleEpics$
);
