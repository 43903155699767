import {EntityWrapper, WithSelections, WithSelectionsProps} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {ActionTableColumn} from "../../../../../core/components/table/ActionTableColumn";
import {EntityTableHeader} from "../../../../../core/components/table/EntityTableHeader";
import {EntityTableRow} from "../../../../../core/components/table/EntityTableRow";
import {Table, TableBody, TableColumn} from "../../../../../core/components/table/Table";

import "./scheduled-emails-table.scss";
import {BookedSession} from "../../../../model/booked-session";
import {AcceptBookedSessionButton} from "./AcceptRequestButton";
import {DeclineBookedSessionButton} from "./DeclineRequestButton";

interface OwnProps {
    bookedSessions: Array<EntityWrapper<BookedSession>>;
}

type Props = OwnProps & WithSelectionsProps<string> & WithTranslation;

const BookedSessionTableComponent = (props: Props) => {
    const {
        bookedSessions,
        selectionModel,
        t
    } = props;

    return (
        <>
            <Table itemCount={bookedSessions.length}
                   className="scheduled-emails-table">
                <EntityTableHeader entities={bookedSessions} selectionModel={selectionModel}>
                    <TableColumn>{t("booked-sessions.table.columns.user")}</TableColumn>
                    <TableColumn>{t("booked-sessions.table.columns.cost")}</TableColumn>
                    <TableColumn>{t("booked-sessions.table.columns.status")}</TableColumn>
                    <TableColumn>{t("booked-sessions.table.columns.actions")}</TableColumn>
                </EntityTableHeader>
                <TableBody>
                    {bookedSessions.map(bookedSession =>
                        <EntityTableRow key={bookedSession.id}
                                        entity={bookedSession}
                                        selectionModel={selectionModel}>
                            <TableColumn className="bold title-column">
                                {bookedSession.entity.user.entity.name}
                            </TableColumn>
                            <TableColumn>
                                {bookedSession.entity.cost.toFixed(2)}€
                            </TableColumn>
                            <TableColumn>
                                {t("booked-sessions.status." + bookedSession.entity.status)}
                            </TableColumn>
                            <ActionTableColumn>
                                {bookedSession.entity.status === BookedSession.STATUS_REQUESTED ? <>
                                    <AcceptBookedSessionButton bookedSession={bookedSession}/>
                                    <DeclineBookedSessionButton bookedSession={bookedSession}/>
                                </> : null}
                            </ActionTableColumn>
                        </EntityTableRow>)}
                </TableBody>
            </Table>
        </>
    );
};

export const BookedSessionTable = WithSelections<OwnProps, string>(
    withTranslation("coaching")(BookedSessionTableComponent) as any,
);
