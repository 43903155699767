import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { sendMailAction } from "../../../actions/send-email";
import { CourseForSaving } from "../../../model/course/course-for-saving";
import { CourseWithMeta } from "../../../model/course/course-with-meta";
import { connect } from "react-redux";

import { CourseRelatedModalHeader } from "../common/modal/CourseRelatedModalHeader";
import { RecipientSelectField } from "./RecipientSelectField";

import "./email-participants-modal.scss";
import {
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "../../../../core/components/entity/WithSingleEntityFromPath";
import {WysiwygField} from "../../../../core/components/wysiwyg/WysiwygField";

interface DispatchProps {
    sendMail: (courseId: string,
               targetParticipantIds: string[],
               subject: string,
               message: string) => void;
}

type Props = WithSingleEntityFromPathProps<CourseWithMeta, CourseForSaving> & DispatchProps & WithTranslation;

class EmailParticipantsModal extends Component<Props> {
    public render() {
        const {entity, sendMail, t} = this.props;

        if (!entity) {
            return <></>;
        }

        return (
            <div className="modal-content email-participants-modal">
                <div className="modal-header p-4">
                    <CourseRelatedModalHeader title={t("Email participants")} course={entity}/>
                </div>
                <div className="modal-body p-4 d-flex flex-column">
                    <Formik
                        initialValues={{
                            recipients: [],
                            subject: "",
                            body: ""
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            const recipients = values.recipients.length ? values.recipients : entity.entity.participants;
                            sendMail(
                                entity.id as string,
                                recipients.map(r => r.id as string),
                                values.subject,
                                values.body
                            );
                            setSubmitting(false);
                        }}>
                        {({isSubmitting}) => (
                            <Form>
                                <div className="form-group mb-4">
                                    <label htmlFor="exampleInputEmail1">{t("Recipients")}</label>
                                    <Field name="recipients"
                                           component={RecipientSelectField}
                                           participants={entity.entity.participants}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="subject">{t("Message")}</label>
                                    <Field className="default-input mb-3"
                                           placeholder={t("Subject")} name="subject" type="text"/>
                                    <Field name="body"
                                           component={WysiwygField}/>
                                </div>

                                <button type="submit" className="btn btn-primary ml-auto"
                                        disabled={isSubmitting}>
                                    {t("Send email")}
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }
}

export default WithSingleEntityFromPath<{}, CourseWithMeta, CourseForSaving>(connect<{}, DispatchProps, {}>(
    null,
    {
        sendMail: sendMailAction,
    }
)(withTranslation("courses")(EmailParticipantsModal)), CourseWithMeta.TYPE, "courseId");
