import {faEuroSign} from "@fortawesome/free-solid-svg-icons";
import {ShowWhenApiSucceeded} from "@thekeytechnology/framework-react";
import {Form, Formik} from "formik";
import React, {Component} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import * as Yup from "yup";
import {connect} from "react-redux";
import {ApiStateSubmitButton} from "../../core/components/form/ApiStateSubmitButton";
import {ValidatedField} from "../../core/components/form/ValidatedField";
import {BooleanSelectField} from "../../core/components/select/BooleanSelectField";
import {API_GET_BUDGET_SETTINGS, getBudgetSettingsAction} from "../actions/get-budget-settings";
import {setBudgetSettingsAction} from "../actions/set-budget-settings";
import {API_SET_USER_BUDGETS} from "../actions/set-user-budgets";
import {BudgetSettings} from "../model/BudgetSettings";
import {selectBudgetSettings} from "../selectors";

interface StateProps {
    budgetSettings?: BudgetSettings;
}

interface DispatchProps {
    getBudgetSettings: () => void;
    setBudgetSettings: (enabled: boolean, globalBudget: number) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class BudgetSettingsFormComponent extends Component<Props> {
    public componentDidMount(): void {
        this.props.getBudgetSettings();
    }

    public render() {
        const {t, budgetSettings, setBudgetSettings} = this.props;

        return (
            <ShowWhenApiSucceeded apiQualifier={API_GET_BUDGET_SETTINGS}
                                  duringLoading={<h3 className="mb-3">{t("budget-settings-form.loading")}</h3>}>
                <Formik
                    initialValues={{
                        enabled: budgetSettings ? budgetSettings.enabled : false,
                        globalBudget: budgetSettings ? budgetSettings.globalBudget : 0
                    }}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                        enabled: Yup.string().required(t("budget-settings-form.enabled") + " " + t("translation:is a required field")),
                        globalBudget: Yup.number()
                            .min(0)
                            .required(t("budget-settings-form.globalBudget") + " " + t("translation:is a required field"))
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        setBudgetSettings(values.enabled, values.globalBudget);
                        setSubmitting(false);
                    }}
                >
                    {({isSubmitting, errors, touched}) => {
                        const hasError = !!(errors.globalBudget && touched.globalBudget) || !!(errors.enabled && touched.enabled);

                        return (
                            <Form>
                                <h3 className="mb-3 mt-5">{t("budget-settings-form.heading")}</h3>
                                <div className="form-group row">
                                    <ValidatedField icon={faEuroSign}
                                                    component={BooleanSelectField}
                                                    trueLabel={t("budget-settings-form.options.true")}
                                                    falseLabel={t("budget-settings-form.options.false")}
                                                    label={t("budget-settings-form.enabled")}
                                                    name="enabled" errors={errors}
                                                    touched={touched}
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faEuroSign}
                                                    type="number"
                                                    min="0"
                                                    step="0.01"
                                                    label={t("budget-settings-form.globalBudget")}
                                                    name="globalBudget" errors={errors}
                                                    touched={touched} required
                                    />
                                </div>
                                <ApiStateSubmitButton
                                    apiQualifier={API_SET_USER_BUDGETS}
                                    submitText={t("budget-settings-form.submit")}
                                    hasError={hasError}
                                    isSubmitting={isSubmitting}/>
                            </Form>
                        );
                    }}
                </Formik>
            </ShowWhenApiSucceeded>);
    }
}

export const BudgetSettingsForm = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            budgetSettings: selectBudgetSettings(state as never)
        };
    },
    {
        getBudgetSettings: getBudgetSettingsAction,
        setBudgetSettings: setBudgetSettingsAction
    }
)(withTranslation(["budget", "translation"])(BudgetSettingsFormComponent));
