import {
    WithEntityList
} from "@thekeytechnology/framework-react";
import { WithEntityListProps } from "@thekeytechnology/framework-react";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AboveTableContainer } from "../../../../core/components/containers/Container";
import { LoadingRow } from "../../../../core/components/table/LoadingRow";
import { AsyncContent } from "../../../../core/components/table/LoadingTableContent";
import { NoDataRow } from "../../../../core/components/table/NoDataRow";
import { CoachingOffer } from "../../../model/coaching-offer";
import PaginationInfo from "../../../../core/components/pagination/PaginationInfo";
import "./coaching-offers.scss";
import { BookCoachingButton } from "./BookCoachingButton";

type Props = WithEntityListProps<CoachingOffer> & WithTranslation;

const AvailableOffersCardsComponent = (props: Props) => {
    const {
        entities,
        entityApiState,
    } = props;

    return (
        <div className="coaching-offers">
            <AboveTableContainer>
                <PaginationInfo entityType={CoachingOffer.TYPE} className="mr-auto"/>
            </AboveTableContainer>

            <AsyncContent
                loadingComponent={() => <LoadingRow/>}
                successComponent={
                    () => entities.length === 0 ? <NoDataRow/> :
                        entities.map(item => {
                            return (
                                <div key={item.id} className="col-12 mb-3">
                                    <div className="card">
                                        <div className="card-body row">
                                            <div className="col-2">
                                                {item.entity.trainer && item.entity.trainer.entity.avatarFile ?
                                                    <>
                                                        <img src={item.entity.trainer.entity.avatarFile.entity.url}
                                                             className="card-img-top"
                                                             alt={item.entity.trainer.entity.name}/>
                                                        <p className="mb-0 bold">{item.entity.trainer.entity.name}</p>
                                                    </> : null}
                                            </div>
                                            <div className="col-10 d-flex flex-column">
                                                <h5 className="card-title">{item.entity.title}</h5>
                                                <p className="card-text"
                                                   dangerouslySetInnerHTML={{__html: item.entity.description as string}}/>
                                                <BookCoachingButton coachingOffer={item}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                }
                apiCallState={entityApiState}/>
        </div>
    );
};

export const AvailableOffersCards = WithEntityList<{}, CoachingOffer>(
    withTranslation("coaching")(AvailableOffersCardsComponent),
    CoachingOffer.TYPE,
    "only-mine"
);
