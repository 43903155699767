import { ApiCallAction, apiPost } from "@thekeytechnology/framework-react";
import { combineEpics } from "redux-observable";
import { AcceptDeclineSessionPayload, API_ACCEPT_DECLINE_SESSION } from "../actions/accept-decline-session";
import { API_BOOK_COACHING, BookCoachingPayload } from "../actions/book-coaching";

const bookCoaching$ = apiPost<BookCoachingPayload, any>(
    {apiType: API_BOOK_COACHING},
    "/coaching/request-session",
    (action: ApiCallAction<BookCoachingPayload>) => {
        return {
            offerId: action.payload.offerId
        };
    }
);

const acceptDecline$ = apiPost<AcceptDeclineSessionPayload, any>(
    {apiType: API_ACCEPT_DECLINE_SESSION},
    "/coaching/accept-decline",
    (action: ApiCallAction<AcceptDeclineSessionPayload>) => {
        return {
            sessionId: action.payload.sessionId,
            status: action.payload.status
        };
    }
);

export const bookEpics$ = combineEpics(bookCoaching$ as any, acceptDecline$ as any);
