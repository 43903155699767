import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import {
    BlueBgContainer,
    HeadingContainer,
    PaginationContainer,
    ContentContainer
} from "../../../../core/components/containers/Container";
import { SimpleHeader } from "../../../../core/components/header/SimpleHeader";
import Pagination from "../../../../core/components/pagination/Pagination";
import { CourseDefinition } from "../../../model/course-definition/course-definition.model";
import { CourseDefinitionFilters } from "./filter/CourseDefinitionFilters";
import { CourseDefinitionTable } from "./table/CourseDefinitionTable";

const CourseDefinitionOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/course-definitions"}>{t("Course definitions")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("Course definitions")}/>
            </HeadingContainer>
            <BlueBgContainer>
                <CourseDefinitionFilters/>
            </BlueBgContainer>
            <ContentContainer>
                <CourseDefinitionTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={CourseDefinition.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const CourseDefinitionOverview = withTranslation("courses")(CourseDefinitionOverviewComponent);
