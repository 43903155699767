import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_REMOVE_PARTICIPANTS = "remove-participant";

export interface RemoveParticipantPayload {
    parentEntityType: string;
    parentEntityId: string;
    participantEmails: string[];
}

export interface ParticipantsRemovedPayload {
    parentEntityType: string;
    parentEntityId: string;
}

export const removeParticipantAction = (parentEntityType: string,
                                        parentEntityId: string,
                                        participantEmails: string[]) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_REMOVE_PARTICIPANTS,
            callType: API_REMOVE_PARTICIPANTS
        },
        payload: {
            parentEntityType,
            parentEntityId,
            participantEmails
        }
    } as ApiCallAction<RemoveParticipantPayload>;
};
