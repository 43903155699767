import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_BOOK_COACHING = "book-coaching";

export interface BookCoachingPayload {
    offerId: string;
}

export const bookCoachingAction = (offerId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_BOOK_COACHING,
            callType: API_BOOK_COACHING
        },
        payload: {
            offerId,
        }
    } as ApiCallAction<BookCoachingPayload>;
};
