import { faArrowRight, faCalendar, faClock, faHeading } from "@fortawesome/free-solid-svg-icons";
import {
    selectSingleEntity, WithSwitchableLanguage, WithSwitchableLanguageProps,
} from "@thekeytechnology/framework-react";
import {
    createEntitiesAction,
    EntityWrapper,
    fetchSingleEntityAction,
    updateEntitiesAction
} from "@thekeytechnology/framework-react";
import { Form, Formik } from "formik";
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { WithTranslation, withTranslation } from "react-i18next";
import { SaveAndBackButtons } from "../../../../../core/components/buttons/SaveAndBackButtons";
import { ContentContainer } from "../../../../../core/components/containers/Container";
import { ValidatedField } from "../../../../../core/components/form/ValidatedField";
import { SimpleEditHeader } from "../../../../../core/components/header/LoadingEditHeader";
import { CourseDefinition } from "../../../../model/course-definition/course-definition.model";
import { ScheduleRelation } from "../../../../model/scheduled-email/schedule-relation";
import { ScheduledEmail } from "../../../../model/scheduled-email/scheduled-email";
import { EditHeaderWithBackground } from "../../../course/common/edit/EditHeaderWithBackground";
import { ScheduleDisplay } from "../../single/schedule/ScheduleDisplay";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { RecipientModeField } from "./RecipientModeField";
import { ScheduleRelationField } from "./ScheduleRelationField";
import * as Yup from "yup";
import {WysiwygField} from "../../../../../core/components/wysiwyg/WysiwygField";

interface StateProps {
    courseDefinition?: EntityWrapper<CourseDefinition>;
    scheduledEmail?: EntityWrapper<ScheduledEmail>;
}

interface DispatchProps {
    fetchCourseDefinition: (id: string,
                            factory: (() => EntityWrapper<CourseDefinition>) | undefined) => void;
    fetchScheduledEmail: (id: string,
                          factory: (() => EntityWrapper<ScheduledEmail>) | undefined)
        => void;

    updateScheduledEmail: (entities: Array<EntityWrapper<ScheduledEmail>>, courseDefinitionId: string)
        => void;
    createScheduledEmail: (entities: Array<EntityWrapper<ScheduledEmail>>, courseDefinitionId: string)
        => void;
}

type Props = StateProps & DispatchProps & WithTranslation & WithSwitchableLanguageProps & RouteComponentProps<any>;

class EditScheduledEmail extends React.Component<Props> {
    public componentDidMount(): void {
        const {
            match: {params: {courseDefinitionId, scheduledEmailId}},
            fetchCourseDefinition, fetchScheduledEmail
        } = this.props;
        if (courseDefinitionId && scheduledEmailId) {
            fetchCourseDefinition(courseDefinitionId, undefined);
            fetchScheduledEmail(scheduledEmailId, () => ScheduledEmail.new(courseDefinitionId));
        }
    }

    public render() {
        const {courseDefinition, scheduledEmail, currentLanguage, t} = this.props;

        return (
            <>
                <BreadcrumbsItem to={"/course-definitions/"}>{t("Course definitions")}</BreadcrumbsItem>
                {courseDefinition ? <BreadcrumbsItem
                    to={"/course-definitions/" + courseDefinition.id + "/edit"}>
                    {courseDefinition.entity.name}</BreadcrumbsItem> : null}
                {courseDefinition ? <BreadcrumbsItem
                    to={"/course-definitions/" + courseDefinition.id + "/scheduled-emails"}>
                    {t("Scheduled emails")}
                </BreadcrumbsItem> : null}
                {scheduledEmail && courseDefinition ? <BreadcrumbsItem
                    to={"/course-definitions/" + courseDefinition.id + "/scheduled-emails/" + scheduledEmail.id}>
                    {t("Editing email for")}&nbsp;
                    {t("recipient-mode." + scheduledEmail.entity.recipientMode)}</BreadcrumbsItem> : null}

                <SimpleEditHeader entity={scheduledEmail} entityTypeName={t("scheduled email")}/>

                {scheduledEmail && courseDefinition ?
                    <Formik
                        initialValues={{
                            subject: scheduledEmail.entity.subjects[currentLanguage] ? scheduledEmail.entity.subjects[currentLanguage] : "",
                            body: scheduledEmail.entity.bodies[currentLanguage] ? scheduledEmail.entity.bodies[currentLanguage] : "",
                            recipientMode: scheduledEmail.entity.recipientMode,
                            relationType: scheduledEmail.entity.scheduleRelation.relationType,
                            relationHours: scheduledEmail.entity.scheduleRelation.hours
                        }}
                        enableReinitialize={true}
                        validationSchema={Yup.object().shape({
                            subject: Yup.string().required(t("Subject") + " " + t("is a required field")),
                            body: Yup.string().required(t("Body") + " " + t("is a required field")),
                            recipientMode: Yup.string().required(t("Recipient(s)") + " " + t("is a required field")),
                            relationType: Yup.string().required(),
                            relationHours: Yup.number().positive().required(t("Schedule relation hours") + " " + t("is a required field"))
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            const email = new EntityWrapper(
                                scheduledEmail.id,
                                new ScheduledEmail(
                                    courseDefinition.id as string,
                                    {
                                        ...scheduledEmail.entity.subjects,
                                        [currentLanguage]: values.subject
                                    },
                                    {
                                        ...scheduledEmail.entity.bodies,
                                        [currentLanguage]: values.body
                                    },
                                    values.recipientMode,
                                    new ScheduleRelation(
                                        values.relationType,
                                        values.relationHours
                                    )
                                )
                            );
                            if (scheduledEmail.id) {
                                this.props.updateScheduledEmail([email], courseDefinition.id as string);
                            } else {
                                this.props.createScheduledEmail([email], courseDefinition.id as string);
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting, errors, touched}) => (
                            <Form>
                                <EditHeaderWithBackground
                                    heading={scheduledEmail.id ?
                                        t("Editing email for") + ": " + t("recipient-mode." + scheduledEmail.entity.recipientMode) :
                                        t("New scheduled email")}
                                    leadText={
                                        <>{t("For")} {courseDefinition.entity.name} - <ScheduleDisplay
                                            schedule={courseDefinition.entity.schedule}/>
                                        </>
                                    }>
                                    <SaveAndBackButtons isSubmitting={isSubmitting}
                                                        entity={scheduledEmail}
                                                        backPath={"/course-definitions/" +
                                                        courseDefinition.id + "/scheduled-emails"}/>
                                </EditHeaderWithBackground>
                                <ContentContainer>
                                    {/*<div className="form-group row">*/}
                                    {/*    <label htmlFor="editing-language"*/}
                                    {/*           className="col-sm-2 col-form-label">{t("Choose translation")}</label>*/}
                                    {/*    <div className="col-sm-10">*/}
                                    {/*        <LanguageSwitcher availableLanguages={availableLanguages}*/}
                                    {/*                          selectedLanguage={editingLanguage}*/}
                                    {/*                          selectLanguage={language => setEditingLanguage(language)}/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="form-group row ">
                                        <ValidatedField icon={faHeading} label={t("Subject")} name="subject"
                                                        errors={errors} touched={touched} type="text"
                                                        placeholder={t("Enter subject")} required
                                        />
                                    </div>
                                    <div className="form-group row ">
                                        <ValidatedField label={t("Body")} name="body"
                                                        component={WysiwygField}
                                                        errors={errors} touched={touched}
                                                        required
                                        />
                                    </div>
                                    <div className="form-group row ">
                                        <ValidatedField icon={faArrowRight} label={t("Recipient(s)")}
                                                        name="recipientMode"
                                                        errors={errors} touched={touched}
                                                        component={RecipientModeField}
                                                        placeholder={t("Choose recipients")} required
                                        />
                                    </div>
                                    <div className="form-group row ">
                                        <ValidatedField icon={faCalendar}
                                                        label={t("Schedule relation type")}
                                                        name="relationType"
                                                        errors={errors} touched={touched}
                                                        component={ScheduleRelationField}
                                                        placeholder={t("Select relation type")} required
                                        />
                                    </div>
                                    <div className="form-group row ">
                                        <ValidatedField icon={faClock}
                                                        label={t("Schedule relation hours")}
                                                        name="relationHours"
                                                        errors={errors} touched={touched} type="number"
                                                        placeholder={t("Enter hours")} required
                                        />
                                    </div>
                                </ContentContainer>
                            </Form>
                        )}
                    </Formik> : null}
            </>
        );
    }
}

export default connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            courseDefinition: selectSingleEntity<CourseDefinition>(CourseDefinition.TYPE)(state),
            scheduledEmail: selectSingleEntity<ScheduledEmail>(ScheduledEmail.TYPE)(state)
        };
    },
    {
        fetchCourseDefinition: fetchSingleEntityAction(CourseDefinition.TYPE),
        fetchScheduledEmail: fetchSingleEntityAction(ScheduledEmail.TYPE),
        updateScheduledEmail: updateEntitiesAction(ScheduledEmail.TYPE),
        createScheduledEmail: createEntitiesAction(ScheduledEmail.TYPE)

    }
)(withRouter(WithSwitchableLanguage(withTranslation("courses")(EditScheduledEmail) as any) as any));
