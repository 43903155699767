import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import EntityDeletionButton from "./EntityDeletionButton";

interface EntityCreateAndDeleteButtonsProps extends WithTranslation {
    entityType: string;
    createPath: string;
    label: string;
    labelPlural: string;
    selectedEntities: string[];
    additionalInformation?: string;
    relatedEntityId?: string;
}

const EntityCreateAndDeleteButtonsComponent = (props: EntityCreateAndDeleteButtonsProps) => {
    const {entityType, createPath, label, labelPlural, selectedEntities, relatedEntityId, additionalInformation, t} = props;
    return (
        <>
            <NavLink className="mr-3"
                     to={createPath}>
                <button type="button" className="btn btn-success">
                    {t("Create")}
                </button>
            </NavLink>
            <EntityDeletionButton entityType={entityType}
                                  selectedEntities={selectedEntities}
                                  label={label}
                                  labelPlural={labelPlural}
                                  additionalExplanation={additionalInformation}
                                  relatedEntityId={relatedEntityId}
            />
        </>
    );
};

export const EntityCreateAndDeleteButtons = withTranslation()(EntityCreateAndDeleteButtonsComponent);
