import { ApiCallAction } from "@thekeytechnology/framework-react";

export const API_SET_BUDGET_SETTINGS = "set-budget-settings";

export interface SetBudgetSettingsPayload {
    enabled: boolean;
    globalBudget: number;
}

export const setBudgetSettingsAction = (enabled: boolean, globalBudget: number) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SET_BUDGET_SETTINGS,
            callType: API_SET_BUDGET_SETTINGS
        },
        payload: {
            enabled,
            globalBudget
        }
    } as ApiCallAction<SetBudgetSettingsPayload>;
};
